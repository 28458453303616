import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

import { ProductionOrder } from 'src/app/model/productionOrder';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-production-order',
    templateUrl: './production-order.component.html',
    styleUrls: ['./production-order.component.scss', '../../../styles/defaultStyles.scss'],
})
export class ProductionOrderComponent implements OnInit {
    constructor(private authService: AuthService) {}

    products: Array<string> = ['Produto 1', 'Produto 2', 'Produto 3'];
    machines: Array<string> = ['Máquina 1', 'Máquina 2', 'Máquina 3'];

    myForm = new FormGroup({
        name: new FormControl(''),
        start: new FormControl(''),
        end: new FormControl(''),
        isRecurrent: new FormControl(''),
    });
    editForm = new FormGroup({
        selectedName: new FormControl(''),
        selectedProduct: new FormControl(''),
        selectedMachine: new FormControl(''),
        selectedStart: new FormControl(''),
        selectedEnd: new FormControl(''),
        selectedIsRecurrent: new FormControl(''),
    });

    data: Array<ProductionOrder> = [
        {
            id: 1,
            name: 'Ordem 1',
            product: 'Produto 1',
            machine: 'Máquina 1',
            start: '2023-03-27T11:53',
            end: '2023-03-27T14:37',
            isRecurrent: true,
        },
        {
            id: 2,
            name: 'Ordem 2',
            product: 'Produto 2',
            machine: 'Máquina 2',
            start: '2023-03-27T11:53',
            end: '2023-03-27T14:37',
            isRecurrent: false,
        },
        {
            id: 3,
            name: 'Ordem 2',
            product: 'Produto 2',
            machine: 'Máquina 2',
            start: '2023-04-27T11:53',
            end: '2023-04-29T14:37',
            isRecurrent: false,
        },
    ];
    dataSource = new MatTableDataSource<ProductionOrder>();
    tableHeaderNames: Array<string> = ['#', 'Nome', 'Produto', 'Máquina', 'Início', 'Fim'];
    displayedColumns: string[] = ['id', 'name', 'product', 'machine', 'start', 'end'];

    idPreview: any = {};
    isEditing: boolean = false;
    editingIndex: number;

    parseStringDate(date: string): string {
        const dateArray = date.split('T');
        const dateOnly = dateArray[0];
        const timeOnly = dateArray[1];

        const dateOnlyArray = dateOnly.split('-');
        const year = dateOnlyArray[0];
        const month = dateOnlyArray[1];
        const day = dateOnlyArray[2];

        const timeOnlyArray = timeOnly.split(':');
        const hour = timeOnlyArray[0];
        const minute = timeOnlyArray[1];

        return `${day}/${month}/${year} ${hour}:${minute}`;
    }

    addHours(date: Date, hours: number): Date {
        date.setHours(date.getHours() + hours);
        return date;
    }

    ngOnInit(): void {
        const dataSource = this.data.map(productionOrder => {
            const start = this.parseStringDate(productionOrder.start);
            const end = this.parseStringDate(productionOrder.end);

            return {
                ...productionOrder,
                start,
                end,
            };
        });

        this.dataSource.data = dataSource;
    }

    getEmmitedObject(receivedObject: any): void {
        console.log('getEmmitedObject', receivedObject);
    }

    editCurrentObject(editingObject: any): void {
        console.log('editCurrentObject', editingObject);

        let productionOrder: ProductionOrder;
        this.dataSource.data.forEach((element, index) => {
            if (element.id == editingObject.id) {
                productionOrder = this.data[index];
            }
        });

        const now = new Date();
        const productionOrderStart = new Date(productionOrder.start);
        const nowPlusOneHour = this.addHours(now, 1);

        if (now >= productionOrderStart) {
            Swal.fire({
                title: 'Você não pode editar uma ordem de produção que já começou!',
                icon: 'warning',
            });
            return;
        } else if (nowPlusOneHour >= productionOrderStart) {
            Swal.fire({
                title: 'Você não pode editar uma ordem de produção antes de uma hora antes de iniciar!',
                icon: 'warning',
            });
            return;
        }

        this.isEditing = true;
        this.editingIndex = editingObject.id;

        this.editForm.patchValue({
            selectedName: productionOrder.name,
            selectedProduct: productionOrder.product,
            selectedMachine: productionOrder.machine,
            selectedStart: productionOrder.start,
            selectedEnd: productionOrder.end,
            selectedIsRecurrent: productionOrder.isRecurrent,
        });
    }

    submitEdit(value: any): void {
        console.log('submitEdit', value);
    }

    deleteCurrentObject(deletingObject: any): void {
        console.log('deleteCurrentObject', deletingObject);

        let productionOrder: ProductionOrder;
        this.dataSource.data.forEach((element, index) => {
            if (element.id == deletingObject.id) {
                productionOrder = this.data[index];
            }
        });

        const now = new Date();
        const productionOrderEnd = new Date(productionOrder.end);

        if (now >= productionOrderEnd) {
            Swal.fire({
                title: 'Você não pode deletar uma ordem de produção que já aconteceu!',
                icon: 'warning',
            });
            return;
        }
    }

    onSubmit(value: any): void {
        console.log(value);
    }

    logout() {
        this.authService.logout();
    }
}
