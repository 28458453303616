<div id="main">
    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Monitoramento de Parada</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" mat-mini-fab
                aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'14'"></app-nav>
        </nav>

        <div class="flex-display">

            <app-dynamic-table class="table-component" [isStart]="false" [dataSource]="dataSource"
                [displayedColumns]="displayedColumns" [tableHeaderNames]="tableHeaderNames"
                (currentEditingObject)="editCurrentObject($event)"
                (currentDeletingObject)="deleteCurrentObject($event)"></app-dynamic-table>

            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmitStopConfig(myForm.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar Monitoramento de Parada
                        </div>
                        <div class="form-id">
                            ID <span># <strong>{{ idPreview.id || '?' }}</strong></span>
                        </div>
                    </header>

                    <div class="input-wrapper">

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Máquina</mat-label>
                            <mat-select placeholder="Máquina" formControlName="machine" autocomplete="off" required
                                (selectionChange)="setAvailableSensors($event,'form')">
                                <mat-option *ngFor="let machine of machines" [value]="machine.id">{{machine.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Origem</mat-label>
                            <mat-select placeholder="Origem" formControlName="origin" autocomplete="off" required>
                                <mat-option *ngFor="let origin of origins"
                                    [value]="origin">{{firstLetterUppercase(origin)}} Input
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [ngClass]="{'sensor':availableSensors.length==0}">
                            <mat-label class="label">Sensor</mat-label>
                            <mat-select placeholder="Sensor" formControlName="sensor" autocomplete="off" required>
                                <mat-option *ngFor="let sensor of availableSensors" [value]="sensor.id">
                                    {{sensor.name}}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="availableSensors.length==0">*Selecione uma máquina para listar os
                                sensores</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Lógica</mat-label>
                            <mat-select placeholder="Sensor" formControlName="logic" autocomplete="off" required>
                                <mat-option *ngFor="let logic of logics" [value]="logic">{{firstLetterUppercase(logic)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <button class="btn-default btn-filled btn-add" type="submit"
                        [disabled]="myForm.invalid || myForm.untouched" color="primary">Adicionar
                        Configuração de parada</button>

                    <div class="btn-wrapper">
                        <button class="btn-default nextPageBtn" (click)="onSubmit()"
                        type="button" [disabled]="stopsConfig.length <= 0">Próxima Página</button>
                    </div>

                </form>
            </div>

        </div>

    </div>

    <!-- modal for editing and others -->
    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar configuração de parada
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>

                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(editForm.value)">
                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Máquina</mat-label>
                            <mat-select placeholder="Máquina" formControlName="machine" autocomplete="off" required
                                (selectionChange)="setAvailableSensors($event,'form')">
                                <mat-option *ngFor="let machine of machines" [value]="machine.id">{{machine.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Origem</mat-label>
                            <mat-select placeholder="Origem" formControlName="origin" autocomplete="off" required>
                                <mat-option *ngFor="let origin of origins"
                                    [value]="origin">{{firstLetterUppercase(origin)}} Input
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Sensor</mat-label>
                            <mat-select placeholder="Sensor" formControlName="sensor" autocomplete="off" required>
                                <mat-option *ngFor="let sensor of availableSensorsEdit"
                                    [value]="sensor.id">{{sensor.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Lógica</mat-label>
                            <mat-select placeholder="Sensor" formControlName="logic" autocomplete="off" required>
                                <mat-option *ngFor="let logic of logics" [value]="logic">{{firstLetterUppercase(logic)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit"
                            [disabled]="editForm.invalid || editForm.get('sensor').value==''"
                            color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false; availableSensors = []"
                            color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>

</div>