import { LobotXLS } from "./lobotXLS";

export class Lobot {
    id?: number;
    name?: string;
    description: string;

    createdAt?: string;
    updatedAt?: string;

    logic?: {
        id?: number;
    }
    logicArgs?: string;

    publishBehavior?: string;

    isAggregateSensorEquals?: boolean;
    aggregateSensor?: {
        id?: number;
    }
    
    dataCollectorOn?: boolean;
    sensorDataType?: {
        id?: number;
        name?: string;
    }

    aggregationBehaviour?: {
        type?: string;
        
        time?: number;
        processingAvgType?: string;
        
        isEndUpdatable?: boolean;
    }


    machine?: {
        id?: number;
    }

    gateway?: {
        id?: number;
    }

    plcConfig?: {
        id?: number;
    }

    sensor?: {
        id?: number;
    }

    static copyInterfaceToEntity(lobotInterface: LobotXLS): Lobot { 
        return {
            description: lobotInterface.description,
            logic: {
                id: lobotInterface.logicId,
            },

            logicArgs: (lobotInterface.logicArgs == undefined) ? "" : lobotInterface.logicArgs,

            dataCollectorOn: lobotInterface.dataCollectorOn,
            sensorDataType: {
                id: lobotInterface.sensorDataType
            },

            aggregationBehaviour: {
                type: lobotInterface.aggregationType,
                time: lobotInterface.aggregationTime,
                processingAvgType: lobotInterface.aggregationThreshold
            },

            isAggregateSensorEquals: lobotInterface.isAggregateSensorEquals,
            aggregateSensor: {
                id: lobotInterface.aggregateSensorId
            },

            gateway: { 
                id: lobotInterface.gatewayId
            },

            machine: { 
                id: lobotInterface.machineId
            },

            plcConfig: { 
                id: lobotInterface.plcConfigId
            },

            publishBehavior: "Publish behavior"
        };
    }
}
