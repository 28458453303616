import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Company } from 'src/app/model/company';
import { AuthService } from 'src/app/services/auth.service';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { HandleHttpService } from 'src/app/services/handle-http.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss', '../../../styles/defaultStyles.scss'],
})
export class CompanyComponent implements OnInit {
    constructor(
        public dataService: DataserviceService,
        private httpRequests: HandleHttpService,
        private sharedService: SharedService,
        private route: Router,
        private authService: AuthService
    ) { }

    myForm = new FormGroup({
        name: new FormControl(''),
        cnpj: new FormControl('')
    })

    editForm = new FormGroup({
        name: new FormControl(''),
        cnpj: new FormControl('')
    })

    dataSource = new MatTableDataSource<Company>([]);
    tableHeaderNames: Array<string> = ['#', 'Nome', 'CNPJ', 'Criado em', 'Atualizado em'];
    displayedColumns: string[] = ['id', 'name', 'cnpj', 'createdAt', 'updatedAt'];

    idPreview: any = {};
    isEditing: boolean = false;
    editingIndex: number;

    getEmmitedObject(receivedObject: any): void {
        this.dataService.company = receivedObject;
        this.sharedService.persistingData.setData(this.dataService)
        this.route.navigate(['/factory']);
    }

    editCurrentObject(editingObject: any): void {
        console.log("Edit Clicked");
        this.isEditing = true;
        this.editingIndex = editingObject.id;

        this.httpRequests.getRequest(`v1/companies/${editingObject.id}`)
            .subscribe((data: Company) => {
                this.editForm.setValue({ name: data.name, cnpj: data.cnpj })
            });
    }

    submitEdit(value: any): void {
        let editedObject: Company = { name: value.name, cnpj: value.cnpj }

        if (editedObject.name != undefined || editedObject.name != null
            && editedObject.cnpj != undefined || editedObject.cnpj != null) {
            this.httpRequests.updateRequest(`v1/companies/${this.editingIndex}`, editedObject)
                .subscribe(result => {
                    this.refresh();
                    this.isEditing = false;
                    this.sharedService.successSnackBar();
                }, error => {

                })
        }

    }

    deleteCurrentObject(deletingObject: any): void {
        Swal.fire({
            title: 'Você tem certeza de que deseja deletar esta empresa?',
            text: "Essa ação é irreversível!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Deletar'
        }).then((result) => {
            if (result.isConfirmed) {
                this.httpRequests.deleteRequest(`v1/companies/${deletingObject.id}`).subscribe(() => {
                    Swal.fire({
                        title: 'Empresa deletada com sucesso!',
                        icon: 'success',
                    })
                    this.refresh();
                }, (error) => {
                    Swal.fire({
                        title: 'Erro ao deletar a empresa',
                        text: 'Tente novamente mais tarde.',
                        icon: 'error',
                    })
                });
            }
        })
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh(): void {
        this.sharedService.getDataSource('companies', Company, this.dataSource, this.idPreview)
    }

    onSubmit(value: any): void {
        this.route.navigate(['/factory']);

        let postObject: Company = {
            name: value.name,
            cnpj: value.cnpj
        };

        if (postObject.name != undefined && postObject.cnpj != undefined) {
            this.httpRequests.postRequest("v1/companies", postObject).subscribe((data) => { this.refresh() })
            this.myForm.reset();
        }
    }

    logout() {
        this.authService.logout();
    }
}
