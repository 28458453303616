import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss', '../../../styles/defaultStyles.scss']
})
export class ShiftComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: Router,
  ) { }

  @ViewChild('userForm') userForm;

  myForm = new FormGroup({
    name: new FormControl(''),
    startTime: new FormControl(''),
    finishTime: new FormControl(''),
    days: new FormControl('')
  })

  editForm = new FormGroup({
    name: new FormControl(''),
    startTime: new FormControl(''),
    finishTime: new FormControl(''),
    days: new FormControl('')
  })

  days = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"];

  shifts = [
    { id: 0, name: 'Primeiro turno', startTime: '00:00', finishTime: '23:59', days: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"] },
    { id: 1, name: 'Segundo turno', startTime: '08:00', finishTime: '17:00', days: ["Seg", "Ter", "Qua"] },
    { id: 2, name: 'Terceiro turno', startTime: '13:00', finishTime: '15:30', days: ["Sab", "Dom"] }
  ]

  dataSource = new MatTableDataSource([]);
  tableHeaderNames: Array<string> = ['Turno', 'Hora Início', 'Hora Fim', 'Dias'];
  displayedColumns: string[] = ['name', 'startTime', 'finishTime', 'days'];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.shifts);
  }

  editCurrentObject(editingObject: any): void {
    this.isEditing = true;

    this.editingIndex = editingObject.id;

    let shiftSelected = this.shifts.find((item) => { return item.id == editingObject.id }); //Retorna o objeto com os valores do turno selecionada

    this.editForm.setValue({
      name: shiftSelected.name,
      startTime: shiftSelected.startTime,
      finishTime: shiftSelected.finishTime,
      days: shiftSelected.days
    });
  }

  deleteCurrentObject(deletingObject: any): void {
    Swal.fire({
      title: 'Você tem certeza de que deseja deletar este turno?',
      text: "Essa ação é irreversível!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Deletar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Turno deletado com sucesso!',
          icon: 'success',
        })
      }
    })
  }

  onSubmitShift(formValues): void {

    //ID ESTATICO - MUDAR FUTURAMENTE /////////////////////
    let idShift = this.shifts[this.shifts.length - 1].id + 1;

    this.shifts.push(
      {
        id: idShift,
        name: formValues.name,
        startTime: formValues.startTime,
        finishTime: formValues.finishTime,
        days: formValues.days
      },
    );
    this.dataSource = new MatTableDataSource(this.shifts);
    this.userForm.resetForm();
  }

  submitEdit(value: any): void {
    //Atualizar turno
    this.isEditing = false;
  }

  onSubmit() {
    //Seguir a página adiante 
    this.route.navigate(['/operators']);
  }

  logout() {
    this.authService.logout();
  }

}
