import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stop-monitoring',
  templateUrl: './stop-monitoring.component.html',
  styleUrls: ['./stop-monitoring.component.scss', '../../../styles/defaultStyles.scss']
})
export class StopMonitoringComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: Router
  ) { }

  @ViewChild('userForm') userForm;

  myForm = new FormGroup({
    machine: new FormControl('', Validators.required),
    origin: new FormControl('', Validators.required),
    sensor: new FormControl('', Validators.required),
    logic: new FormControl('', Validators.required),
  });

  editForm = new FormGroup({
    machine: new FormControl('', Validators.required),
    origin: new FormControl('', Validators.required),
    sensor: new FormControl('', Validators.required),
    logic: new FormControl('', Validators.required),
  });

  machines = [
    { name: "Máquina 1", id: 1, productionLineId: 1 },
    { name: "Máquina 2", id: 2, productionLineId: 2 },
    { name: "Máquina 3", id: 3, productionLineId: 3 },
  ];
  origins = ['digital', 'analog'];
  sensors = [
    { name: "Sensor 1", id: 1, machineId: 1 },
    { name: "Sensor 2", id: 2, machineId: 2 },
    { name: "Sensor 3", id: 3, machineId: 3 },
  ];
  logics = ['pulse', 'incremental', 'constante'];

  availableSensors = [];
  availableSensorsEdit = [];
  //Var auxiliar para que os formularios (criação e edição) não se alterem

  dataSource = new MatTableDataSource([]);
  tableHeaderNames: Array<string> = ['Máquina', 'Origem', 'Sensor', 'Lógica'];
  displayedColumns: string[] = ['machine', 'origin', 'sensor', 'logic'];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  stopsConfig = [
    { id: 1, machineId: 1, origin: 'digital', sensorId: 1, logic: 'pulse' },
    { id: 2, machineId: 2, origin: 'analog', sensorId: 2, logic: 'incremental' }
  ];

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.stopsConfig);
    this.formatTable();
  }

  formatTable() {//formata dados para apresentar na tabela
    let formatedTable = [];

    this.stopsConfig.forEach(config => {//percorre todas as config
      let auxMachine = this.machines.find(m => m.id === config.machineId);//Encontra a máquina da config pelo ID
      let auxSensor = this.sensors.find(s => s.id === config.sensorId);//Encontra o sensor da config pelo ID
      let auxOrigin = this.firstLetterUppercase(config.origin) + ' Input';//Torna a 1° letra maiuscula
      let auxLogic = this.firstLetterUppercase(config.logic);//Torna a 1° letra maiuscula
      formatedTable.push(//Adiciona var formatada (com nomes invés de ids) ao array auxiliar
        { id: config.id, machine: auxMachine.name, origin: auxOrigin, sensor: auxSensor.name, logic: auxLogic }
      )
    });

    this.dataSource = new MatTableDataSource(formatedTable);//atrela dados a tabela
  }

  firstLetterUppercase(string: string): string {//Transforma 1° letra da palavra passada em maiúscula
    if (string != undefined)
      return string.charAt(0).toUpperCase() + string.slice(1);
    else return null;
  }

  setAvailableSensors(machineId, from) {//A partir do ID da máquina, aponta sensores disponíveis para seleção
    let value;
    let auxSensors = [];
    if (from == 'form') value = machineId.value;//Se a ação for chamada do formulário, acessa o .value
    else value = machineId;

    this.sensors.forEach(s => {//Percorre todos os sensores, se o id for igual, adiciona ao vetor
      if (s.machineId == value) auxSensors.push(s);
    })

    if (this.isEditing) {//Se for ao editar uma config,
      this.availableSensorsEdit = auxSensors;//O vetor auxiliar para edição é quem recebe os dados
      this.editForm.controls['sensor'].setValue('');//"Limpa" o valor dos sensores
    }
    else this.availableSensors = auxSensors;
  }

  editCurrentObject(editingObject: any): void {
    this.isEditing = true;

    this.editingIndex = editingObject.id;

    let stopSelected = this.stopsConfig.find((item) => { return item.id == editingObject.id }); //Retorna o objeto com os valores da config selecionada
    let auxMachine = this.machines.find(m => m.id === stopSelected.machineId);//Retorna máquina da config selecionada
    let auxSensor = this.sensors.find(s => s.id === stopSelected.sensorId);//Retorna sensor da config selecionada
    this.setAvailableSensors(auxMachine.id, 'edit');//Altera os sensores disponíveis para seleção no form de edição

    this.editForm.setValue({
      machine: auxMachine.id,
      origin: stopSelected.origin,
      sensor: auxSensor.id,
      logic: stopSelected.logic
    });
  }

  deleteCurrentObject(deletingObject: any): void {
    Swal.fire({
      title: 'Você tem certeza de que deseja deletar esta configuração de parada?',
      text: "Essa ação é irreversível!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Deletar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Configuração de parada deletada com sucesso!',
          icon: 'success',
        })
      }
    })
  }

  onSubmitStopConfig(formValues): void {

    //ID ESTATICO - MUDAR FUTURAMENTE /////////////////////
    let idMonStop = this.stopsConfig[this.stopsConfig.length - 1].id + 1;

    this.stopsConfig.push(
      {
        id: idMonStop,
        machineId: formValues.machine,
        origin: formValues.origin,
        sensorId: formValues.sensor,
        logic: formValues.logic
      },
    );
    this.formatTable();//Formata disposição da tabela
    this.availableSensors = [];//"Limpa" valores disponíveis de sensores
    this.userForm.resetForm();//Reseta formulário
  }

  submitEdit(value: any): void {
    //Atualizar config de monitoramento de paradas
    this.isEditing = false;
  }

  onSubmit() {
    //Seguir a página adiante 
    this.route.navigate(['/production-monitoring']);
  }

  logout() {
    this.authService.logout();
  }
}
