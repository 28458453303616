export class Gateway {
    id?: number;
    name: string;
    createdAt?: string;
    updatedAt?: string;

    macAddress: string;
    
    productionLine?: {
        id?: number;
    }
}