import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-class',
    templateUrl: './stop-class.component.html',
    styleUrls: ['./stop-class.component.scss', '../../../styles/defaultStyles.scss'],
})
export class StopClassComponent implements OnInit {
    constructor(private authService: AuthService, private route: Router) {}

    @ViewChild('userForm') userForm;

    myForm = new FormGroup({
        class: new FormControl('', Validators.required),
        machine: new FormControl('', Validators.required),
    });

    editForm = new FormGroup({
        class: new FormControl('', Validators.required),
        machine: new FormControl('', Validators.required),
    });

    machines = [
        { name: 'Máquina 0', id: 0, productionLineId: 1 },
        { name: 'Máquina 1', id: 1, productionLineId: 1 },
        { name: 'Máquina 2', id: 2, productionLineId: 2 },
        { name: 'Máquina 3', id: 3, productionLineId: 3 },
    ];

    classes = [
        { id: 0, class: 'Classe 01', machineId: [0] },
        { id: 1, class: 'Classe 02', machineId: [1] },
    ];

    dataSource = new MatTableDataSource([]);
    tableHeaderNames: Array<string> = ['Classe', 'Máquina'];
    displayedColumns: string[] = ['class', 'machine'];

    idPreview: any = {};
    isEditing: boolean = false;
    editingIndex: number;

    ngOnInit(): void {
        this, this.formatTable();
    }

    formatTable() {
        //formata dados para apresentar na tabela
        let formatedTable = [];

        this.classes.forEach(classe => {
            //percorre todas as classes
            let auxMachines = '';
            classe.machineId.forEach(machine => {
                let auxM = this.machines.find(m => m.id === machine); //Encontra a máquina da classe pelo ID
                auxMachines += ', ' + auxM.name;
            });
            auxMachines = auxMachines.slice(1, auxMachines.length);
            formatedTable.push({ id: classe.id, class: classe.class, machine: auxMachines }); //Adiciona var formatada (com nomes invés de ids) ao array auxiliar
        });

        this.dataSource = new MatTableDataSource(formatedTable); //atrela dados a tabela
    }

    editCurrentObject(editingObject: any): void {
        this.isEditing = true;

        this.editingIndex = editingObject.id;

        let classSelected = this.classes.find(item => {
            return item.id == editingObject.id;
        }); //Retorna o objeto com os valores da classe selecionada

        let machinesSelected = [];
        classSelected.machineId.forEach(machine => {
            let auxM = this.machines.find(m => m.id === machine); //Encontra a máquina da classe pelo ID
            machinesSelected.push(auxM.id);
        });

        this.editForm.setValue({
            class: classSelected.class,
            machine: machinesSelected,
        });
    }

    deleteCurrentObject(deletingObject: any): void {
        Swal.fire({
            title: 'Você tem certeza de que deseja deletar esta classe?',
            text: 'Essa ação é irreversível!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Deletar',
        }).then(result => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Classe deletada com sucesso!',
                    icon: 'success',
                });
            }
        });
    }

    onSubmitClass(formValues): void {
        //ID ESTATICO - MUDAR FUTURAMENTE /////////////////////
        let idClass = this.classes[this.classes.length - 1].id + 1;

        this.classes.push({
            id: idClass,
            class: formValues.class,
            machineId: formValues.machine,
        });
        this.formatTable();
        this.userForm.resetForm();
    }

    submitEdit(value: any): void {
        //Atualizar classe
        this.isEditing = false;
    }

    onSubmit() {
        //Seguir a página adiante
        this.route.navigate(['/stop-reasons']);
    }

    logout() {
        this.authService.logout();
    }
}
