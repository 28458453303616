<div id="main">

    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Gateways</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" 
                    mat-mini-fab aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'5'"></app-nav>
        </nav>

        <span class="text-breadcrumbs">{{ breadCrumbs }}</span>

        <div class="flex-display">

            <app-dynamic-table class="table-component" 
                [dataSource]="dataSource" 
                [displayedColumns]="displayedColumns"
                [tableHeaderNames]="tableHeaderNames"
                (onRefresh)="refresh()"
                (emmitedObject)="getEmmitedObject($event)"
                (currentDeletingObject)="deleteCurrentObject($event)"
                (currentEditingObject)="editCurrentObject($event)"
                ></app-dynamic-table>
                
            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmit(userForm.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar Gateway
                        </div>
                        <div class="form-id">
                            ID <span># <strong>{{ idPreview.id  || '?' }}</strong></span>
                        </div>
                    </header>

                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do Gateway</mat-label>
                            <input matInput placeholder="Nome do Gateway" formControlName="name" autocomplete="off" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Mac Address</mat-label>
                            <input matInput placeholder="Mac Address" formControlName="macAddress" autocomplete="off" required>
                        </mat-form-field>
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="myForm.invalid" color="primary">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">
            
            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>
    
                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(userForm.value)"> 
                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do Gateway</mat-label>
                            <input matInput placeholder="Nome do Gateway" formControlName="name" autocomplete="off" required>
                        </mat-form-field>
    
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Mac Address</mat-label>
                            <input matInput placeholder="Mac Address" formControlName="macAddress" autocomplete="off" required>
                        </mat-form-field>
                    </div>
        
                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid" color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false" color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>