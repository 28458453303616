import { SensorXLS } from "./sensorXLS";

export class Sensor {
    id?: number;
    name: string;

    createdAt?: string;
    updatedAt?: string;

    monitoring?: boolean;
    dataCollectorOn?: boolean;
    showSensorSupervisory: boolean;
    
    isAggregateSensorEquals: boolean;

    ihmOperator: string;
    nameAddress: string;
    
    aggregateSensor?: {
        id?: number;
    }
    
    aggregationBehaviour?: {
        type?: string;
        
        time?: number;
        threshold?: number;
        processingAvgType?: string;
        
        isEndUpdatable?: boolean;
    }

    plcConfig?: {
        id?: number;
    }

    sensorDataType?: {
        id?: number;
    }

    sensorType?: {
        id?: number;
    }

    machine?: {
        id?: number;
    }

    static copyInterfaceToEntity(sensorInterface: SensorXLS): Sensor {
        
        return { 
            name: sensorInterface.name,
    
            monitoring: sensorInterface.monitoring,
            dataCollectorOn: sensorInterface.dataCollectorOn,
            showSensorSupervisory: sensorInterface.showSensorSupervisory,
            ihmOperator: sensorInterface.ihmOperator,
            nameAddress: sensorInterface.nameAddress,
    
            isAggregateSensorEquals: sensorInterface.isAggregateSensorEquals,
    
            aggregateSensor: {
                id: sensorInterface.aggregateSensorId
            },
    
            aggregationBehaviour: {
                type: sensorInterface.aggregationBehaviourType,
                time: sensorInterface.aggregationBehaviourTime,
                threshold: sensorInterface.aggregationBehaviourThreshold,
                processingAvgType: sensorInterface.aggregationBehaviourProcessingAvgType,
                isEndUpdatable: sensorInterface.aggregationBehaviourIsEndUpdatable
            },
    
            sensorDataType: {
                id: sensorInterface.sensorDataType
            },
    
            sensorType: {
                id: sensorInterface.sensorType
            },

            machine: { 
                id: sensorInterface.machineId
            },

            plcConfig: { 
                id: sensorInterface.plcConfigId
            }
        }
    }
}
