import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { ProductionMonitoring } from '../../model/production-monitoring';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-production-monitoring',
  templateUrl: './production-monitoring.component.html',
  styleUrls: [
    './production-monitoring.component.scss',
    '../../../styles/defaultStyles.scss',
  ],
})
export class ProductionMonitoringComponent implements OnInit {
  constructor(private authService: AuthService) {}

  @ViewChild('userForm') userForm;

  machines: Array<string> = ['Máquina 1', 'Máquina 2', 'Máquina 3'];
  origins: Array<string> = ['PLC1', 'PLC2', 'PLC3'];
  sensors: Array<string> = ['D1', 'D2', 'D3'];
  logics: Array<string> = ['Pulse', 'Incremental', 'Constante'];

  myForm = new FormGroup({
    machine: new FormControl(''),
    origin: new FormControl(''),
    sensor: new FormControl(''),
    logic: new FormControl(''),
  });
  editForm = new FormGroup({
    selectedMachine: new FormControl(''),
    selectedLogic: new FormControl(''),
  });

  dataSource = new MatTableDataSource<ProductionMonitoring>([
    {
      id: 1,
      machine: 'Máquina 1',
      origin: 'Digital Input',
      sensor: 'D1',
      logic: 'Pulse',
    },
    {
      id: 2,
      machine: 'Máquina 2',
      origin: 'Digital Input',
      sensor: 'D2',
      logic: 'Incremental',
    },
    {
      id: 3,
      machine: 'Máquina 3',
      origin: 'Digital Input',
      sensor: 'D3',
      logic: 'Constante',
    },
    {
      id: 4,
      machine: 'Máquina 4',
      origin: 'Digital Input',
      sensor: 'D4',
      logic: 'Pulse',
    },
  ]);
  tableHeaderNames: Array<string> = ['#', 'Máquina', 'Lógica'];
  displayedColumns: string[] = ['id', 'machine', 'logic'];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  getEmmitedObject(receivedObject: any): void {
    console.log('getEmmitedObject', receivedObject);
  }

  editCurrentObject(editingObject: any): void {
    console.log('editCurrentObject', editingObject);

    this.isEditing = true;
    this.editingIndex = editingObject.id;

    let productionMonitoring: ProductionMonitoring;
    this.dataSource.data.forEach((element) => {
      if (element.id == editingObject.id) {
        productionMonitoring = element;
      }
    });

    this.editForm.patchValue({
      selectedMachine: productionMonitoring.machine,
      selectedLogic: productionMonitoring.logic,
    });
  }

  submitEdit(value: any): void {
    console.log('submitEdit', value);
  }

  deleteCurrentObject(deletingObject: any): void {
    console.log('deleteCurrentObject', deletingObject);
  }

  ngOnInit(): void {}

  onSubmit(value: any): void {
    console.log(value);
    
    let id = 0;

    if (this.dataSource.data.length > 0)
      id = this.dataSource.data[this.dataSource.data.length - 1].id + 1;

    const line = {id: id, machine: value.machine, origin: value.origin, sensor: value.sensor, logic: value.logic};

    this.dataSource.data.push(line);
    this.dataSource._updateChangeSubscription();
    this.userForm.resetForm();
  }

  logout() {
    this.authService.logout();
  }
}
