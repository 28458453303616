import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Machine } from 'src/app/model/machine';
import { AuthService } from 'src/app/services/auth.service';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { HandleHttpService } from 'src/app/services/handle-http.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-machines',
    templateUrl: './machines.component.html',
    styleUrls: ['./machines.component.scss', '../../../styles/defaultStyles.scss']
})
export class MachinesComponent implements OnInit {

    constructor(
        public dataService: DataserviceService,
        public sharedService: SharedService,
        private httpRequests: HandleHttpService,
        private route: Router,
        private authService: AuthService
    ) { }

    dataSource = new MatTableDataSource([]);
    tableHeaderNames: Array<string> = ['Máquina', 'Linha'];
    displayedColumns: string[] = ["name", "prodLineName"];

    idPreview: any = {};
    breadCrumbs: string;
    isEditing: boolean = false;
    editingIndex: number;

    productionLines = [
        { name: "Linha 1", id: 1 },
        { name: "Linha 2", id: 2 },
        { name: "Linha 3", id: 3 }
    ];

    machines = [
        { name: "Máquina 1", id: 1, productionLineId: 1 },
        { name: "Máquina 2", id: 2, productionLineId: 2 },
        { name: "Máquina 3", id: 3, productionLineId: 3 },
    ];

    myForm = new FormGroup({
        name: new FormControl(''),
        productionLine: new FormControl('')
    })

    editForm = new FormGroup({
        name: new FormControl(''),
        productionLine: new FormControl('')
    })

    getEmmitedObject(receivedObject: any): void {
        this.dataService.machine = receivedObject;
        this.sharedService.persistingData.setData(this.dataService)

        this.route.navigate(['/gateway']);
    }

    editCurrentObject(editingObject: any): void {
        this.isEditing = true;
        this.editingIndex = editingObject.id;

        // this.httpRequests.getRequest(`v1/machines/${editingObject.id}`).subscribe((data: Machine) => {
        //     this.editForm.setValue({ name: data.name })
        // });

        let machineSelected = this.machines.find((item) => { return item.id == editingObject.id }); //Retorna o objeto com os valores da maquina selecionada

        this.editForm.setValue({ name: machineSelected.name, productionLine: machineSelected.productionLineId });
    }

    deleteCurrentObject(deletingObject: any): void {
        Swal.fire({
            title: 'Você tem certeza de que deseja deletar esta máquina?',
            text: "Essa ação é irreversível!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Deletar'
        }).then((result) => {
            if (result.isConfirmed) {
                this.httpRequests.deleteRequest(`v1/machines/${deletingObject.id}`).subscribe(() => {
                    Swal.fire({
                        title: 'Máquina deletada com sucesso!',
                        icon: 'success',
                    })
                    this.refresh();
                }, (error) => {
                    Swal.fire({
                        title: 'Erro ao deletar a máquina',
                        text: 'Tente novamente mais tarde.',
                        icon: 'error',
                    })
                });
            }
        })
    }

    submitEdit(value: any): void {

        // let editedObject: Machine = { name: value.name }

        // if (editedObject.name != undefined || editedObject.name != null) {
        //     this.httpRequests.updateRequest(`v1/machines/${this.editingIndex}`, editedObject).subscribe(() => {
        //         this.refresh();
        //         this.isEditing = false;
        //     })
        // }

        this.isEditing = false;
    }

    ngOnInit(): void {
        // try {
        //     if (this.sharedService.persistingData.getData().productionCell.id == undefined)
        //         console.log("does not exist");
        //     else
        //         this.dataService = this.sharedService.persistingData.getData();
        // } catch (error) {
        //     this.sharedService.goHome()
        // }

        // this.breadCrumbs = `
        // ${this.dataService.company.name} > 
        // ${this.dataService.factory.name} > 
        // ${this.dataService.productionLine.name} >
        // ${this.dataService.productionCell.name} >`

        // this.refresh();

        this.updateTable();
    }


    refresh(): void {
        this.sharedService.getDataSource('machines/production-cells', Machine, this.dataSource, this.idPreview, this.dataService.productionCell.id)
    }

    onSubmitMachine(machineName: string, productionLineId: number): void {

        //ID ESTATICO - MUDAR FUTURAMENTE /////////////////////
        let idMaquina = this.machines[this.machines.length - 1].id + 1;

        this.machines.push({ name: machineName, id: idMaquina, productionLineId: productionLineId });
        this.updateTable();

        //Reset dos inputs da maquina
        this.myForm.get('name').reset();
        this.myForm.get('name').setErrors(null);
        this.myForm.get('productionLine').reset();
        this.myForm.get('productionLine').setErrors(null);
    }

    updateTable() {
        let auxMachines = [];
        //percorre as máquinas e substitui o ID da linha pelo Nome da Linha para mostrar na tabela
        this.machines.forEach(machine => {
            let productionLine = this.productionLines.find((item) => { return item.id == machine.productionLineId });//Retorna a Linha da máquina
            auxMachines.push({ name: machine.name, id: machine.id, prodLineName: productionLine.name });
        })
        this.dataSource = new MatTableDataSource(auxMachines);
    }

    onSubmit(value: any): void {
        // let postObject: Machine = { name: value.name, productionCell: { id: this.dataService.productionCell.id } };

        // this.httpRequests.postRequest("v1/machines/", postObject).subscribe((data) => { this.refresh() })
        // this.myForm.reset();
        this.route.navigate(['/shift']);

    }

    logout() {
        this.authService.logout();
    }
}
