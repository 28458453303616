import { Component, ElementRef, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Sensor } from 'src/app/model/sensor';
import { SensorType } from 'src/app/model/sensorType';
import { SensorXLS } from 'src/app/model/sensorXLS';
import { AuthService } from 'src/app/services/auth.service';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { HandleHttpService } from 'src/app/services/handle-http.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

import * as XLSX from 'xlsx';

@Component({
    selector: 'app-sensor',
    templateUrl: './sensor.component.html',
    styleUrls: ['./sensor.component.scss', '../../../styles/defaultStyles.scss']
})
export class SensorComponent implements OnInit {


    constructor(
        public dataService: DataserviceService,
        private sharedService: SharedService,
        private httpRequests: HandleHttpService,
        private route: Router,
        private authService: AuthService
    ) { }

    dataSource = new MatTableDataSource([]);
    tableHeaderNames: Array<string> = ['#', 'Nome', 'Monitorando', 'Coletando Dados', 'Supervisório', 'Comportamento de Agregação', 'Tipo do Sensor', 'Operador IHM', 'Endereço'];
    displayedColumns: string[] = ['id', 'name', 'monitoring', 'dataCollectorOn', 'showSensorSupervisory', 'aggregationBehaviour', 'sensorType', 'ihmOperator', 'nameAddress'];

    breadCrumbs: string;
    filteredOptions: Observable<string[]>;
    isEditing: boolean = false;
    editingIndex: number;

    isUploadingFile: boolean = false;

    aggregationOptions: any = [
        { value: undefined, name: '---' },
        { value: 'on_change', name: 'Alteração (OnChange)' },
        { value: 'aggregate_on_change', name: 'Alteração Agregada (Aggregate OnChange)' },
        { value: 'counter', name: 'Contador' },
        { value: 'time_counter', name: 'Contador Timer' },
        { value: 'pulse', name: 'Pulso' },
        { value: 'raw', name: 'Sem Compressão (Raw)' },
        { value: null, name: 'Nenhum (null)' },
    ]

    processingAvgTypes: any = [
        { value: 'gradual', name: 'Gradual' },
        { value: 'notGradual', name: 'Não Gradual (padrão)' }
    ]

    monitoringOptions: any = [
        { value: true, name: 'Sim' },
        { value: false, name: 'Não' }
    ]

    @ViewChild('fileInput')
    fileInput: ElementRef;

    myForm = new FormGroup({
        name: new FormControl(''),

        aggregateSensor: new FormControl(''),

        type: new FormControl(''),
        time: new FormControl(''),
        threshold: new FormControl(''),

        plcConfig: new FormControl(''),
    })
    monitoring = new FormControl('');
    dataCollectorOn = new FormControl('');
    showSensorSupervisory = new FormControl('');
    aggregationType = new FormControl('');
    sensorDataType = new FormControl('');
    sensorType = new FormControl('');

    isEndUpdatable = new FormControl('');
    processingAvgType = new FormControl('');

    isAggregateSensorEquals = new FormControl(true);

    ihmOperator = new FormControl('');
    nameAddress = new FormControl('');


    editForm = new FormGroup({
        name: new FormControl(''),
    })

    typeConstraints = new FormGroup({
        time: new FormControl(''),
        threshold: new FormControl(''),
        isEndUpdatable: new FormControl(''),
        processingAvgType: new FormControl(''),
    })


    getEmmitedObject(receivedObject: any) {
        this.dataService.sensor = receivedObject;
        this.sharedService.persistingData.setData(this.dataService)
        this.route.navigate(['/lobot']);
    }

    editCurrentObject(editingObject: any): void {
        this.isEditing = true;
        this.editingIndex = editingObject.id;

        this.httpRequests.getRequest(`v1/sensors/${editingObject.id}`).subscribe((data: Sensor) => {

            console.log(data);

            this.editForm.setValue({ name: data.name });
            this.monitoring.setValue(data.monitoring);
            this.showSensorSupervisory.setValue(data.showSensorSupervisory);
            this.dataCollectorOn.setValue(data.dataCollectorOn);
            this.sensorDataType.setValue(data.sensorDataType.id);
            this.sensorType.setValue(data.sensorType.id);

            this.ihmOperator.setValue(data.ihmOperator)
            this.nameAddress.setValue(data.nameAddress)

            this.aggregationType.setValue(data.aggregationBehaviour.type);

            this.isAggregateSensorEquals.setValue(data.isAggregateSensorEquals);

            if (data.aggregationBehaviour.type == 'aggregate_on_change') {
                this.typeConstraints.get('time').setValue(data.aggregationBehaviour.time)
                this.typeConstraints.get('threshold').setValue(data.aggregationBehaviour.threshold)
            } else if (data.aggregationBehaviour.type == 'on_change') {
                this.typeConstraints.get('isEndUpdatable').setValue(data.aggregationBehaviour.isEndUpdatable)
            }
        });
    }

    submitEdit(value: any): void {
        let editedObject: Sensor =
        {
            name: value.name,

            monitoring: this.monitoring.value,
            dataCollectorOn: this.dataCollectorOn.value,
            showSensorSupervisory: this.showSensorSupervisory.value,
            isAggregateSensorEquals: this.isAggregateSensorEquals.value,
            ihmOperator: this.ihmOperator.value,
            nameAddress: this.nameAddress.value,

            aggregationBehaviour: {
                type: this.aggregationType.value,
            },

            sensorType: {
                id: this.sensorType.value
            },

            sensorDataType: {
                id: this.sensorDataType.value
            },

        };

        if (editedObject.aggregationBehaviour.type == 'on_change') {
            editedObject.aggregationBehaviour = {
                type: this.aggregationType.value,
                isEndUpdatable: value.isEndUpdatable
            }
        } else if (editedObject.aggregationBehaviour.type == 'aggregate_on_change') {
            editedObject.aggregationBehaviour = {
                type: this.aggregationType.value,

                time: this.typeConstraints.get('time').value,
                threshold: this.typeConstraints.get('threshold').value
            };
        }

        //todo: if dataCollectorOn is off should I save data type and aggr behaviour?
        // if(editedObject.dataCollectorOn == false){
        //     editedObject.aggregationBehaviour = null;
        // }

        console.log(editedObject);

        if (editedObject.name != undefined || editedObject.name != null) {
            this.httpRequests.updateRequest(`v1/sensors/${this.editingIndex}`, editedObject).subscribe(
                result => {
                    this.refresh();
                    this.isEditing = false;

                    this.myForm.reset();
                    this.monitoring.reset();
                    this.dataCollectorOn.reset();
                    this.showSensorSupervisory.reset();
                    this.aggregationType.reset();
                    this.sensorDataType.reset();
                    this.sensorType.reset();
                    this.isAggregateSensorEquals.reset();
                }, error => {

                })
        }

    }

    cancelEdit(event: any) {
        this.isEditing = false;
        this.myForm.reset();
        this.monitoring.reset();
        this.dataCollectorOn.reset();
        this.showSensorSupervisory.reset();
        this.aggregationType.reset();
        this.sensorDataType.reset();
        this.sensorType.reset();
        this.isAggregateSensorEquals.reset();
    }

    deleteCurrentObject(deletingObject: any): void {
        Swal.fire({
            title: 'Você tem certeza de que deseja deletar este sensor?',
            text: "Essa ação é irreversível!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Deletar'
        }).then((result) => {
            if (result.isConfirmed) {
                this.httpRequests.deleteRequest(`v1/sensors/${deletingObject.id}`).subscribe(() => {
                    Swal.fire({
                        title: 'Sensor deletado com sucesso!',
                        icon: 'success',
                    })
                    this.refresh();
                }, (error) => {
                    Swal.fire({
                        title: 'Erro ao deletar o sensor',
                        text: 'Tente novamente mais tarde.',
                        icon: 'error',
                    })
                });
            }
        })
    }

    ngOnInit(): void {

        // try {
        //     if (this.sharedService.persistingData.getData().plc.id == undefined)
        //         console.log("does not exist");
        //     else
        //         this.dataService = this.sharedService.persistingData.getData();
        // } catch (error) {
        //     this.sharedService.goHome()
        // }

        // this.breadCrumbs = `
        // ${this.dataService.company.name} > 
        // ${this.dataService.factory.name} > 
        // ${this.dataService.productionLine.name} >
        // ${this.dataService.productionCell.name} >
        // ${this.dataService.machine.name} >
        // ${this.dataService.gateway.name} >
        // ${this.dataService.plc.plcName}`

        // this.refresh();

        // this.getSensorDataTypes();
        // this.getSensorTypes();
    }

    idPreview: any = {};

    refresh() {
        this.sharedService.getDataSource('sensors/plc-configs', Sensor, this.dataSource, this.idPreview, this.dataService.plc.id)
    }

    sensorDataTypeOptions: string[];
    sensorTypeOptions: SensorType[];

    getSensorDataTypes(): void {
        this.httpRequests.getRequest(`v1/sensor-data-types`).subscribe((data: any[]) => {
            this.sensorDataTypeOptions = data;
        })
    }

    getSensorTypes(): void {
        this.httpRequests.getRequest(`v1/sensor-types`).subscribe((data: any[]) => {
            this.sensorTypeOptions = data;
        })
    }

    fileName: string = ''
    uploadingData: Sensor[];
    displayDataSource: MatTableDataSource<any>;

    onFileSelected(evt: any) {
        const target: DataTransfer = <DataTransfer>(evt.target);

        if (target.files.length !== 1) throw new Error('Cannot use multiple files');

        const selectedFile = evt.target.files[0];
        const fileReader: FileReader = new FileReader();
        this.fileName = selectedFile.name;

        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = (event) => {
            let binaryData = event.target.result;
            let wb = XLSX.read(binaryData, { type: 'binary' })

            wb.SheetNames.map(sheet => {
                const data = XLSX.utils.sheet_to_json<SensorXLS>(wb.Sheets[sheet])
                const sensorReturn = data.map((sensorRaw) => Sensor.copyInterfaceToEntity(sensorRaw))

                sensorReturn.map((sensor) => {
                    if (sensor.aggregateSensor.id == undefined)
                        sensor.aggregateSensor = null;

                    sensor.machine.id = this.dataService.machine.id;
                    sensor.plcConfig.id = this.dataService.plc.id;
                })

                this.uploadingData = sensorReturn;
                this.displayDataSource = new MatTableDataSource(JSON.parse(JSON.stringify(sensorReturn)));
            })
            this.checkUploadedFiles();
        }
    }

    checkUploadedFiles() {
        this.isUploadingFile = true;

        this.displayDataSource.data.map((sensorItem) => {
            if (sensorItem.aggregationBehaviour) {
                if (sensorItem.aggregationBehaviour.type == null)
                    sensorItem.aggregationBehaviour = "Desligado"
                else if (sensorItem.aggregationBehaviour.type == "on_change")
                    sensorItem.aggregationBehaviour = "Alteração (OnChange)"

                else if (sensorItem.aggregationBehaviour.type == "aggregate_on_change")
                    sensorItem.aggregationBehaviour = "Alteração Agregada"

                else if (sensorItem.aggregationBehaviour.type == "counter")
                    sensorItem.aggregationBehaviour = "Contador"

                else if (sensorItem.aggregationBehaviour.type == "time_counter")
                    sensorItem.aggregationBehaviour = "Contador Timer"

                else if (sensorItem.aggregationBehaviour.type == "pulse")
                    sensorItem.aggregationBehaviour = "Pulso"

                else if (sensorItem.aggregationBehaviour.type == "raw")
                    sensorItem.aggregationBehaviour = "Sem Compressão (raw)"
            }

            if (sensorItem.sensorType) {
                let sensorTypeFilter = this.sensorTypeOptions.find(optional => optional.id === sensorItem.sensorType.id)
                sensorItem.sensorType = sensorTypeFilter.name;
            }
        })
    }

    submitFileToSensor() {
        if (this.uploadingData !== null) {
            this.httpRequests.postRequest("v1/sensors/list", this.uploadingData).subscribe((data) => {
                this.refresh();
                this.isUploadingFile = false;
                this.fileInput.nativeElement.value = ''
                this.fileName = 'Importar Arquivo';
            })
        }
    }

    cancelUpload(event: any) {
        this.isUploadingFile = false;
        this.fileInput.nativeElement.value = ''
        this.fileName = 'Importar Arquivo';
    }

    returnVal(element: any, dispCol: any) {
        switch (dispCol) {
            case 'name': return element.name;

            case 'monitoring': return element.monitoring;
            case 'dataCollectorOn': return element.dataCollectorOn;
            case 'aggregationBehaviour': return element.aggregationBehaviour;

            case 'showSensorSupervisory': return element.showSensorSupervisory;
            case 'sensorType': return element.sensorType;

            case 'ihmOperator': return element.ihmOperator;
            case 'nameAddress': return element.nameAddress;
        }
    }

    onSubmit(value: any) {
        this.route.navigate(['/lobot']);

        // let postObject: Sensor = {
        //     name: value.name,

        //     monitoring: this.monitoring.value,
        //     dataCollectorOn: this.dataCollectorOn.value,
        //     showSensorSupervisory: this.showSensorSupervisory.value,
        //     isAggregateSensorEquals: this.isAggregateSensorEquals.value,

        //     ihmOperator: this.ihmOperator.value,
        //     nameAddress: this.nameAddress.value,

        //     aggregationBehaviour: {
        //         type: this.aggregationType.value,
        //     },

        //     sensorType: {
        //         id: this.sensorType.value
        //     },

        //     sensorDataType: {
        //         id: this.sensorDataType.value
        //     },

        //     plcConfig: {
        //         id: this.dataService.plc.id
        //     },

        //     machine: {
        //         id: this.dataService.machine.id
        //     },
        // };

        // if (!(postObject.isAggregateSensorEquals)) {
        //     postObject.aggregateSensor = {
        //         id: value.aggregateSensor
        //     }
        // }

        // if (postObject.aggregationBehaviour.type == 'on_change') {
        //     postObject.aggregationBehaviour = {
        //         type: this.aggregationType.value,
        //         isEndUpdatable: this.isEndUpdatable.value
        //     }
        // } else if (postObject.aggregationBehaviour.type == 'aggregate_on_change') {
        //     postObject.aggregationBehaviour = {
        //         type: this.aggregationType.value,
        //         time: value.time,
        //         threshold: value.threshold,
        //         processingAvgType: this.processingAvgType.value
        //     };
        // } else if (postObject.aggregationBehaviour.type == 'counter') {
        //     postObject.aggregationBehaviour = {
        //         type: this.aggregationType.value,
        //         isEndUpdatable: this.isEndUpdatable.value
        //     };
        // }

        // if (postObject.name != undefined && postObject.machine.id != undefined) {
        //     this.httpRequests.postRequest("v1/sensors", postObject).subscribe((data) => {
        //         this.refresh();
        //     })
        //     this.myForm.reset();
        //     this.monitoring.reset();
        //     this.dataCollectorOn.reset();
        //     this.showSensorSupervisory.reset();
        //     this.aggregationType.reset();
        //     this.sensorDataType.reset();
        //     this.sensorType.reset();
        //     this.isAggregateSensorEquals.reset();
        // }
    }

    logout() {
        this.authService.logout();
    }
}
