<div id="main">
    <div class="content">
        <mat-toolbar class="header">
            <span>Cadastro Rápido</span>
        </mat-toolbar>

        <div class="flex-display">
            <div class="form-cards">

                <mat-card>
                    <header>
                        <span>Cadastrar Empresa</span>
                        <div class="form-id">
                            ID <span># <strong> 1234 </strong></span>
                        </div>
                    </header>

                    <input mat-input type="text" placeholder="Nome da Empresa">
                    <input mat-input type="text" placeholder="CNPJ da Empresa">
                </mat-card>

                <mat-card>
                    <header>
                        <span>Cadastrar Fábrica</span>
                        <div class="form-id">
                            ID <span># <strong> 1234 </strong></span>
                        </div>
                    </header>

                    <input mat-input type="text" placeholder="Nome da Fábrica">
                </mat-card>

                <mat-card>
                    <header>
                        <span>Cadastrar Linha de Produção</span>
                        <div class="form-id">
                            ID <span># <strong> 1234 </strong></span>
                        </div>
                    </header>

                    <input mat-input type="text" placeholder="Nome da Linha de Produção">
                </mat-card>

                <mat-card>
                    <header>
                        <span>Cadastrar Célula de Produção</span>
                        <div class="form-id">
                            ID <span># <strong> 1234 </strong></span>
                        </div>
                    </header>

                    <input mat-input type="text" placeholder="Nome da Célula de Produção">
                </mat-card>

                <mat-card>
                    <header>
                        <span>Cadastrar Máquina</span>
                        <div class="form-id">
                            ID <span># <strong> 1234 </strong></span>
                        </div>
                    </header>

                    <input mat-input type="text" placeholder="Nome da Máquina">
                </mat-card>

            </div>

            <div class="confirm-buttons-wrapper">
                <mat-card class="confirm-buttons">
                    <button class="btn-default btn-filled">Salvar</button>
                    <button [routerLink]="['/company']" class="btn-default btn-outlined">Voltar</button>
                </mat-card>
            </div>

        </div>
    </div>
</div>