<div id="main">

    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | PLCs</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" 
                    mat-mini-fab aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'6'"></app-nav>
        </nav>

        <span class="text-breadcrumbs">{{ breadCrumbs }}</span>

        <div class="flex-display">

            <app-dynamic-table class="table-component" 
                [dataSource]="dataSource" 
                [displayedColumns]="displayedColumns"
                [tableHeaderNames]="tableHeaderNames"
                (onRefresh)="refresh()"
                (emmitedObject)="getEmmitedObject($event)"
                (currentEditingObject)="editCurrentObject($event)"
                (currentDeletingObject)="deleteCurrentObject($event)"
                ></app-dynamic-table>

            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmit(userForm.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar PLC
                        </div>
                        <div class="form-id">
                            ID <span># <strong> {{ idPreview.id || '?'}} </strong></span>
                        </div>
                    </header>

                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do PLC</mat-label>
                            <input matInput placeholder="Nome do PLC" formControlName="name" autocomplete="off" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Protocolo do PLC</mat-label>
                            <mat-select [formControl]="plcDriver">
                                <mat-option *ngFor="let option of options" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <ng-container *ngIf="plcDriver.value == 'Allen Bradley'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Endereço</mat-label>
                                <input matInput placeholder="Endereço" autocomplete="off" formControlName="hostIp">
                            </mat-form-field>
                        </ng-container>
                        
                        <ng-container *ngIf="plcDriver.value == 'Modbus Serial'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Porta</mat-label>
                                <input matInput placeholder="Porta" autocomplete="off" formControlName="hostIp">
                            </mat-form-field>
    
                            <mat-form-field appearance="outline">
                                <mat-label class="label">BaudRate</mat-label>
                                <input matInput placeholder="BaudRate" autocomplete="off" formControlName="slot">
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="plcDriver.value == 'Modbus TCP'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Endereço</mat-label>
                                <input matInput placeholder="Endereço" autocomplete="off" formControlName="hostIp">
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label class="label">Porta</mat-label>
                                <input matInput placeholder="Porta" autocomplete="off" formControlName="slot">
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="plcDriver.value == 'OPC UA'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Host (?)</mat-label>
                                <input matInput placeholder="Host" autocomplete="off" formControlName="hostIp">
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="plcDriver.value == 'Siemens S7'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Endereço</mat-label>
                                <input matInput placeholder="Endereço" autocomplete="off" formControlName="hostIp">
                            </mat-form-field>
    
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Rack</mat-label>
                                <input matInput placeholder="Rack" autocomplete="off" formControlName="rack">
                            </mat-form-field>
    
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Slot</mat-label>
                                <input matInput placeholder="Slot" autocomplete="off" formControlName="slot">
                            </mat-form-field>
                        </ng-container>
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="myForm.invalid" color="primary">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>
    
                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(userForm.value)"> 
                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do PLC</mat-label>
                            <input matInput placeholder="Nome do PLC" formControlName="name" autocomplete="off" required>
                        </mat-form-field>
    
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Protocolo do PLC</mat-label>
                            <mat-select [formControl]="plcDriver">
                                <mat-option *ngFor="let option of options" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <ng-container *ngIf="plcDriver.value == 'Allen Bradley'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Endereço</mat-label>
                                <input matInput placeholder="Endereço" autocomplete="off" formControlName="hostIp">
                            </mat-form-field>
                        </ng-container>
                        
                        <ng-container *ngIf="plcDriver.value == 'Modbus Serial'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Porta</mat-label>
                                <input matInput placeholder="Porta" autocomplete="off" formControlName="hostIp">
                            </mat-form-field>
    
                            <mat-form-field appearance="outline">
                                <mat-label class="label">BaudRate</mat-label>
                                <input matInput placeholder="BaudRate" autocomplete="off" formControlName="slot">
                            </mat-form-field>
                        </ng-container>
    
                        <ng-container *ngIf="plcDriver.value == 'Modbus TCP'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Endereço</mat-label>
                                <input matInput placeholder="Endereço" autocomplete="off" formControlName="hostIp">
                            </mat-form-field>
    
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Porta</mat-label>
                                <input matInput placeholder="Porta" autocomplete="off" formControlName="slot">
                            </mat-form-field>
                        </ng-container>
    
                        <ng-container *ngIf="plcDriver.value == 'OPC UA'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Host (?)</mat-label>
                                <input matInput placeholder="Host" autocomplete="off" formControlName="hostIp">
                            </mat-form-field>
                        </ng-container>
    
                        <ng-container *ngIf="plcDriver.value == 'Siemens S7'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Endereço</mat-label>
                                <input matInput placeholder="Endereço" autocomplete="off" formControlName="hostIp">
                            </mat-form-field>
    
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Rack</mat-label>
                                <input matInput placeholder="Rack" autocomplete="off" formControlName="rack">
                            </mat-form-field>
    
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Slot</mat-label>
                                <input matInput placeholder="Slot" autocomplete="off" formControlName="slot">
                            </mat-form-field>
                        </ng-container>
                    </div>
        
                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid" color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false" color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>