import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HandleHttpService implements HttpInterceptor {
    constructor(private http: HttpClient) { }

    //Changes the default URL that makes HTTP Requests
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //mudar url quando for enviar para o bucket
        // const url = environment.apiUrl;
        req = req.clone({
            // url: url + req.url,
            headers: req.headers
                .set('Access-Control-Allow-Origin', '*')
        });

        return next.handle(req);
    }

    //Generic get request
    getRequest<T>(request: string): Observable<T> {
        return this.http.get<T>(`${environment.apiUrl}/${request}`);
    }

    //Generic post request
    postRequest(request: string, postingObject: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/${request}`, postingObject);
    }

    updateRequest(request: string, updatingObject: any) {
        return this.http.put(`${environment.apiUrl}/${request}`, updatingObject);
    }

    deleteRequest(request: string) {
        return this.http.delete(`${environment.apiUrl}/${request}`);
    }
}
