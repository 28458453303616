import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Lobot } from 'src/app/model/lobot';
import { LobotXLS } from 'src/app/model/lobotXLS';
import { SensorType } from 'src/app/model/sensorType';
import { AuthService } from 'src/app/services/auth.service';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { HandleHttpService } from 'src/app/services/handle-http.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

import * as XLSX from 'xlsx';

@Component({
    selector: 'app-lobot',
    templateUrl: './lobot.component.html',
    styleUrls: ['./lobot.component.scss', '../../../styles/defaultStyles.scss']
})
export class LobotComponent implements OnInit {

    //#region variables

    //Variáveis Table
    dataSource = new MatTableDataSource([]);
    tableHeaderNames: Array<string> = ['#', 'Nome', 'Criado em'];
    displayedColumns: string[] = ['id', 'description', 'createdAt'];
    filteredOptions: Observable<string[]>;

    //Variaveis auxiliares
    breadCrumbs: string;
    idPreview: any = {};
    isEditing: boolean = false;
    editingIndex: number;

    //Variaveis do Form Principal

    myForm: FormGroup;

    logicForm: FormGroup;

    logicArgs = new FormControl('');
    logicId = new FormControl('');
    monitoring = new FormControl('');
    dataCollectorOn = new FormControl('');
    aggregationType = new FormControl('');
    sensorDataType = new FormControl('');
    isAggregateSensorEquals = new FormControl(true);
    isLogicArgPredefined = new FormControl(true);

    resultPositive = new FormControl('');
    resultNegative = new FormControl('');
    isTimeArgument = new FormControl(true);
    timeArgument = new FormControl('');

    sensorDataTypeOptions: string[];
    sensorTypeOptions: SensorType[];

    sensorsList: any[];

    sensorType = new FormControl('');

    // Variáveis de importação

    importHeaderNames: Array<string> = [
        'Nome',
        'Id Lógico',
        'Argumentos',
        'Coletando Dados',
        'Tipo do Sensor',
        'Comportamento de Agregação',
        // 'Tempo',
        // 'Limiar',
    ];
    importColumns: string[] = [
        'description',
        'logicId',
        // 'logicArgs',
        'dataCollectorOn',
        'sensorDataType',
        'aggregationType',
        // 'aggregationTime',
        // 'aggregationThreshold'
    ];
    fileName: string = ''
    uploadingData: Lobot[];
    displayDataSource: MatTableDataSource<any>;
    isUploadingFile: boolean = false;

    @ViewChild('fileInput')
    fileInput: ElementRef;

    //Variaveis do Form Editor

    editForm = new FormGroup({
        name: new FormControl(''),
    })

    //Variaveis do Form para o construtor e Logic Arg
    operations: FormArray;
    operationsCount: number = 0;

    finalString: string = '';

    isCreatingLogic: boolean = false;

    //Variáveis de arrays

    aggregationOptions: any = [
        { value: undefined, name: '---' },
        { value: 'on_change', name: 'Alteração (OnChange)' },
        { value: 'aggregate_on_change', name: 'Alteração Agregada (Aggregate OnChange)' },
        { value: 'counter', name: 'Contador' },
        { value: 'time_counter', name: 'Contador Timer' },
        { value: 'pulse', name: 'Pulso' },
        { value: 'raw', name: 'Sem Compressão (Raw)' },
        { value: null, name: 'Desligado (null)' },
    ]

    monitoringOptions: any = [
        { value: true, name: 'Sim' },
        { value: false, name: 'Não' }
    ]

    logicalOperators = [
        { value: '==', name: 'Igual a' },
        { value: '!=', name: 'Diferente de' },
        { value: '>', name: 'Maior que' },
        { value: '<', name: 'Menor que' },
        { value: '>=', name: 'Maior ou igual a' },
        { value: '<=', name: 'Menor ou igual a' },
    ]

    processingAvgTypes: any = [
        {value: 'gradual', name: 'Gradual'},
        {value: 'notGradual', name: 'Não Gradual (padrão)'}
    ]
    junctionOperators = [
        { value: '&&' },
        { value: '||' }
    ]

    logicIdOptions: any = []

    //#endregion

    constructor(
        public dataService: DataserviceService,
        private sharedService: SharedService,
        private httpRequests: HandleHttpService,
        private route: Router,
        private authService: AuthService,

        private fb: FormBuilder
    ) { }

    //#region Criador de Lógicas do LOBOT 

    createOperationForm() {
        this.operationsCount++;
        return this.fb.group({
            sensor1: '',
            operator: '',
            sensor2: '',
            junctionOperator: '&&',
        });
    }

    addOperation() {
        this.operations = this.logicForm.get('operations') as FormArray;
        this.operations.push(this.createOperationForm());

        console.log(this.logicForm.get('operations')['controls'].length)
        console.log(this.operationsCount)
    }

    removeOperation() {
        this.operationsCount--;
        this.operations.removeAt(this.operationsCount)
    }

    checkResults() {

        this.finalString = '';
        let ctrlArray = this.operations.value;

        this.finalString += `(`

        for (let i = 0; i < ctrlArray.length; i++) {
            this.finalString += `(s${ctrlArray[i].sensor1} ${ctrlArray[i].operator} s${ctrlArray[i].sensor2})`;

            if (this.operationsCount >= 1 && i != ctrlArray.length - 1) {
                this.finalString += ` ${ctrlArray[i].junctionOperator} ` 
            }

        }

        if (ctrlArray.length == this.operationsCount) {
            if (this.isTimeArgument.value)
                this.finalString += `){${this.timeArgument.value}} ? ${this.resultPositive.value} : ${this.resultNegative.value}`
            else
                this.finalString += `) ? ${this.resultPositive.value} : ${this.resultNegative.value}`
        }


    }

    //#endregion

    getEmmitedObject(receivedObject: any): void {
        this.dataService.sensor = receivedObject;
        this.sharedService.persistingData.setData(this.dataService)
    }

    ngOnInit(): void {

        this.myForm = this.fb.group({
            name: this.fb.control(''),

            publishBehavior: this.fb.control(''),

            type: this.fb.control(''),
            time: this.fb.control(''),
            threshold: this.fb.control(''),

            isEndUpdatable: this.fb.control(''),
            processingAvgType: this.fb.control(''),

            aggregateSensor: this.fb.control(''),
            plcConfig: this.fb.control(''),
        });

        this.logicForm = this.fb.group({
            operations: this.fb.array([]),
        })

        try {
            if (this.sharedService.persistingData.getData().gateway.id == undefined)
                console.log("does not exist");
            else
                this.dataService = this.sharedService.persistingData.getData();
        } catch (error) {
            this.route.navigate(['/sensor'])
        }

        this.breadCrumbs = `
        ${this.dataService.company.name} > 
        ${this.dataService.factory.name} > 
        ${this.dataService.productionLine.name} >
        ${this.dataService.productionCell.name} >
        ${this.dataService.machine.name} >
        ${this.dataService.gateway.name} >
        ${this.dataService.plc.plcName}`
        // ${this.dataService.sensor.name}`

        this.refresh();
        this.getSensorDataTypes();
        this.getSensorTypes();

        this.loadSensors();
        this.getLogicId();
    }

    editCurrentObject(editingObject: any): void {
        this.isEditing = true;
        this.editingIndex = editingObject.id;

        this.httpRequests.getRequest(`v1/lobot-configs/${editingObject.id}`).subscribe((data: Lobot) => {
            this.editForm.setValue({ name: data.name });
        });
    }

    openArgumentCreator(): void {
        this.isCreatingLogic = true;
    }

    submitEdit(value: any): void {
        let editedObject =
        {
            name: value.name,
            //todo: insert other types from Lobot Model
        };

        if (editedObject.name != undefined || editedObject.name != null) {
            this.httpRequests.updateRequest(`v1/lobot-configs/${this.editingIndex}`, editedObject).subscribe(() => {
                this.refresh();
                this.isEditing = false;
            })
        }

    }

    deleteCurrentObject(deletingObject: any): void {
        Swal.fire({
            title: 'Você tem certeza de que deseja deletar este LOBOT?',
            text: "Essa ação é irreversível!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Deletar'
        }).then((result) => {
            if (result.isConfirmed) {
                this.httpRequests.deleteRequest(`v1/lobot-configs/${deletingObject.id}`).subscribe(() => {
                    Swal.fire({
                        title: 'LOBOT deletado com sucesso!',
                        icon: 'success',
                    })
                    this.refresh();
                }, (error) => {
                    Swal.fire({
                        title: 'Erro ao deletar o LOBOT',
                        text: 'Tente novamente mais tarde.',
                        icon: 'error',
                    })
                });
            }
        })
    }

    refresh(): void {
        this.sharedService.getDataSource('lobot-configs/gateways', Lobot, this.dataSource, this.idPreview, this.dataService.gateway.id)
    }

    loadSensors(): void {
        this.httpRequests.getRequest(`v1/sensors/machines/${this.dataService.machine.id}`).subscribe((data: any[]) => {
            this.sensorsList = data;
        })
    }

    getLogicId(): void {
        this.httpRequests.getRequest(`v1/logics`).subscribe((data: any[]) => {
            this.logicIdOptions = data;
        })
    }

    getSensorDataTypes(): void {
        this.httpRequests.getRequest(`v1/sensor-data-types`).subscribe((data: any[]) => {
            this.sensorDataTypeOptions = data;
        })
    }

    getSensorTypes(): void {
        this.httpRequests.getRequest(`v1/sensor-types`).subscribe((data: any[]) => {
            this.sensorTypeOptions = data;
        })
    }

    onFileSelected(evt: any) {
        const target: DataTransfer = <DataTransfer>(evt.target);

        if (target.files.length !== 1) throw new Error('Cannot use multiple files');

        const selectedFile = evt.target.files[0];
        const fileReader: FileReader = new FileReader();
        this.fileName = selectedFile.name;

        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = (event) => {
            let binaryData = event.target.result;
            let wb = XLSX.read(binaryData, { type: 'binary' })

            wb.SheetNames.map(sheet => {
                const data = XLSX.utils.sheet_to_json<LobotXLS>(wb.Sheets[sheet])
                console.log("UploadingData:", data);
                const lobotReturn = data.map((lobotRaw) => Lobot.copyInterfaceToEntity(lobotRaw))

                lobotReturn.map((lobot) => {
                    if (lobot.aggregateSensor.id == undefined)
                        lobot.aggregateSensor = null;

                    lobot.machine.id = this.dataService.machine.id;
                    lobot.plcConfig.id = this.dataService.plc.id;
                    lobot.gateway.id = this.dataService.gateway.id;
                })

                this.uploadingData = lobotReturn;
                this.displayDataSource = new MatTableDataSource(JSON.parse(JSON.stringify(lobotReturn)));
            })
            this.checkUploadedFiles();
        }
    }

    checkUploadedFiles() {

        this.displayDataSource.data.map((lobotItem) => {
            if (lobotItem.aggregationBehaviour) {
                if (lobotItem.aggregationBehaviour.type == null)
                    lobotItem.aggregationBehaviour = "Desligado"
                else if (lobotItem.aggregationBehaviour.type == "on_change")
                    lobotItem.aggregationBehaviour = "Alteração (OnChange)"

                else if (lobotItem.aggregationBehaviour.type == "aggregate_on_change")
                    lobotItem.aggregationBehaviour = "Alteração Agregada"

                else if (lobotItem.aggregationBehaviour.type == "counter")
                    lobotItem.aggregationBehaviour = "Contador"

                else if (lobotItem.aggregationBehaviour.type == "time_counter")
                    lobotItem.aggregationBehaviour = "Contador Timer"

                else if (lobotItem.aggregationBehaviour.type == "pulse")
                    lobotItem.aggregationBehaviour = "Pulso"

                else if (lobotItem.aggregationBehaviour.type == "raw")
                    lobotItem.aggregationBehaviour = "Sem Compressão (raw)"
            }

            if (lobotItem.sensorType) {
                let lobotTypeFilter = this.sensorTypeOptions.find(optional => optional.id === lobotItem.sensorType.id)
                lobotItem.sensorType.name = lobotTypeFilter.name;
            }
        })

        this.isUploadingFile = true;
    }

    submitFileToSensor() {
        if (this.uploadingData !== null) {
            this.httpRequests.postRequest("v1/lobot-configs/list", this.uploadingData).subscribe((data) => {
                this.refresh();
                this.isUploadingFile = false;
                this.fileInput.nativeElement.value = ''
                this.fileName = 'Importar Arquivo';
            }, error => {
                console.error("Falhou a submição de arquivo:", error);
            })
        }
    }

    cancelUpload(event: any) {
        this.isUploadingFile = false;
        this.fileInput.nativeElement.value = ''
        this.fileName = 'Importar Arquivo';
    }

    returnVal(element: any, dispCol: any) {
        switch (dispCol) {
            case 'description': return element.description;

            case 'logicId': return element.logic.id;
            case 'logicArgs': return element.logicArgs;

            case 'dataCollectorOn': return element.dataCollectorOn;
            case 'sensorDataType': return element.sensorDataType.id;

            case 'aggregationType': return element.aggregationBehaviour;
        }
    }

    onSubmit(value: any): void {
        let postObject: Lobot = {
            description: value.name,

            logic: {
                id: this.logicId.value
            },

            publishBehavior: value.publishBehavior,

            isAggregateSensorEquals: this.isAggregateSensorEquals.value,

            dataCollectorOn: this.dataCollectorOn.value,

            aggregationBehaviour: {
                type: this.aggregationType.value,
            },

            sensorDataType: {
                id: this.sensorDataType.value
            },

            machine: {
                id: this.dataService.machine.id
            },

            gateway: {
                id: this.dataService.gateway.id
            },

            plcConfig: {
                id: this.dataService.plc.id
            },
        }

        if ((postObject.isAggregateSensorEquals) == false) { //verificar se o ID do sensor é agregado ao mesmo sensor
            postObject.aggregateSensor = {
                id: value.aggregateSensor
            }
        }

        if (this.logicArgs.value == "" || this.logicArgs.value == undefined) {
            postObject.logicArgs = this.finalString;
        } else {
            postObject.logicArgs = this.logicArgs.value;
        }

        if (postObject.aggregationBehaviour.type == 'on_change') {
            postObject.aggregationBehaviour = {
                type: this.aggregationType.value,
                isEndUpdatable: this.myForm.controls['isEndUpdatable'].value
            }
        } else if (postObject.aggregationBehaviour.type == 'aggregate_on_change') {
            postObject.aggregationBehaviour = {
                type: this.aggregationType.value,
                time: value.time,
                // threshold: this.myForm.controls['threshold'].value,
                processingAvgType: this.myForm.controls['processingAvgType'].value
            };
        } else if (postObject.aggregationBehaviour.type == 'counter') {
            postObject.aggregationBehaviour = {
                type: this.aggregationType.value,
                isEndUpdatable: this.myForm.controls['isEndUpdatable'].value
            };
        }

        console.log(postObject);
        

        if (postObject.description != undefined) {
            this.httpRequests.postRequest("v1/lobot-configs", postObject).subscribe((data) => {
                this.refresh();
            })
            this.myForm.reset();
        }

    }

    logout() {
        this.authService.logout();
    }
}
