import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Import all Modules from Material
import { MaterialModule } from './material/material.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    DynamicTableComponent,
    getPortuguesePaginatorIntl,
    OrdinalPipe,
} from './components/dynamic-table/dynamic-table.component';
import { NavComponent } from './components/nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';

import { CadastroRapidoComponent } from './views/cadastro-rapido/cadastro-rapido.component';
import { CompanyComponent } from './views/company/company.component';
import { FactoryComponent } from './views/factory/factory.component';
import { ProductionLineComponent } from './views/production-line/production-line.component';
import { ProductionCellComponent } from './views/production-cell/production-cell.component';
import { MachinesComponent } from './views/machines/machines.component';
import { GatewayComponent } from './views/gateway/gateway.component';
import { PlcConfigComponent } from './views/plc-config/plc-config.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HandleHttpService } from './services/handle-http.service';
import { SensorComponent } from './views/sensor/sensor.component';
import { LobotComponent } from './views/lobot/lobot.component';
import { LoginComponent } from './views/login/login.component';
import { JwtInterceptor } from './helper/jwt.interceptor';

import { BrMaskerModule } from 'br-mask';
import { ShiftComponent } from './views/shift/shift.component';
import { NetworkConfigComponent } from './views/network-config/network-config.component';
import { ProductComponent } from './views/product/product.component';
import { MatInputModule } from '@angular/material/input';
import { SignalComponent } from './views/signal/signal.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DiscardMonitoringComponent } from './views/discard-monitoring/discard-monitoring.component';
import { ProcessingComponent } from './views/processing/processing.component';
import { DiscardReasonsComponent } from './views/discard-reasons/discard-reasons.component';
import { StopMonitoringComponent } from './views/stop-monitoring/stop-monitoring.component';
import { PlcComponent } from './views/plc/plc.component';
import { IpAddressPipe } from './pipes/ip-address.pipe';
import { MeasurementsComponent } from './views/measurements/measurements.component';
import { StopClassComponent } from './views/stop-class/stop-class.component';
import { StopReasonsComponent } from './views/stop-reasons/stop-reasons.component';
import { ProductionMonitoringComponent } from './views/production-monitoring/production-monitoring.component';
import { MatSelectModule } from '@angular/material/select';
import { ProductionOrderComponent } from './views/production-order/production-order.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OperatorsComponent } from './views/operators/operators.component';
import { DiscardClassComponent } from './views/discard-class/discard-class.component';

@NgModule({
    declarations: [
        AppComponent,
        NavComponent,
        OrdinalPipe,
        CadastroRapidoComponent,
        CompanyComponent,
        FactoryComponent,
        ProductionLineComponent,
        DynamicTableComponent,
        ProductionCellComponent,
        MachinesComponent,
        GatewayComponent,
        PlcConfigComponent,
        SensorComponent,
        LobotComponent,
        LoginComponent,
        ShiftComponent,
        NetworkConfigComponent,
        ProductComponent,
        SignalComponent,
        DiscardMonitoringComponent,
        ProcessingComponent,
        DiscardReasonsComponent,
        StopMonitoringComponent,
        PlcComponent,
        IpAddressPipe,
        MeasurementsComponent,
        StopClassComponent,
        StopReasonsComponent,
        ProductionMonitoringComponent,
        ProductionOrderComponent,
        OperatorsComponent,
        DiscardClassComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        LayoutModule,
        MatToolbarModule,
        MatTooltipModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrMaskerModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatSlideToggleModule,
        MatCheckboxModule,
    ],
    exports:[
        IpAddressPipe
    ],
    providers: [
        { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl() },
        // { provide: HTTP_INTERCEPTORS, useClass: HandleHttpService, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
