<div id="main">
    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Produto</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" mat-mini-fab
                aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'5'"></app-nav>
        </nav>

        <div class="flex-display">

            <app-dynamic-table class="table-component" [isStart]="false" [dataSource]="dataSource"
                [displayedColumns]="displayedColumns" [tableHeaderNames]="tableHeaderNames"
                (currentEditingObject)="editCurrentObject($event)"
                (currentDeletingObject)="deleteCurrentObject($event)"></app-dynamic-table>

            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmitProduct(myForm.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar Produto
                        </div>
                        <div class="form-id">
                            ID <span># <strong>{{ idPreview.id || '?' }}</strong></span>
                        </div>
                    </header>

                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do Produto</mat-label>
                            <input matInput placeholder="Nome do Produto" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <div class="input-values" *ngFor="let machine of machines">
                            <div class="machine-name">{{machine.name}}</div>
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Produção Teórica (un/h)</mat-label>
                                <input matInput type="number" placeholder="Prod Teórica"
                                    formControlName="{{'prod'+machine.id}}" autocomplete="off">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Unidade por ciclo</mat-label>
                                <input matInput type="number" placeholder="Unidade por ciclo"
                                    formControlName="{{'units'+machine.id}}" autocomplete="off">
                            </mat-form-field>
                            <!-- <input matInput type="hidden" formControlName="{{'mid'+machine.id}}"> -->
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Tempo para parada</mat-label>
                                <input matInput type="number" placeholder="Tempo para parada"
                                    formControlName="{{'stopTime'+machine.id}}" autocomplete="off">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Tempo de setup (min)</mat-label>
                                <input matInput type="number" placeholder="Tempo de setup"
                                    formControlName="{{'setupTime'+machine.id}}" autocomplete="off">
                            </mat-form-field>
                            <!-- <input type="hidden" formControlName="{{'mid'+machine.id}}"> -->
                        </div>
                    </div>

                    <mat-error class="error" *ngIf="myForm.get('name').hasError('required') && myForm.touched">O nome do
                        produto deve ser preenchido.</mat-error>
                    <mat-error class="error" *ngIf="myForm.invalid && !(myForm.get('name').hasError('required'))">Os
                        valores dos campos não podem
                        ser negativos.</mat-error>
                    <button class="btn-default btn-filled btn-add" type="submit"
                        [disabled]="myForm.invalid || myForm.untouched" color="primary">Adicionar Produto</button>
                    <div class="btn-wrapper">
                        <button class="btn-default nextPageBtn" (click)="onSubmit()" type="button"
                            [disabled]="products.length <= 0">Próxima Página</button>
                    </div>

                </form>
            </div>

        </div>

    </div>

    <!-- modal for editing and others -->
    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>

                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(editForm.value)">
                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do Produto</mat-label>
                            <input matInput placeholder="Nome do Produto" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <div class="input-values" *ngFor="let machine of machines">
                            <div class="machine-name">{{machine.name}}</div>
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Produção Teórica (un/h)</mat-label>
                                <input matInput type="number" placeholder="Prod Teórica"
                                    formControlName="{{'prod'+machine.id}}" autocomplete="off">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Unidade por ciclo</mat-label>
                                <input matInput type="number" placeholder="Unidade por ciclo"
                                    formControlName="{{'units'+machine.id}}" autocomplete="off">
                            </mat-form-field>
                            <!-- <input matInput type="hidden" formControlName="{{'mid'+machine.id}}"> -->
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Tempo para parada</mat-label>
                                <input matInput type="number" placeholder="Tempo para parada"
                                    formControlName="{{'stopTime'+machine.id}}" autocomplete="off">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Tempo de setup (min)</mat-label>
                                <input matInput type="number" placeholder="Tempo de setup"
                                    formControlName="{{'setupTime'+machine.id}}" autocomplete="off">
                            </mat-form-field>
                            <!-- <input type="hidden" formControlName="{{'mid'+machine.id}}"> -->
                        </div>
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid"
                            color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false"
                            color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>

</div>
