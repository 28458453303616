import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

let DATASOURCE_TITLE = [];

@Component({
    selector: 'app-dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent implements OnInit {

    constructor(private _snackBar: MatSnackBar) { }

    @Input() dataSource: MatTableDataSource<any>;
    @Input() tableHeaderNames: Array<string>;
    @Input() displayedColumns: string[];
    @Input() isStart: boolean = false

    @Output() idToEmit = new EventEmitter<number>();
    emmitIdFrom(emittingId: number): void { this.idToEmit.emit(emittingId); }

    @Output() emmitedObject = new EventEmitter<object>();
    emmitObject(emmitingObj: object): void { this.emmitedObject.emit(emmitingObj) }

    @Output() currentEditingObject = new EventEmitter<object>();
    editObject(editingObject: object): void { this.currentEditingObject.emit(editingObject) }

    @Output() currentDeletingObject = new EventEmitter<object>();
    deleteObject(deletingObject: object): void { this.currentDeletingObject.emit(deletingObject) }

    @Output() onRefresh: EventEmitter<any> = new EventEmitter()
    refreshWasClicked(): void { this.onRefresh.next(); }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    ngOnInit(): void {
        this.displayedColumns.push('actions');
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    ngOnChange() {
        DATASOURCE_TITLE = this.dataSource.data.map(({ createdAt, ...rest }) => ({ ...rest }));

        this.dataSource.filterPredicate = function (data, filter: string): boolean {
            var _data = DATASOURCE_TITLE.filter(element => element.id === data.id);

            return data.id.toString().toLowerCase().includes(filter) || data.id.toString().toLowerCase() === filter ||
                _data[0].name.toString().toLowerCase().includes(filter.toLowerCase());
        };
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['warning']
        });
    }

    printId(id: number) {
        console.log("Item id:", id);
    }

}

import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';

export function getPortuguesePaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Itens por página';
    paginatorIntl.nextPageLabel = 'Próxima Página';
    paginatorIntl.previousPageLabel = 'Página Anterior';

    return paginatorIntl;
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'FilterData'
})
export class OrdinalPipe implements PipeTransform {
    transform(value: number): string {
        var data = DATASOURCE_TITLE.filter(
            element => element.id === value);
        return data[0].name;
    }
}