<div id="main">

    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Lobot</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" 
                    mat-mini-fab aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'8'"></app-nav>
        </nav>
        
        <span class="text-breadcrumbs">{{ breadCrumbs }}</span>

        <div class="flex-display">

            <app-dynamic-table class="table-component"
                [dataSource]="dataSource"
                [displayedColumns]="displayedColumns"
                [tableHeaderNames]="tableHeaderNames" 
                (onRefresh)="refresh()"
                (emmitedObject)="getEmmitedObject($event)"
                (currentDeletingObject)="deleteCurrentObject($event)"
                (currentEditingObject)="editCurrentObject($event)">
            </app-dynamic-table>

            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmit(userForm.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar LOBOT
                        </div>

                        <div class="pb-wrapper">
                            <div class="form-id">
                                ID <span># <strong> {{ idPreview.id || '?'}} </strong></span>
                            </div>

                        </div>
                    </header>

                    <div class="input-wrapper overflow-wrapper">

                        <div class="tooltip-wrapper">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">{{ fileName || 'Importar arquivo' }}</mat-label>
                                <input #fileInput matInput disabled autocomplete="off">
                            </mat-form-field>
                            <div class="tip-holder">

                                <input class="file-input" type="file" autocomplete="off"
                                    (change)="onFileSelected($event)" #fileUpload>

                                <div class="btn-wrapper">
                                    <button class=" publish-btn btn-filled btn-default" type="button"
                                        (click)="fileUpload.click()"
                                        matTooltip="Importar sensores a partir de um arquivo">
                                        <mat-icon>publish</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="separator">ou crie um novo</div>


                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome/Descrição LOBOT</mat-label>
                            <input matInput placeholder="Nome / Descrição LOBOT" formControlName="name"
                                autocomplete="off" required>
                        </mat-form-field>

                        <div class="tooltip-wrapper">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">ID Lógico</mat-label>
                                <mat-select [formControl]="logicId">
                                    <mat-option *ngFor="let option of logicIdOptions" [value]="option.id">
                                        {{ option.id }} | {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="tip-holder">
                                <span matTooltip="Sim - Os dados são coletados e salvos no banco de dados
                                    Não - Os dados são coletados porém não serão salvos no banco de dados"
                                    [matTooltipClass]="'my-tooltip'">?</span>
                            </div>
                        </div>

                        <div class="tooltip-wrapper">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Tipo dos Dados</mat-label>
                                <mat-select [formControl]="sensorDataType">
                                    <mat-option *ngFor="let option of sensorDataTypeOptions" [value]="option.id">
                                        {{ option.databaseType }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="tip-holder">
                                <span matTooltip="Tipo que será utilizado dentro do banco de dados / CLP"
                                    [matTooltipClass]="'my-tooltip'">?</span>
                            </div>
                        </div>

                        <div class="tooltip-wrapper">
                            <div class="slider-wrapper">
                                <mat-slide-toggle [formControl]="isLogicArgPredefined"></mat-slide-toggle>
                                <mat-label class="label">Utilizar argumento lógico pré-definido</mat-label>
                            </div>
                            <div class="tip-holder">
                                <span matTooltip="Explicação para o input">?</span>
                            </div>
                        </div>

                        <div class="tooltip-wrapper" *ngIf="isLogicArgPredefined.value == true">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Argumento Lógico (?)</mat-label>
                                <textarea matInput placeholder="Argumento Lógico" autocomplete="off"
                                [formControl]="logicArgs"></textarea>
                            </mat-form-field>
                            <div class="tip-holder">
                                <span matTooltip="Explicação para o input">?</span>
                            </div>
                        </div>

                        <div class="mb-s" *ngIf="isLogicArgPredefined.value == false">
                            <div class="btn-wrapper" *ngIf="finalString == ''">
                                <button class="btn-default btn-filled" type="button" (click)="isCreatingLogic = true" color="primary">Criar Argumento</button>
                            </div>
                            
                            <div class="tooltip-wrapper" *ngIf="finalString != ''">
                                <mat-form-field appearance="outline" >
                                    <mat-label class="label">Argumento Lógico</mat-label>
                                    <textarea matInput disabled autocomplete="off">{{ finalString }}</textarea>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Resultado que será utilizado como parâmetro do Argumento Lógico do LOBOT"
                                        [matTooltipClass]="'my-tooltip'">?</span>
                                </div>
                            </div>

                            <div class="btn-wrapper" *ngIf="finalString != ''">
                                <button class="btn-default btn-filled" type="button" (click)="isCreatingLogic = true" color="primary">Modificar Argumento</button>
                            </div>
                        </div>


                        <div class="tooltip-wrapper">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Deseja salvar os dados?</mat-label>
                                <mat-select [formControl]="dataCollectorOn">
                                    <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="tip-holder">
                                <span matTooltip="Sim - Os dados são coletados e salvos no banco de dados
                                    Não - Os dados são coletados porém não serão salvos no banco de dados"
                                    [matTooltipClass]="'my-tooltip'">?</span>
                            </div>
                        </div>

                        <div class="tooltip-wrapper" *ngIf="dataCollectorOn.value == true">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Tipo de Compressão de Dados</mat-label>

                                <mat-select [formControl]="aggregationType">
                                    <mat-option *ngFor="let option of aggregationOptions" [value]="option.value" >
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            
                            <div class="tip-holder">
                                <span matTooltip="Alteração - Envia o dado apenas quando muda. Recomendado quando não se sabe o comportamento do Sensor.
                                    Alteração Agregada - Realiza uma agregação em uma janela de tempo, entretanto só salvará esta janela houver alguma mudança em comparação à janela anterior.
                                    Contador - Transforma contadores em números simples, facilitando operações de agregação no banco de dados.
                                    Contador Timer - Usado sobre sensores que enviam contadores de tempo. Um contador é inicializado até ser reiniciado.
                                    Pulso - Utilizado para sensores que enviam dados binários (0 - 1).
                                    Raw - Os dados são salvos sem nenhum tipo de processamento ou agregação."
                                [matTooltipClass]="'my-tooltip'">?</span>                            
                            </div>
                        </div>

                        <div class="tooltip-wrapper" *ngIf="aggregationType.value == 'counter'">
                            <ng-container *ngIf="aggregationType.value == 'counter'">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">is End Updatable</mat-label>
                                    <mat-select formControlName="isEndUpdatable">
                                        <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </ng-container>
                            <div class="tip-holder">
                                <span matTooltip="O end vai sendo atualizado a cada mensagem que chega, até mesmo quando o valor se repete.">?</span>
                            </div>
                        </div>

                        <ng-container *ngIf="aggregationType.value == 'aggregate_on_change'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Tempo</mat-label>
                                <input matInput placeholder="Tempo" autocomplete="off" formControlName="time">
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="aggregationType.value == 'aggregate_on_change'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Limiar</mat-label>
                                <input matInput placeholder="threshold" autocomplete="off"
                                    formControlName="threshold">
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="aggregationType.value == 'aggregate_on_change'">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Processing Average Type</mat-label>
                                <mat-select formControlName="processingAvgType">
                                    <mat-option *ngFor="let option of processingAvgTypes" [value]="option.value">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>

                        <div class="tooltip-wrapper" *ngIf="aggregationType.value == 'on_change'">
                            <ng-container *ngIf="aggregationType.value == 'on_change'">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">is End Updatable</mat-label>
                                    <mat-select formControlName="isEndUpdatable">
                                        <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </ng-container>
                            <div class="tip-holder">
                                <span matTooltip="O end vai sendo atualizado a cada mensagem que chega, até mesmo quando o valor se repete.">?</span>
                            </div>
                        </div>

                        <div class="tooltip-wrapper">
                            <div class="slider-wrapper">
                                <mat-slide-toggle [formControl]="isAggregateSensorEquals"></mat-slide-toggle>
                                <mat-label class="label">Sensor Agregado é o mesmo Sensor Atual</mat-label>
                            </div>
                            <div class="tip-holder">
                                <span matTooltip="Explicação para o input">?</span>
                            </div>
                        </div>

                        <div class="tooltip-wrapper" *ngIf="isAggregateSensorEquals.value == false">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Específicar ID do Sensor Agregado (?)</mat-label>
                                <input matInput placeholder="Nome da Linha" autocomplete="off" type="number"
                                    formControlName="aggregateSensor">
                            </mat-form-field>
                            <div class="tip-holder">
                                <span matTooltip="Explicação para o input">?</span>
                            </div>
                        </div>

                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" color="primary">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">
            
            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>
    
                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(userForm.value)"> 
                    <div class="input-wrapper">
                        <div class="overflow-wrapper">
                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                <mat-label class="label">Nome do Sensor</mat-label>
                                    <input matInput formControlName="name" autocomplete="off" required>
                                </mat-form-field>
                            </div>                        
                        </div>
                    </div>
                    
                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid" color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false" color="primary">Cancelar</button>
                    </div>
                </form>

            </div>

        </div>
    </div>

    <div class="edit-modal" *ngIf="isCreatingLogic == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <h1 class="flex-center" *ngIf="logicForm.get('operations')['controls'].length <= 0">
                    Adicione um argumento para começar
                </h1>
    
                <div class="operations-wrapper">
                    <form [formGroup]="logicForm" >
                        <div class="operations-controls-wrapper">
                            <div formArrayName="operations" class="controls-junction-wrapper"
                                *ngFor="let item of logicForm.get('operations')['controls']; let i = index">

                                <div class="controls-wrapper">
                                    <h3>Argumento {{ i + 1 }}</h3>
                
                                    <div class="logicArg-wrapper" [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label class="label">Primeiro Sensor</mat-label>
                                            <mat-select formControlName="sensor1">
                                                <mat-option *ngFor="let option of sensorsList" [value]="option.id">
                                                    {{ option.id }} | {{ option.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                
                                    <div class="logicArg-wrapper" [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label class="label">Operador</mat-label>
                                            <mat-select formControlName="operator">
                                                <mat-option *ngFor="let option of logicalOperators" [value]="option.value">
                                                    {{ option.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                
                                    <div class="logicArg-wrapper" [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label class="label">Segundo Sensor</mat-label>
                                            <mat-select formControlName="sensor2">
                                                <mat-option *ngFor="let option of sensorsList" [value]="option.id">
                                                    {{ option.id }} | {{ option.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>            
                                </div>

                                <div class="junction-wrapper" *ngIf="logicForm.get('operations')['controls'].length > 1 && i < (operationsCount - 1)">
                                    <div class="logicArg-wrapper" [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label class="label"></mat-label>
                                            <mat-select formControlName="junctionOperator">
                                                <mat-option *ngFor="let option of junctionOperators" [value]="option.value">
                                                    {{ option.value }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="btns-operations" *ngIf="logicForm.get('operations')['controls'].length >= 1">
                            <div class="checker-wrapper">
                                <div class="logicArg-wrapper">
                                    <p><mat-checkbox [formControl]="isTimeArgument">Argumento de Tempo?</mat-checkbox></p>
                                </div>
    
                                <div class="logicArg-wrapper">
                                    <mat-form-field appearance="outline">
                                        <mat-label class="label">Argumento de Tempo</mat-label>
                                        <input matInput placeholder="Argumento" type="number" autocomplete="off" [formControl]="timeArgument">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="logicArg-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Caso Positivo</mat-label>
                                    <input matInput placeholder="Argumento" autocomplete="off" [formControl]="resultPositive">
                                </mat-form-field>
                            </div>
        
                            <div class="logicArg-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Caso Negativo</mat-label>
                                    <input matInput placeholder="Argumento" autocomplete="off" [formControl]="resultNegative">
                                </mat-form-field>
                            </div>
                        </div>
    
                        <div class="btns-operations">
     
                            <div class="btn-wrapper">
                                <div class="btn-operator btn-default btn-filled" type="button" (click)="addOperation()">
                                    <mat-icon>add</mat-icon>
                                    <span>Adicionar Argumento</span>
                                </div>
                            </div> 
    
                            <div class="btn-wrapper">
                                <div class="btn-operator btn-default btn-filled" type="button" (click)="removeOperation()">
                                    <mat-icon>remove</mat-icon>
                                    <span>Remover Argumento</span>
                                </div>
                            </div> 
    
                            <div class="btn-wrapper">
                                <div class="btn-operator btn-default btn-filled" type="button" (click)="checkResults()">
                                    <mat-icon>refresh</mat-icon>
                                    <span>Atualizar Argumento</span>
                                </div>
                            </div> 
                            
                        </div>
                    </form>
        
                    

                    <div>
                        <div><h3>Argumento final</h3></div>
                        <div class="tooltip-wrapper">
                            <mat-form-field appearance="outline" >
                                <mat-label  class="label">{{ finalString }}</mat-label>
                                <input matInput disabled autocomplete="off">
                            </mat-form-field>
                            <div class="tip-holder">
                                <span matTooltip="Resultado que será utilizado como parâmetro do Argumento Lógico do LOBOT"
                                    [matTooltipClass]="'my-tooltip'">?</span>
                            </div>
                        </div>
                    </div>
    
    
                </div>
                
                <div class="btn-wrapper">
                    <button class="btn-default btn-filled btn-fullsize" (click)="isCreatingLogic = false" color="primary">Confirmar</button>
                </div>
            </div>


        </div>
    </div>


    <div class="edit-modal" *ngIf="isUploadingFile == true">
        <div class="modal-wrapper">
            <div class="table-wrapper">
                <div class="table-wrapper-overflow">
                    <table mat-table matSort [dataSource]="displayDataSource" 
                        (tableHeaderNames)="importHeaderNames" (displayedColumns)="importColumns">
                        <ng-container *ngFor='let importCol of importColumns; let i = index'>
                            <ng-container matColumnDef="{{ importCol }}">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ importHeaderNames[i] }}</th>
                                <td mat-cell *matCellDef="let element"> {{ returnVal(element, importCol) }} </td>
                            </ng-container>
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="importColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: importColumns;"></tr>
        
                    </table>
                </div>
                <div class="btn-wrapper btn-wrapper-table">
                    <button class="btn-default btn-filled" (click)="submitFileToSensor()">Confirmar</button>
                    <button class="btn-default btn-outlined" (click)="cancelUpload($event)">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</div>