<div id="main">
    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Sinais Físicos</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" mat-mini-fab
                aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'10'"></app-nav>
        </nav>
        <mat-card class="content-card">
            <button mat-button class="refresh-btn btn-filled" (click)="refreshValues()"><mat-icon>refresh</mat-icon>
                Atualizar todos os valores</button>
            <div class="table-wrapper">

                <mat-card class="digital-signal-table">
                    <p class="table-title">Sinais Digitais</p>
                    <table mat-table matSort [dataSource]="dataSourceDigital" #sortD="matSort"
                        (displayedColumns)="displayedColumnsDigital">
                        <ng-container matColumnDef="active">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="status-indicator">
                                    <div [ngClass]="element['active'] == true ? 'show-active' : 'show-inactive'"
                                        [matTooltip]="element['active'] == true ? 'Ativada' : 'Desativada'">
                                    </div>
                                    <p>{{element['active'] == true ? 'Ativada' : 'Desativada'}}</p>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="active-btn">
                            <th mat-header-cell *matHeaderCellDef>Ativar/Desativar entrada</th>
                            <td mat-cell *matCellDef="let element" class="active-display">
                                <mat-slide-toggle color="primary" [checked]="element['active']"
                                    [matTooltip]="element['active'] == true ? 'Clique para desativar' : 'Clique para ativar'"
                                    (change)="toggleActive(element.channel,'digital')">
                                </mat-slide-toggle>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="channel">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Canal </th>
                            <td mat-cell *matCellDef="let element" class="channel-name"> {{element.channel}} </td>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="{'deactive': element['active'] == false }">
                                {{element.value}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsDigital"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsDigital;"></tr>
                    </table>
                </mat-card>

                <mat-card class="analog-signal-table">
                    <p class="table-title">Sinais Analógicos</p>
                    <table mat-table matSort [dataSource]="dataSourceAnalog" (displayedColumns)="displayedColumnsAnalog"
                        #sortA="matSort">


                        <ng-container matColumnDef="active">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="status-indicator">
                                    <div [ngClass]="element['active'] == true ? 'show-active' : 'show-inactive'"
                                        [matTooltip]="element['active'] == true ? 'Ativada' : 'Desativada'">
                                    </div>
                                    <p>{{element['active'] == true ? 'Ativada' : 'Desativada'}}</p>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="active-btn">
                            <th mat-header-cell *matHeaderCellDef>Ativar/Desativar entrada</th>
                            <td mat-cell *matCellDef="let element" class="active-display">
                                <mat-slide-toggle color="primary" [checked]="element['active']"
                                    [matTooltip]="element['active'] == true ? 'Clique para desativar' : 'Clique para ativar'"
                                    (change)="toggleActive(element.channel,'analog')">
                                </mat-slide-toggle>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="channel">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Canal </th>
                            <td mat-cell *matCellDef="let element" class="channel-name"> {{element.channel}} </td>
                        </ng-container>
                        <ng-container matColumnDef="range">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Sinal </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="{'deactive': element['active'] == false }">
                                {{element.range}} </td>
                        </ng-container>
                        <ng-container matColumnDef="inflimit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Limite inferior </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="{'deactive': element['active'] == false }">
                                {{element.inflimit}} </td>
                        </ng-container>
                        <ng-container matColumnDef="suplimit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Limite superior </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="{'deactive': element['active'] == false }">
                                {{element.suplimit}} </td>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="{'deactive': element['active'] == false }">
                                {{element.value}} </td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef>Editar campos</th>
                            <td mat-cell *matCellDef="let element">
                                <button (click)="editCurrentObject(element['channel'])" mat-icon-button class="btn-edit"
                                    matTooltip="Editar sinal, limite inferior e superior"
                                    [disabled]="element['active'] == false">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsAnalog"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsAnalog;"></tr>
                    </table>
                </mat-card>

            </div>
            <div class="save-btn">
                <button class="btn-default nextPageBtn" (click)="onSubmit()">Próxima Página</button>
            </div>
        </mat-card>

    </div>

    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar Canal {{channelSelected}}
                    </div>
                </header>

                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(editForm.value)">
                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Sinal</mat-label>
                            <mat-select placeholder="Sinal" #productionLineId formControlName="range" autocomplete="off"
                                required>
                                <mat-option *ngFor="let range of rangeOptions" [value]="range">{{range}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Limite Inferior</mat-label>
                            <input matInput type="number" placeholder="Limite Inferior" formControlName="inflimit"
                                autocomplete="off" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Limite Superior</mat-label>
                            <input matInput type="number" placeholder="Limite Superior" formControlName="suplimit"
                                autocomplete="off" required>
                        </mat-form-field>
                    </div>

                    <mat-error
                        *ngIf="editForm.get('inflimit').hasError('min') || editForm.get('suplimit').hasError('min')">O
                        valor não pode ser menor que 0</mat-error>
                    <mat-error
                        *ngIf="editForm.get('suplimit').hasError('max') || editForm.get('inflimit').hasError('max')">O
                        valor não pode ser maior que 100</mat-error>
                    <mat-error *ngIf="editForm.get('inflimit').hasError('moreThanSup')">O valor do limite inferior não
                        pode ser maior que o limite superior</mat-error>
                    <mat-error *ngIf="editForm.get('suplimit').hasError('lessThanInf')">O valor do limite superior não
                        pode ser menor que o limite inferior</mat-error>
                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid"
                            color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false"
                            color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>

</div>