<div id="main">
    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | PLC</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" mat-mini-fab
                aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'11'"></app-nav>
        </nav>

        <div class="flex-display">

            <div class="card-wrapper">
                <div class="empty" *ngIf="plcs.length == 0">Não existem PLCs cadastrados</div>
                <div class="plc-card" *ngFor="let plc of plcs, let i=index">
                    <div class="title-edit-delete">
                        <p class="label">PLC: {{plc.name}}</p>
                        <div class="edit-delete">
                            <button mat-button class="btn-edit" matTooltip="Editar dados do PLC"
                                (click)="editCurrentObject(plc.id,'plc')">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-button class="btn-edit" matTooltip="Excluir PLC"
                                (click)="deleteCurrentObject(plc, 'plc')">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="info">
                        <p><span class="label"> Tipo: </span>{{types[plc.typeId].name}}</p>
                        <p><span class="label"> Endereço IP: </span>{{plc.address | ipAddress}}</p>
                        <div class="rack-slot-wrapper">
                            <p *ngIf="plc.rack!=null && plc.rack!=''"><span class="label"> Rack: </span>{{plc.rack}}
                            </p>
                            <p *ngIf="plc.slot!=null && plc.slot!=''"><span class="label"> Slot: </span>{{plc.slot}}
                            </p>
                            <p *ngIf="plc.topic!=null && plc.topic!=''"><span class="label"> Tópico:
                                </span>{{plc.topic}} </p>
                        </div>
                        <div class="test">
                            <span class="label">Status do PLC:</span>
                            <div class="value-indicator" [ngClass]="plc.value == 0 ? 'fail' : 'success'"
                                [matTooltip]="plc.value == 0 ? 'Falha' : 'Sucesso'"></div>
                            <button mat-raised-button (click)="plcs[i].value=1">Testar</button>
                        </div>
                    </div>
                    <hr>
                    <div class="sensors-wrapper">
                        <div class="title-btn-wrapper">
                            <div class="label">Sensores:</div>
                            <button mat-button class="btn-filled" (click)="addSensor(plc.id)">
                                <mat-icon>add</mat-icon>
                                Sensor
                            </button>
                        </div>

                        <table mat-table #sensorTable class="sensor">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> Nome </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>
                            <ng-container matColumnDef="machine">
                                <th mat-header-cell *matHeaderCellDef> Máquina </th>
                                <td mat-cell *matCellDef="let element"> {{element.machine}} </td>
                            </ng-container>
                            <ng-container matColumnDef="address">
                                <th mat-header-cell *matHeaderCellDef> Endereço </th>
                                <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                            </ng-container>
                            <ng-container matColumnDef="ihm">
                                <th mat-header-cell *matHeaderCellDef> IHM </th>
                                <td mat-cell *matCellDef="let element"> {{element.ihm}} </td>
                            </ng-container>
                            <ng-container matColumnDef="edit">
                                <th mat-header-cell *matHeaderCellDef>Ações</th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-button class="btn-edit" matTooltip="Editar Sensor"
                                        (click)="editCurrentObject(element.id,'sensor',plc.id)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-button class="btn-edit" matTooltip="Excluir Sensor"
                                        (click)="deleteCurrentObject(element, 'sensor')">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="nodata" class="hidden">
                                <td mat-footer-row *matFooterCellDef [colSpan]="displayedColumns.length"
                                    style="text-align: center;">Não existem sensores cadastrados no PLC</td>
                            </ng-container>
                            <tr mat-footer-row *matFooterRowDef="['nodata']" class="noData"></tr>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator #sensorPaginator class="paginator" [pageSizeOptions]="[2, 5, 10]"
                            pageSize="2"></mat-paginator>
                    </div>
                </div>
            </div>

            <div class="form-wrapper">
                <form #plcFormc="ngForm" [formGroup]="plcForm" (ngSubmit)="onSubmitPlc(plcForm.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar PLC
                        </div>
                        <div class="form-id">
                            ID <span># <strong>{{ idPreview.id || '?' }}</strong></span>
                        </div>
                    </header>

                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do PLC</mat-label>
                            <input matInput placeholder="Nome do PLC" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Tipo</mat-label>
                            <mat-select placeholder="Tipo" formControlName="type" autocomplete="off" required>
                                <mat-option *ngFor="let type of types" [value]="type.id">{{type.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Endereço IP</mat-label>
                            <input matInput type="text" placeholder="Endereço IP" formControlName="address"
                                autocomplete="off"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"
                                minlength="8" maxlength="8" required>
                            <mat-error *ngIf="plcForm.controls['address'].hasError">
                                O endereço IP deve conter 8 números
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [ngClass]="{'hidden': plcForm.controls['type'].value!=0}">
                            <mat-label class="label">Rack</mat-label>
                            <input matInput type="number" placeholder="Rack" formControlName="rack" autocomplete="off"
                                [required]="plcForm.controls['type'].value==0">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [ngClass]="{'hidden': plcForm.controls['type'].value==2}">
                            <mat-label class="label">Slot</mat-label>
                            <input matInput type="number" placeholder="Slot" formControlName="slot" autocomplete="off"
                                [required]="plcForm.controls['type'].value!=2">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [ngClass]="{'hidden': plcForm.controls['type'].value!=2}">
                            <mat-label class="label">Tópico</mat-label>
                            <input matInput type="number" placeholder="Tópico" formControlName="topic"
                                autocomplete="off" [readonly]="plcForm.controls['type'].value!=2"
                                [required]="plcForm.controls['type'].value==2">
                        </mat-form-field>
                    </div>

                    <button class="btn-default btn-filled btn-add" type="submit"
                        [disabled]="plcForm.invalid || plcForm.untouched" color="primary">Adicionar
                        PLC</button>

                    <div class="btn-wrapper">
                        <button class="btn-default nextPageBtn" (click)="onSubmit()" type="button"
                            [disabled]="plcs.length <= 0">Próxima Página</button>
                    </div>

                </form>
            </div>

        </div>

    </div>

    <!-- sensor modal -->
    <div class="edit-modal" *ngIf="addingSensor == true || editingSensor">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        {{addingSensor?'Adicionar':'Editar'}}
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>

                <form #sensorsForm="ngForm" [formGroup]="sensorForm"
                    (ngSubmit)="addingSensor? onSubmitSensor(sensorForm.value): submitEdit(sensorForm.value,'sensor')">

                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do Sensor</mat-label>
                            <input matInput placeholder="Nome do Sensor" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Máquina</mat-label>
                            <mat-select placeholder="Máquina" formControlName="machine" autocomplete="off" required>
                                <mat-option *ngFor="let machine of machines" [value]="machine.id">{{machine.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Endereço</mat-label>
                            <input matInput placeholder="Endereço" formControlName="address" autocomplete="off"
                            required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">IHM</mat-label>
                            <input matInput placeholder="IHM" formControlName="ihm" autocomplete="off"
                                required>
                        </mat-form-field>
                        <input type="hidden" formControlName="plcId">
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="sensorForm.invalid"
                            color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="addingSensor = false; editingSensor = false"
                            color="primary">Cancelar</button>
                    </div>

                </form>
            </div>

        </div>
    </div>

    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>

                <form #plcFormc="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(editForm.value,'plc')">
                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do PLC</mat-label>
                            <input matInput placeholder="Nome do PLC" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Tipo</mat-label>
                            <mat-select placeholder="Tipo" formControlName="type" autocomplete="off" required>
                                <mat-option *ngFor="let type of types" [value]="type.id">{{type.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Endereço IP</mat-label>
                            <input matInput type="text"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                placeholder="Endereço IP" formControlName="address" autocomplete="off" required>
                            <mat-error *ngIf="editForm.controls['address'].hasError">
                                O endereço IP deve conter 8 números
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [ngClass]="{'hidden': editForm.controls['type'].value!=0}">
                            <mat-label class="label">Rack</mat-label>
                            <input matInput type="number" placeholder="Rack" formControlName="rack" autocomplete="off"
                                [required]="editForm.controls['type'].value==0">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [ngClass]="{'hidden': editForm.controls['type'].value==2}">
                            <mat-label class="label">Slot</mat-label>
                            <input matInput type="number" placeholder="Slot" formControlName="slot" autocomplete="off"
                                [required]="editForm.controls['type'].value!=2">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [ngClass]="{'hidden': editForm.controls['type'].value!=2}">
                            <mat-label class="label">Tópico</mat-label>
                            <input matInput type="number" placeholder="Tópico" formControlName="topic"
                                autocomplete="off" [required]="editForm.controls['type'].value==2">
                        </mat-form-field>
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid"
                            color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false"
                            color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>

</div>