<div class="table-container">
    <header>
        <div class="btn-wrapper">
            <a class="btn-default btn-filled" [routerLink]="['/cadastro-rapido']" (isStart)="isStart" *ngIf="isStart">
                Cadastro Rápido <a>
                    <mat-icon>launch</mat-icon>
                </a>
            </a>
        </div>

        <div class="btn-wrapper">
            <button (click)="refreshWasClicked()" class="mat-elevation-z0 btn-round" matTooltip="Recarregar"
                mat-mini-fab aria-label="Recarregar Página">
                <mat-icon>refresh</mat-icon>
            </button>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Pesquisar...">
        </div>
    </header>

    <table mat-table matSort [dataSource]="dataSource" (tableHeaderNames)="tableHeaderNames"
        (displayedColumns)="displayedColumns">
        <ng-container *ngFor='let displayCol of displayedColumns; let i = index'>
            <ng-container *ngIf="!(displayCol == 'actions')" matColumnDef="{{ displayCol }}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ tableHeaderNames[i] }}</th>
                <td (click)="emmitIdFrom(element.id)" (click)="emmitObject(element)" mat-cell *matCellDef="let element">
                    {{element[displayCol]}}
                    <mat-icon class="icon-null" *ngIf="element[displayCol]==null || element[displayCol].length==0">remove</mat-icon>
                </td>
            </ng-container>

            <ng-container *ngIf="displayCol == 'actions'" matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Ações</th>
                <td mat-cell *matCellDef="let element">
                    <button (click)="editObject(element)" aria-hidden="true"
                        class="btn-round btn-action mat-elevation-z0" mat-mini-fab matTooltip="Editar">
                        <mat-icon>mode_edit</mat-icon>
                    </button>
                    <button (click)="deleteObject(element)" aria-hidden="true"
                        class="btn-round btn-action mat-elevation-z0" mat-mini-fab matTooltip="Deletar">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <footer>

        <span class="no-content" *ngIf="dataSource.data.length === 0">
            Não existem registros cadastrados.
        </span>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" pageSize="10" showFirstLastButtons></mat-paginator>
    </footer>
</div>