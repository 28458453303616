import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CadastroRapidoComponent } from './views/cadastro-rapido/cadastro-rapido.component';
import { CompanyComponent } from './views/company/company.component';
import { FactoryComponent } from './views/factory/factory.component';
import { ProductionLineComponent } from './views/production-line/production-line.component';
import { ProductionCellComponent } from './views/production-cell/production-cell.component';
import { MachinesComponent } from './views/machines/machines.component';
import { GatewayComponent } from './views/gateway/gateway.component';
import { PlcConfigComponent } from './views/plc-config/plc-config.component';
import { SensorComponent } from './views/sensor/sensor.component';
import { LobotComponent } from './views/lobot/lobot.component';
import { LoginComponent } from './views/login/login.component';
import { ShiftComponent } from './views/shift/shift.component';

import { NetworkConfigComponent } from './views/network-config/network-config.component';
import { DiscardMonitoringComponent } from './views/discard-monitoring/discard-monitoring.component';
import { ProcessingComponent } from './views/processing/processing.component';
import { DiscardReasonsComponent } from './views/discard-reasons/discard-reasons.component';
import { AuthGuard } from './guard/auth.guard';
import { ProductComponent } from './views/product/product.component';
import { SignalComponent } from './views/signal/signal.component';
import { StopMonitoringComponent } from './views/stop-monitoring/stop-monitoring.component';
import { PlcComponent } from './views/plc/plc.component';
import { MeasurementsComponent } from './views/measurements/measurements.component';
import { StopClassComponent } from './views/stop-class/stop-class.component';
import { StopReasonsComponent } from './views/stop-reasons/stop-reasons.component';
import { ProductionMonitoringComponent } from './views/production-monitoring/production-monitoring.component';
import { ProductionOrderComponent } from './views/production-order/production-order.component';
import { DiscardClassComponent } from './views/discard-class/discard-class.component';
import { OperatorsComponent } from './views/operators/operators.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },

    { path: 'home', redirectTo: 'network' },

    { path: 'company', component: CompanyComponent, },
    { path: 'cadastro-rapido', component: CadastroRapidoComponent, },
    { path: 'factory', component: FactoryComponent, },
    { path: 'production-line', component: ProductionLineComponent, },
    { path: 'production-cell', component: ProductionCellComponent, },
    { path: 'machines', component: MachinesComponent, },
    { path: 'gateway', component: GatewayComponent, },
    { path: 'plc-config', component: PlcConfigComponent, },
    { path: 'sensor', component: SensorComponent, },
    { path: 'lobot', component: LobotComponent, },
    { path: 'shift', component: ShiftComponent, },
    { path: 'network', component: NetworkConfigComponent, },

    { path: 'product', component: ProductComponent, },
    { path: 'signal', component: SignalComponent, },
    { path: 'discard-monitoring', component: DiscardMonitoringComponent, },
    { path: 'processing', component: ProcessingComponent, },
    { path: 'discard-reasons', component: DiscardReasonsComponent, },
    { path: 'stop-monitoring', component: StopMonitoringComponent, },
    { path: 'plc', component: PlcComponent, },

    { path: 'measurements', component: MeasurementsComponent, },
    { path: 'stop-class', component: StopClassComponent, },
    { path: 'stop-reasons', component: StopReasonsComponent, },
    { path: 'production-monitoring', component: ProductionMonitoringComponent, },
    { path: 'production-order', component: ProductionOrderComponent, },
    { path: 'discard-class', component: DiscardClassComponent, },
    { path: 'operators', component: OperatorsComponent, },

    { path: '', redirectTo: 'network', pathMatch: 'full' },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
