<div id="main">
    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Motivo de Parada</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" mat-mini-fab
                aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'7'"></app-nav>
        </nav>

        <div class="flex-display">

            <app-dynamic-table class="table-component" [isStart]="false" [dataSource]="dataSource"
                [displayedColumns]="displayedColumns" [tableHeaderNames]="tableHeaderNames"
                (currentEditingObject)="editCurrentObject($event)"
                (currentDeletingObject)="deleteCurrentObject($event)"></app-dynamic-table>

            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmitClass(myForm.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar Motivo de Parada
                        </div>
                        <div class="form-id">
                            ID <span># <strong>{{ idPreview.id || '?' }}</strong></span>
                        </div>
                    </header>

                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do motivo</mat-label>
                            <input matInput placeholder="Nome da Classe" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Máquina</mat-label>
                            <mat-select placeholder="Máquina" formControlName="machine" autocomplete="off" required
                                (selectionChange)="setAvailableClasses($event,'form')" multiple>
                                <mat-option *ngFor="let machine of machines" [value]="machine.id">{{machine.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [ngClass]="{'classes':classesAvailable.length==0}">
                            <mat-label class="label">Classe</mat-label>
                            <mat-select placeholder="Classe" formControlName="class" autocomplete="off" required>
                                <mat-option *ngFor="let class of classesAvailable" [value]="class.id">{{class.name}}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="classesAvailable.length==0">*Selecione uma máquina para listar as
                                classes</mat-hint>
                        </mat-form-field>

                        <mat-checkbox color="primary" ngDefaultControl formControlName="mtbfmttr">MTBF / MTTR</mat-checkbox>
                        <mat-checkbox color="primary" ngDefaultControl formControlName="scheduledStops">Parada Programada</mat-checkbox>

                    </div>

                    <button class="btn-default btn-filled btn-add" type="submit"
                        [disabled]="myForm.invalid || myForm.untouched" color="primary">Adicionar
                        Motivo de Parada</button>

                    <div class="btn-wrapper">
                        <button class="btn-default nextPageBtn" (click)="onSubmit()"
                        type="button" [disabled]="reasons.length <= 0">Próxima Página</button>
                    </div>

                </form>
            </div>

        </div>

    </div>

    <!-- modal for editing and others -->
    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>

                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(editForm.value)">
                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do motivo</mat-label>
                            <input matInput placeholder="Nome da Classe" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Máquina</mat-label>
                            <mat-select placeholder="Máquina" formControlName="machine" autocomplete="off" required
                                (selectionChange)="setAvailableClasses($event,'form')" multiple>
                                <mat-option *ngFor="let machine of machines" [value]="machine.id">{{machine.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [ngClass]="{'classes':classesAvailableEdit.length==0}">
                            <mat-label class="label">Classe</mat-label>
                            <mat-select placeholder="Classe" formControlName="class" autocomplete="off" required>
                                <mat-option *ngFor="let class of classesAvailableEdit" [value]="class.id">{{class.name}}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="classesAvailableEdit.length==0">*Selecione uma máquina para listar as
                                classes</mat-hint>
                        </mat-form-field>

                        <mat-checkbox color="primary" formControlName="mtbfmttr">MTBF / MTTR</mat-checkbox>
                        <mat-checkbox color="primary" formControlName="scheduledStops">Parada Programada</mat-checkbox>

                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid"
                            color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false"
                            color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>

</div>