import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { HandleHttpService } from './handle-http.service';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    constructor( 
        private httpRequests: HandleHttpService,
        private _snackBar: MatSnackBar,
        private route: Router
    ) { }

    persistingData = {
        setData: function(value) {
            try {
                localStorage.setItem('persisting-data', JSON.stringify(value))
            } catch (error) {
                console.log(error);
            }
        },
        getData: function (defaultValue = {}) {
            try {
                const item = localStorage.getItem('persisting-data');
                return item ? JSON.parse(item) : defaultValue;
            } catch (error) {
                console.log(error);
                return defaultValue;
            }
        }
    }
    
    getDataSource(url: string, type: any, dataSource: MatTableDataSource<any>, idPreview: any, id?: number) {
        if(id == undefined){
            this.httpRequests.getRequest(`v1/${url}`)
            .subscribe((data: typeof type[]) => {
                    data.map((item) => {
                        item.createdAt = this.formatISOtoRegularDate(item.createdAt);
                        item.updatedAt = this.formatISOtoRegularDate(item.updatedAt);
                    })     

                    dataSource.data = data;
                    idPreview.id = dataSource.data[dataSource.data.length - 1].id + 1;

                }, error => {
                    if(error.status == 400) {
                        this.openSnackBar("Oops! Parece que não há referência para esta página, você será redirecionado agora!", "Ok");
                        // this.route.navigate(['/company']);
                    }
                }
                )
            } else {
                this.httpRequests.getRequest(`v1/${url}/${id}`)
                .subscribe((data: typeof type[]) => {
                    data.map((item) => {
                        item.createdAt = this.formatISOtoRegularDate(item.createdAt);
                        item.updatedAt = this.formatISOtoRegularDate(item.updatedAt);

                        data.sort(function (a, b) {
                            return a.id - b.id  ||  a.name.localeCompare(b.name);
                        });

                        if(item.aggregationBehaviour){
                            if(item.aggregationBehaviour.type == null)
                                item.aggregationBehaviour = "Desligado"
                            else if(item.aggregationBehaviour.type == "on_change")
                                item.aggregationBehaviour = "Alteração (OnChange)"

                            else if(item.aggregationBehaviour.type == "aggregate_on_change")
                                item.aggregationBehaviour = "Alteração Agregada"

                            else if(item.aggregationBehaviour.type == "counter")
                                item.aggregationBehaviour = "Contador"

                            else if(item.aggregationBehaviour.type == "time_counter")
                                item.aggregationBehaviour = "Contador Timer"

                            else if(item.aggregationBehaviour.type == "pulse")
                                item.aggregationBehaviour = "Pulso"

                            else if(item.aggregationBehaviour.type == "raw")
                                item.aggregationBehaviour = "Sem Compressão (raw)"                                
                        }

                        if(item.dataCollectorOn){
                            if(item.dataCollectorOn == true)
                                item.dataCollectorOn = "Ligado"
                            else if(item.dataCollectorOn == false)
                                item.dataCollectorOn = "Desligado"
                        }

                        if(item.monitoring) {
                            if(item.monitoring == true)
                                item.monitoring = "Ligado"
                            if(item.monitoring == false)
                                item.monitoring = "Desligado"
                        }

                        if(item.sensorType) 
                            item.sensorType = item.sensorType.name;
                        
                        if(item.showSensorSupervisory){
                            if(item.showSensorSupervisory === true)
                                item.showSensorSupervisory = "Visível"
                            else if (item.showSensorSupervisory === false)
                                item.showSensorSupervisory = "Não Visível"
                        }

                    })
                    
                    dataSource.data = data;
                    idPreview.id = dataSource.data[dataSource.data.length - 1].id + 1;

                }, error => {
                    if(error.status == 400){
                        this.openSnackBar("Oops! Parece que não há referência para esta página, você será redirecionado agora!", "Ok");
                        // this.route.navigate(['/company']);
                    }
                }
            )
        }   
    }

    goHome() {
        this.openSnackBar("Oops! Parece que não há referência para esta página, você será redirecionado agora!", "Ok");
        this.route.navigate(['/company']);
    }

    formatISOtoRegularDate(value) {
        var dateToUpdate = new Date(value);
        return dateToUpdate.toString().substring(0, 25);
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['warning']
        });
    }

    successSnackBar() {
        this._snackBar.open(`Concluída com sucesso!`, `Fechar`, {
            duration: 5000,
            panelClass: ['success']
        })
    }
}
