import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stop-reasons',
  templateUrl: './stop-reasons.component.html',
  styleUrls: ['./stop-reasons.component.scss', '../../../styles/defaultStyles.scss']
})
export class StopReasonsComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: Router
  ) { }

  @ViewChild('userForm') userForm;

  myForm = new FormGroup({
    name: new FormControl('', Validators.required),
    machine: new FormControl('', Validators.required),
    class: new FormControl('', Validators.required),
    mtbfmttr: new FormControl(null),
    scheduledStops: new FormControl(null),
  })

  editForm = new FormGroup({
    name: new FormControl('', Validators.required),
    machine: new FormControl('', Validators.required),
    class: new FormControl('', Validators.required),
    mtbfmttr: new FormControl(''),
    scheduledStops: new FormControl(''),
  })

  machines = [
    { name: "Máquina 1", id: 0, productionLineId: 1 },
    { name: "Máquina 2", id: 1, productionLineId: 1 },
    { name: "Máquina 3", id: 2, productionLineId: 2 },
    { name: "Máquina 4", id: 3, productionLineId: 3 },
  ];

  classes = [
    { id: 0, name: 'Elétrica', machineId: 0 },
    { id: 1, name: 'Mecânica', machineId: 1 },
  ];

  classesAvailable = [];
  classesAvailableEdit = [];

  reasons = [
    { id: 0, name: 'Primeiro motivo', machineId: [0], classId: 0, mtbfmttr: true, scheduledStops: true },
    { id: 1, name: 'Segundo motivo', machineId: [1], classId: 1, mtbfmttr: false, scheduledStops: false },
  ];

  dataSource = new MatTableDataSource([]);
  tableHeaderNames: Array<string> = ['Nome', 'Máquina', 'Classe', 'MTBF/MTTR', 'Parada Programada'];
  displayedColumns: string[] = ['name', 'machine', 'class', 'mtbfmttr', 'scheduledStops'];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  ngOnInit(): void {
    this, this.formatTable();
  }

  formatTable() {//formata dados para apresentar na tabela
    let formatedTable = [];

    this.reasons.forEach(reason => {//percorre todas as classes
      let auxMachine = "";
      this.machines.forEach(m => { if (reason.machineId.includes(m.id)) auxMachine += ", " + m.name; });
      auxMachine = auxMachine.slice(1, auxMachine.length);

      let auxClasse = this.classes.find(c => c.id === reason.classId);//Encontra a classe pelo ID
      let auxMt = reason.mtbfmttr ? 'MTBF / MTTR' : null;
      let auxSchedule = reason.scheduledStops ? 'Parada Programada' : null;
      formatedTable.push(//Adiciona var formatada (com nomes invés de ids) ao array auxiliar
        { id: reason.id, name: reason.name, machine: auxMachine, class: auxClasse.name, mtbfmttr: auxMt, scheduledStops: auxSchedule })
    });

    this.dataSource = new MatTableDataSource(formatedTable);//atrela dados a tabela
  }

  setAvailableClasses(machineSelected, from) {
    let values = [];
    let auxClasses = [];
    this.classesAvailable = [];


    if (from == 'form') values.push(machineSelected.value);//Se a ação for chamada do formulário, acessa o .value
    else values.push(machineSelected);

    this.classes.forEach(classe => {
      if (values[0].includes(classe.machineId)) auxClasses.push(classe);
    })

    if (this.isEditing) {//Se for ao editar um motivo,
      this.classesAvailableEdit = auxClasses;//O vetor auxiliar para edição é quem recebe os dados
      this.editForm.controls['class'].setValue('');//"Limpa" o valor de classes
    }
    else {
      this.classesAvailable = auxClasses;
      this.myForm.controls['class'].setValue('');
    }
  }

  editCurrentObject(editingObject: any): void {

    this.isEditing = true;

    this.editingIndex = editingObject.id;

    let reasonSelected = this.reasons.find((item) => { return item.id == editingObject.id }); //Retorna o objeto com os valores do motivo selecionado
    let classSelected = this.classes.find((item) => { return item.id == reasonSelected.classId });

    this.setAvailableClasses(reasonSelected.machineId, 'edit');

    this.editForm.setValue({
      name: reasonSelected.name,
      machine: reasonSelected.machineId,
      class: classSelected.id,
      mtbfmttr: reasonSelected.mtbfmttr,
      scheduledStops: reasonSelected.scheduledStops
    });
  }

  deleteCurrentObject(deletingObject: any): void {
    Swal.fire({
      title: 'Você tem certeza de que deseja deletar este motivo?',
      text: "Essa ação é irreversível!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Deletar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Motivo deletado com sucesso!',
          icon: 'success',
        })
      }
    })
  }

  onSubmitClass(formValues): void {
    //ID ESTATICO - MUDAR FUTURAMENTE /////////////////////
    let idReason = this.reasons[this.reasons.length - 1].id + 1;
    let mtaux, scheduleaux;
    if (formValues.mtbfmttr == null) mtaux = false; else mtaux = true;
    if (formValues.scheduledStops == null) scheduleaux = false; else scheduleaux = true;
    this.reasons.push(
      {
        id: idReason,
        name: formValues.name,
        machineId: formValues.machine,
        classId: formValues.class,
        mtbfmttr: mtaux,
        scheduledStops: scheduleaux
      },
    );
    this.formatTable();
    this.userForm.resetForm();
  }

  submitEdit(value: any): void {
    //Atualizar classe
    this.isEditing = false;
  }

  onSubmit() {
    //Seguir a página adiante 
    this.route.navigate(['/discard-class']);
  }

  logout() {
    this.authService.logout();
  }
}
