import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent {

    @Output() activeView = new EventEmitter<number>();
    @Input() currentActiveView: number;

    getCurrentActiveView(stepView: number): void {
        this.activeView.emit(stepView);
        this.currentActiveView = stepView;
    }

    public steps: Object[] = [
        {
            id: 0,
            title: 'Rede',
            url: '/network'
        },
        {
            id: 1,
            title: 'Linha de Produção',
            url: '/production-line'
        },
        {
            id: 2,
            title: 'Máquina',
            url: '/machines'
        },
        {
            id: 3,
            title: 'Turno',
            url: '/shift'
        },
        {
            id: 4,
            title: 'Operadores',
            url: '/operators'
        },
        {
            id: 5,
            title: 'Produto',
            url: '/product'
        },
        {
            id: 6,
            title: 'Classes de Parada',
            url: '/stop-class'
        },
        {
            id: 7,
            title: 'Motivo de Parada',
            url: '/stop-reasons'
        },
        {
            id: 8,
            title: 'Classes de Descarte',
            url: '/discard-class'
        },
        {
            id: 9,
            title: 'Motivo de Descarte',
            url: '/discard-reasons'
        },
        {
            id: 10,
            title: 'Sinais Físicos',
            url: '/signal'
        },
        {
            id: 11,
            title: 'PLC',
            url: '/plc'
        },
        {
            id: 12,
            title: 'Processamento',
            url: '/processing'
        },
        {
            id: 13,
            title: 'Medidas',
            url: '/measurements'
        },
        {
            id: 14,
            title: 'Monitoramento de Parada',
            url: '/stop-monitoring'
        },
        {
            id: 15,
            title: 'Monitoramento de Produção',
            url: '/production-monitoring'
        },
        {
            id: 16,
            title: 'Monitoramento de Descarte',
            url: '/discard-monitoring'
        },
        {
            id: 17,
            title: 'Ordem de produção',
            url: '/production-order'
        },
    ];
}
