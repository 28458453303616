<div id="main">
    <div class="content">
        <mat-toolbar class="header header-margin">
            <span>Console de Configuração | Ordem de Produção</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" mat-mini-fab
                aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'17'"></app-nav>
        </nav>

        <div class="flex-display">
            <app-dynamic-table class="table-component" [dataSource]="dataSource" [displayedColumns]="displayedColumns"
                [tableHeaderNames]="tableHeaderNames" (currentEditingObject)="editCurrentObject($event)"
                (currentDeletingObject)="deleteCurrentObject($event)"
                (emmitedObject)="getEmmitedObject($event)"></app-dynamic-table>

            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmit(userForm.value)">
                    <header>
                        <div class="form-title">Ordem de Produção</div>
                        <div class="form-id">
                            ID
                            <span># <strong>{{ idPreview.id || '?' }}</strong></span>
                        </div>
                    </header>

                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome</mat-label>
                            <input matInput required placeholder="Nome" formControlName="name" autocomplete="off" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Produtos</mat-label>
                            <mat-select required>
                                <mat-option *ngFor="let product of products" [value]="product">
                                    {{ product }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Máquina</mat-label>
                            <mat-select required>
                                <mat-option *ngFor="let machine of machines" [value]="machine">
                                    {{ machine }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Início</mat-label>
                            <input matInput required type="datetime-local" placeholder="Início" formControlName="start"
                                autocomplete="off" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Fim</mat-label>
                            <input matInput required type="datetime-local" placeholder="Fim" formControlName="end"
                                autocomplete="off" />
                        </mat-form-field>

                        <mat-checkbox color="primary" formControlName="isRecurrent">Recorrente?</mat-checkbox>
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="myForm.invalid"
                            color="primary">
                            Adicionar
                        </button>
                    </div>
                    <button type="button" class="btn-default nextPageBtn" type="button"
                        [disabled]="dataSource.data.length<=0">Finalizar</button>
                </form>
            </div>
        </div>
    </div>

    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">
            <div class="content-wrapper">
                <header>
                    <div class="form-title">Editar</div>
                    <div class="form-id">
                        ID
                        <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>

                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(userForm.value)">
                    <div class="input-wrapper margin-bottom">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome</mat-label>
                            <input matInput placeholder="Nome" formControlName="selectedName" autocomplete="off" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Produto</mat-label>
                            <mat-select required formControlName="selectedProduct">
                                <mat-option *ngFor="let product of products" [value]="product">
                                    {{ product }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Máquina</mat-label>
                            <mat-select required formControlName="selectedMachine">
                                <mat-option *ngFor="let machine of machines" [value]="machine">
                                    {{ machine }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Início</mat-label>
                            <input matInput type="datetime-local" placeholder="Início" formControlName="selectedStart"
                                autocomplete="off" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome</mat-label>
                            <input matInput type="datetime-local" placeholder="Nome" formControlName="selectedEnd"
                                autocomplete="off" />
                        </mat-form-field>

                        <mat-checkbox color="primary" formControlName="selectedIsRecurrent"
                            checked="true">Recorrente?</mat-checkbox>
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid"
                            color="primary">
                            Salvar
                        </button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false" color="primary">
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>