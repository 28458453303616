<div id="main">

    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Máquina</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" mat-mini-fab
                aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'2'"></app-nav>
        </nav>

        <span class="text-breadcrumbs">{{ breadCrumbs }}</span>

        <div class="flex-display">

            <app-dynamic-table class="table-component" [dataSource]="dataSource" [displayedColumns]="displayedColumns"
                [tableHeaderNames]="tableHeaderNames" (onRefresh)="refresh()" (emmitedObject)="getEmmitedObject($event)"
                (currentEditingObject)="editCurrentObject($event)"
                (currentDeletingObject)="deleteCurrentObject($event)"></app-dynamic-table>

            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm"
                    (ngSubmit)="onSubmitMachine(machineName.value, productionLineId.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar Máquinas
                        </div>
                        <div class="form-id">
                            ID <span># <strong>{{ idPreview.id || '?' }}</strong></span>
                        </div>
                    </header>

                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome da Máquina</mat-label>
                            <input matInput placeholder="Nome da Máquina" #machineName formControlName="name"
                                autocomplete="off" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Linha de Produção</mat-label>
                            <mat-select placeholder="Linha de Produção" #productionLineId
                                formControlName="productionLine" autocomplete="off" required>
                                <mat-option *ngFor="let line of productionLines" [value]="line.id">{{line.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <button class="btn-default btn-filled btn-add" type="submit"
                        [disabled]="myForm.invalid || myForm.get('name').untouched || myForm.get('productionLine').untouched"
                        color="primary">Adicionar
                        Máquina</button>

                    <div class="btn-wrapper">
                        <button class="btn-default nextPageBtn" (click)="onSubmit(userForm.value)"
                        type="button" [disabled]="machines.length <= 0">Próxima Página</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>

                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(userForm.value)">
                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome da Máquina</mat-label>
                            <input matInput placeholder="Nome da Máquina" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Linha de Produção</mat-label>
                            <mat-select placeholder="Linha de Produção" formControlName="productionLine"
                                autocomplete="off" required>
                                <mat-option *ngFor="let line of productionLines" [value]="line.id">{{line.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid"
                            color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false"
                            color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>