import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { DiscardReason } from 'src/app/model/discardReason';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-discard-reasons',
  templateUrl: './discard-reasons.component.html',
  styleUrls: [
    './discard-reasons.component.scss',
    '../../../styles/defaultStyles.scss',
  ],
})
export class DiscardReasonsComponent implements OnInit {
  constructor(private authService: AuthService) {}

  @ViewChild('userForm') userForm;

  machines: Array<string> = ['Máquina 1', 'Máquina 2', 'Máquina 3'];

  myForm = new FormGroup({
    name: new FormControl(''),
    machine: new FormControl(''),
  });
  editForm = new FormGroup({
    selectedMachine: new FormControl(''),
    selectedName: new FormControl(''),
  });

  dataSource = new MatTableDataSource<DiscardReason>([
    {
      id: 1,
      name: 'Justificativa de Descarte 1',
      machine: 'Máquina 1',
    },
    {
      id: 2,
      name: 'Justificativa de Descarte 2',
      machine: 'Máquina 2',
    },
    {
      id: 3,
      name: 'Justificativa de Descarte 3',
      machine: 'Máquina 3',
    },
  ]);
  tableHeaderNames: Array<string> = ['#', 'Nome', 'Máquina'];
  displayedColumns: string[] = ['id', 'name', 'machine'];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  getEmmitedObject(receivedObject: any): void {
    console.log('getEmmitedObject', receivedObject);
  }

  editCurrentObject(editingObject: any): void {
    console.log('editCurrentObject', editingObject);

    this.isEditing = true;
    this.editingIndex = editingObject.id;

    let discardReason: DiscardReason;
    this.dataSource.data.forEach((element) => {
      if (element.id == editingObject.id) {
        discardReason = element;
      }
    });

    this.editForm.patchValue({
      selectedName: discardReason.name,
      selectedMachine: discardReason.machine,
    });
  }

  submitEdit(value: any): void {
    console.log('submitEdit', value);
  }

  deleteCurrentObject(deletingObject: any): void {
    console.log('deleteCurrentObject', deletingObject);
  }

  ngOnInit(): void {}

  onSubmit(value: any): void {
    console.log(value);

    let id = 0;

    if (this.dataSource.data.length > 0)
      id = this.dataSource.data[this.dataSource.data.length - 1].id + 1;

    const line = {id: id,name: value.name,machine: value.machine};

    this.dataSource.data.push(line);
    this.dataSource._updateChangeSubscription();
    this.userForm.resetForm();
  }

  logout() {
    this.authService.logout();
  }
}
