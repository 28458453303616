<div id="page-home">
    <aside>
        <div class="banner-bg">
            <img src="../../../assets/ubivis_logo.png" alt="logo ubivis iot" class="logo">
        </div>
    </aside>

    <main>
        <div class="main-content">

            <h1 class="title">Console de Configuração</h1>
            <p class="sub-title">Informe o seu usuário e senha para continuar</p>
            <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                <div class="input-group">
                    <label for="email">Usuário</label>
                    <input type="text" placeholder="login@email.com" formControlName="email">

                    <div class="type-error">

                    </div>    
                </div>
                
                <div class="input-group">
                    <label for="password">Senha</label>
                    <input type="password" placeholder="senha" formControlName="password">

                    <div class="type-error">
    
                    </div>    
                </div>

                <button class="button" type="submit">
                    Entrar
                </button>

                <div>{{ error }}</div>
            </form >
        </div>
    </main>
</div>