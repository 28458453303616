import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss', '../../../styles/defaultStyles.scss']
})
export class OperatorsComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private route: Router,
  ) { }

  @ViewChild('userForm') userForm;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  myForm = new FormGroup({
    name: new FormControl(''),
    shifts: new FormControl('')
  })

  editForm = new FormGroup({
    name: new FormControl(''),
    shifts: new FormControl(''),
  })

  shifts = [
    { id: 0, name: 'Primeiro turno', startTime: '00:00', finishTime: '23:59', days: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"] },
    { id: 1, name: 'Segundo turno', startTime: '08:00', finishTime: '17:00', days: ["Seg", "Ter", "Qua"] },
    { id: 2, name: 'Terceiro turno', startTime: '13:00', finishTime: '15:30', days: ["Sab", "Dom"] }
  ]

  operators = [
    { id: 0, name: 'Operador 1', shiftsId: [0, 1] },
  ]

  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = ['operator', 'shifts', 'edit'];
  displayedColumnsShift: string[] = ['name', 'startTime', 'finishTime', 'days'];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  ngOnInit(): void {
    this.formatTable();
  }

  formatTable() {
    let formatedTable = [];

    this.operators.forEach(op => {//percorre todos os operadores
      let auxShifts = [];
      this.shifts.forEach(s => {
        if (op.shiftsId.includes(s.id))
          auxShifts.push({ name: s.name, startTime: s.startTime, finishTime: s.finishTime, days: s.days });
      })
      formatedTable.push(//Adiciona var formatada (com nomes invés de ids) ao array auxiliar
        { id: op.id, operator: op.name, shifts: auxShifts })
    });

    let withoutOp = this.findShiftWithoutOp();
    if (withoutOp != undefined && withoutOp.length > 0) formatedTable.push({ id: null, operator: 'Sem operador', shifts: withoutOp });

    this.dataSource = new MatTableDataSource(formatedTable);//atrela dados a tabela
    this.dataSource.paginator = this.paginator;
  }

  findShiftWithoutOp() {
    let availableShifts = [];
    let found: boolean;
    this.shifts.forEach(s => {
      found = false;
      this.operators.forEach(op => {
        if (op.shiftsId.includes(s.id)) found = true;
      })
      if (!found) availableShifts.push(s);
    })
    return availableShifts;
  }

  editCurrentObject(editingObject: any): void {
    this.isEditing = true;

    this.editingIndex = editingObject.id;

    let operatorSelected = this.operators.find((item) => { return item.id == editingObject.id }); //Retorna o objeto com os valores do turno selecionada

    this.editForm.setValue({
      name: operatorSelected.name,
      shifts: operatorSelected.shiftsId
    });
  }

  deleteCurrentObject(deletingObject: any): void {
    Swal.fire({
      title: 'Você tem certeza de que deseja deletar este operador?',
      text: "Essa ação é irreversível!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Deletar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Operador deletado com sucesso!',
          icon: 'success',
        })
      }
    })
  }

  onSubmitOperator(formValues): void {

    //ID ESTATICO - MUDAR FUTURAMENTE /////////////////////
    let idOperator = this.operators[this.operators.length - 1].id + 1;

    this.operators.push(
      {
        id: idOperator,
        name: formValues.name,
        shiftsId: formValues.shifts,
      },
    );

    this.formatTable();
    this.userForm.resetForm();
  }

  submitEdit(value: any): void {
    //Atualizar turno
    this.isEditing = false;
  }

  onSubmit() {
    //Seguir a página adiante 
    this.route.navigate(['/product']);
  }

  logout() {
    this.authService.logout();
  }

  refresh() {
    //
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
