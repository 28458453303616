import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-signal',
  templateUrl: './signal.component.html',
  styleUrls: ['./signal.component.scss', '../../../styles/defaultStyles.scss']
})
export class SignalComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private route: Router
  ) { }

  @ViewChild('sortA') public sortA: MatSort;
  @ViewChild('sortD') public sortD: MatSort;

  editForm = new FormGroup({
    channel: new FormControl('', Validators.required),
    range: new FormControl('', Validators.required),
    inflimit: new FormControl('', [Validators.required, Validators.max(100), Validators.min(0)]),
    suplimit: new FormControl('', [Validators.required, Validators.max(100), Validators.min(0)])
  })

  channelSelected = "";

  isEditing: boolean = false;
  editingIndex: number;

  dataSourceAnalog = new MatTableDataSource([]);
  dataSourceDigital = new MatTableDataSource([]);
  displayedColumnsAnalog = ['active', 'active-btn', 'channel', 'range', 'inflimit', 'suplimit', 'value', 'edit'];
  displayedColumnsDigital = ['active', 'active-btn', 'channel', 'value'];

  digitalSignals = [
    { active: false, channel: 'D1', value: 0 },
    { active: true, channel: 'D2', value: 0 },
    { active: true, channel: 'D3', value: 0 },
    { active: false, channel: 'D4', value: 0 },
    { active: false, channel: 'D5', value: 0 },
    { active: true, channel: 'D6', value: 0 },
    { active: true, channel: 'D7', value: 0 },
    { active: false, channel: 'D8', value: 0 }
  ];

  analogSignals = [
    { active: true, channel: 'A1', range: '0-10', inflimit: 0, suplimit: 100, value: 0 },
    { active: false, channel: 'A2', range: '4-20', inflimit: 0, suplimit: 100, value: 0 },
    { active: true, channel: 'A3', range: '0-10', inflimit: 0, suplimit: 100, value: 0 },
    { active: true, channel: 'A4', range: '0-10', inflimit: 0, suplimit: 100, value: 0 },
    { active: false, channel: 'A5', range: '4-20', inflimit: 0, suplimit: 100, value: 0 },
    { active: false, channel: 'A6', range: '0-10', inflimit: 0, suplimit: 100, value: 0 },
  ];

  rangeOptions = ["0-10", "4-20"];


  ngOnInit(): void {
    this.dataSourceAnalog = new MatTableDataSource(this.analogSignals);
    this.dataSourceDigital = new MatTableDataSource(this.digitalSignals);
  }

  ngAfterViewInit() {
    this.dataSourceAnalog.sort = this.sortA;
    this.dataSourceDigital.sort = this.sortD;
  }

  refreshValues() {
    //atualiza os valores sendo lidos
  }

  toggleActive(elementChannel: string, type: string) {
    //Procura o elemento clicado e troca entre ativo/desativo
    if (type == "analog") {
      let index = this.analogSignals.map(i => i.channel).indexOf(elementChannel);
      this.analogSignals[index].active = !this.analogSignals[index].active;
    }
    else {
      let index = this.digitalSignals.map(i => i.channel).indexOf(elementChannel);
      this.digitalSignals[index].active = !this.digitalSignals[index].active;
    }
  }

  editCurrentObject(editingObject: any): void {
    //edita campos dos sinais analógicos
    this.isEditing = true;

    this.editingIndex = editingObject.id;

    let signalSelected = this.analogSignals.find((item) => { return item.channel == editingObject }); //Retorna o objeto com os valores do turno selecionada
    this.channelSelected = signalSelected.channel;

    this.editForm.setValue({
      channel: signalSelected.channel,
      range: signalSelected.range,
      inflimit: signalSelected.inflimit,
      suplimit: signalSelected.suplimit
    });

    //subscribe para validar condição de limite inferior e superior estão sendo atingidas
    this.editForm.controls['inflimit'].valueChanges.subscribe(value => {
      if (value > this.editForm.controls['suplimit'].value)
        this.editForm.controls['inflimit'].setErrors({ moreThanSup: true });
    });

    this.editForm.controls['suplimit'].valueChanges.subscribe(value => {
      if (value < this.editForm.controls['inflimit'].value)
        this.editForm.controls['suplimit'].setErrors({ lessThanInf: true });
    });
  }

  submitEdit(value: any): void {
    //Atualizar dados do sinal
    let index = this.analogSignals.map(i => i.channel).indexOf(value.channel);
    this.analogSignals[index].range = value.range;
    this.analogSignals[index].inflimit = value.inflimit;
    this.analogSignals[index].suplimit = value.suplimit;
    this.isEditing = false;
  }

  onSubmit() {
    //Seguir a página adiante 
    this.route.navigate(['/plc']);
  }

  logout() {
    this.authService.logout();
  }

}
