import { Component } from '@angular/core';
import { User } from './model/user';
import { AuthService } from './services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    currentUser: User;

    constructor(
        private authService: AuthService
    ) {
        this.authService.currentUser.subscribe(user => this.currentUser = user)
    }

}
