import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-plc',
  templateUrl: './plc.component.html',
  styleUrls: ['./plc.component.scss', '../../../styles/defaultStyles.scss']
})
export class PlcComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: Router
  ) { }

  @ViewChild('plcFormc') plcFormc;
  @ViewChild('sensorsForm') sensorsForm;
  @ViewChildren('sensorTable') sensorTable: QueryList<MatTable<any>>;
  @ViewChildren('sensorPaginator') sensorPaginator: QueryList<MatPaginator>;

  plcForm = new FormGroup({
    name: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    rack: new FormControl(''),
    topic: new FormControl(''),
    slot: new FormControl('')
  });
  sensorForm = new FormGroup({
    name: new FormControl('', Validators.required),
    machine: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    ihm: new FormControl('', Validators.required),
    plcId: new FormControl(''),
  });

  editForm = new FormGroup({
    name: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    rack: new FormControl(''),
    topic: new FormControl(''),
    slot: new FormControl('')
  });

  types = [
    { id: 0, name: 'Siemens Profinet' },
    { id: 1, name: 'AB' },
    { id: 2, name: 'MQTT' },
  ];

  plcs = [
    {
      id: 0,
      name: 'Siemens 1',
      typeId: 0,
      address: '19216801',
      rack: 1,
      slot: 1,
      topic: null,
      value: 0,
    },
    {
      id: 1,
      name: 'AB2',
      typeId: 1,
      address: '19216802',
      rack: null,
      slot: 1,
      topic: null,
      value: 0,
    },
    {
      id: 2,
      name: 'MQTT',
      typeId: 2,
      address: '19216803',
      rack: null,
      slot: null,
      topic: 1,
      value: 0,
    },
  ];

  sensors = [
    { id: 0, name: 'sensor 1', machineId: 0, address: 'Temperatura', ihm: '/1', plcId: 0 },
    { id: 1, name: 'sensor 2', machineId: 1, address: 'Pressão', ihm: '/10', plcId: 0 },
    { id: 2, name: 'sensor 2-1', machineId: 0, address: 'Temperatura', ihm: '/1', plcId: 0 },
    { id: 3, name: 'sensor 2-2', machineId: 1, address: 'Pressão', ihm: '/1', plcId: 1 },
  ];

  machines = [
    { id: 0, name: "Primeira" },
    { id: 1, name: "Segunda" },
    { id: 2, name: "Terceira" },
  ];

  displayedColumns: string[] = ['name', 'machine', 'address', 'ihm', 'edit'];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  addingSensor: boolean = false;
  editingSensor: boolean = false;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.sensorTable.changes.subscribe(() => {//atualiza quando a viewChildren de tabelas for alterada
      this.formatPlcs();
    });

    this.formatPlcs();
  }

  formatPlcs() {
    let plcSensors = [];
    this.plcs.forEach((plc, index) => {//Percorre todos os plcs
      plcSensors = [];
      this.sensors.forEach(s => {//percorre todos os sensores
        if (s.plcId == plc.id) {//se o sensor estiver atrelado ao plc atual
          let auxMachine = this.machines.find((item) => { return item.id == s.machineId });//encontra a máquina atrelada ao sensor
          plcSensors.push({ id: s.id, name: s.name, machine: auxMachine.name, address: s.address, ihm: s.ihm, plcId: s.plcId });//adiciona valores em um vetor aux
        }
      });
      let auxTable = new MatTableDataSource(plcSensors);//cria uma data source com os valores do vetor aux
      auxTable.paginator = this.sensorPaginator.toArray()[index];//atribui a paginação daquele item
      this.sensorTable.toArray()[index].dataSource = auxTable;//atualiza a tabela do plc com os sensores
      if (auxTable.data.length > 0) //se houverem sensores no plc, esconde o indicador de tabela vazia
        document.getElementsByClassName("noData")[index].classList.add("hidden");
      else { //se não houver sensores no plc
        document.getElementsByClassName("paginator")[index].classList.add("hidden");//esconde a paginação da tabela dos sensores
        document.getElementsByClassName("sensor")[index].getElementsByTagName("thead")[0].classList.add("hidden");
        //esconde o cabeçalho da tabela dos sensores
      }
    });
  }

  addSensor(plcId: number) {
    this.addingSensor = true;
    this.sensorForm.reset();
    this.sensorForm.controls.plcId.setValue(plcId);
  }

  editCurrentObject(editingObject: any, type: string, plcId?: number): void {
    if (type == 'plc') {

      this.editForm = new FormGroup({//"Reseta" o formulario de edição
        name: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        address: new FormControl('', Validators.required),
        rack: new FormControl(''),
        slot: new FormControl(''),
        topic: new FormControl(),
      });

      this.isEditing = true;
      let plcSelected = this.plcs.find((item) => { return item.id == editingObject });

      this.editForm.setValue({
        name: plcSelected.name,
        type: plcSelected.typeId,
        address: plcSelected.address,
        rack: plcSelected.rack,
        slot: plcSelected.slot,
        topic: plcSelected.topic,
      });
    }
    else {
      this.editingSensor = true;
      let sensorSelected = this.sensors.find((item) => { return item.id == editingObject });
      this.sensorForm.setValue({
        name: sensorSelected.name,
        machine: sensorSelected.machineId,
        address: sensorSelected.address,
        ihm: sensorSelected.ihm,
        plcId: plcId
      });

    }
  };

  deleteCurrentObject(deletingObject: any, type: string): void {
    if (type == 'plc') {//Ao deletar um plc
      Swal.fire({
        title: 'Você tem certeza de que deseja deletar este PLC?',
        text: "Essa ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Deletar'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'PLC deletado com sucesso!',
            icon: 'success',
          })
        }
      })
    }
    else {//Ao deletar um sensor
      Swal.fire({
        title: 'Você tem certeza de que deseja deletar este sensor?',
        text: "Essa ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Deletar'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Sensor deletado com sucesso!',
            icon: 'success',
          })
        }
      })
    }
  }

  onSubmitPlc(formValues): void {

    //ID ESTATICO - MUDAR FUTURAMENTE /////////////////////
    let idPlc = this.plcs[this.plcs.length - 1].id + 1;

    this.plcs.push(
      {
        id: idPlc,
        name: formValues.name,
        typeId: formValues.type,
        address: formValues.address,
        rack: formValues.type == 0 ? formValues.rack : null,
        slot: formValues.type != 2 ? formValues.slot : null,
        topic: formValues.type == 2 ? formValues.topic : null,
        value: 0
      },
    );
    this.plcFormc.resetForm();
  }

  onSubmitSensor(formValues): void {
    //ID ESTATICO - MUDAR FUTURAMENTE /////////////////////
    let idSensor = this.sensors[this.sensors.length - 1].id + 1;

    this.sensors.push(
      {
        id: idSensor,
        name: formValues.name,
        machineId: formValues.machine,
        address: formValues.address,
        ihm: formValues.ihm,
        plcId: formValues.plcId,
      },
    );
    let indexPlc = this.plcs.findIndex(item => { return item.id == formValues.plcId });
    //Atualiza classes que indicam se a tabela de sensores esta ou não nula
    document.getElementsByClassName("noData")[indexPlc].classList.add("hidden");
    document.getElementsByClassName("paginator")[indexPlc].classList.remove("hidden");
    document.getElementsByClassName("sensor")[indexPlc].getElementsByTagName("thead")[0].classList.remove("hidden");

    this.addingSensor = false;
    this.editingSensor = false;
    this.sensorsForm.resetForm();
  }

  submitEdit(value: any, type: string): void {
    if (type == 'plc') {
      //Atualiza plc selecionado
      this.isEditing = false;
    }
    else {
      //Atualiza sensor selecionado
      this.editingSensor = false;
    }
  }

  onSubmit() {
    //Seguir a página adiante 
    this.route.navigate(['/processing']);
  }

  logout() {
    this.authService.logout();
  }

}

