import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Processing } from 'src/app/model/processing';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrls: [
    './processing.component.scss',
    '../../../styles/defaultStyles.scss',
  ],
})
export class ProcessingComponent implements OnInit {
  constructor(private authService: AuthService) {}

  sensors: Array<string> = ['D1', 'D2', 'D3'];
  machines: Array<string> = ['Máquina 1', 'Máquina 2', 'Máquina 3'];
  processings: Array<string> = ['Counter', 'Timer'];
  
  @ViewChild('userForm') userForm;

  myForm = new FormGroup({
    name: new FormControl(''),
    machine: new FormControl(''),
    sensor: new FormControl(''),
    processing: new FormControl(''),
  });
  editForm = new FormGroup({
    selectedSensor: new FormControl(''),
    selectedMachine: new FormControl(''),
    selectedProcessing: new FormControl(''),
    selectedName: new FormControl(''),
  });

  dataSource = new MatTableDataSource<Processing>([
    {
      id: 1,
      name: 'Templo de Ciclo',
      machine: 'Máquina 1',
      sensor: 'D1',
      processing: 'Counter',
    },
    {
      id: 2,
      name: 'Contador',
      machine: 'Máquina 2',
      sensor: 'D2',
      processing: 'Timer',
    },
    {
      id: 3,
      name: 'Tempo de Ciclo',
      machine: 'Máquina 3',
      sensor: 'D3',
      processing: 'Counter',
    },
    {
      id: 4,
      name: 'Contador',
      machine: 'Máquina 4',
      sensor: 'D1',
      processing: 'Timer',
    },
  ]);
  tableHeaderNames: Array<string> = ['#', 'Nome', 'Máquina', 'Processamento'];
  displayedColumns: string[] = ['id', 'name', 'machine', 'processing'];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  getEmmitedObject(receivedObject: any): void {
    console.log('getEmmitedObject', receivedObject);
  }

  editCurrentObject(editingObject: any): void {
    console.log('editCurrentObject', editingObject);

    this.isEditing = true;
    this.editingIndex = editingObject.id;

    let processing: Processing;
    this.dataSource.data.forEach((element) => {
      if (element.id == editingObject.id) {
        processing = element;
      }
    });

    this.editForm.patchValue({
      selectedSensor: processing.sensor,
      selectedMachine: processing.machine,
      selectedProcessing: processing.processing,
      selectedName: processing.name,
    });
  }

  submitEdit(value: any): void {
    console.log('submitEdit', value);
  }

  deleteCurrentObject(deletingObject: any): void {
    console.log('deleteCurrentObject', deletingObject);
  }

  ngOnInit(): void {}

  onSubmit(value: any): void {
    console.log(value);
    
    let id = 0;

    if (this.dataSource.data.length > 0)
      id = this.dataSource.data[this.dataSource.data.length - 1].id + 1;

    const line = {id: id,
      name: value.name,
      machine: value.machine,
      sensor: value.sensor,
      processing: value.processing};

    this.dataSource.data.push(line);
    this.dataSource._updateChangeSubscription();
    this.userForm.resetForm();
  }

  logout() {
    this.authService.logout();
  }
}
