import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-network-config',
    templateUrl: './network-config.component.html',
    styleUrls: ['./network-config.component.scss', '../../../styles/defaultStyles.scss'],
})
export class NetworkConfigComponent {
    constructor() { }

    wifi = new FormGroup({
        ssid: new FormControl(''),
        password: new FormControl(''),
    });

    ethTI = new FormGroup({
        ti_type: new FormControl('static'),
        ti_ip: new FormControl('', Validators.required),
        ti_gateway: new FormControl(''),
        ti_dns: new FormControl(''),
    });

    ethAuto = new FormGroup({
        automation_type: new FormControl('static'),
        automation_ip: new FormControl('', Validators.required),
        automation_gateway: new FormControl(''),
        automation_dns: new FormControl(''),
    });

    tableHeaderNames: Array<string> = ['#', 'Nome', 'IP', 'Gateway', 'DNS'];
    displayedColumns: string[] = ['id', 'ssid', 'ti_ip', 'ti_gateway', 'ti_dns'];

    idPreview: any = {};
    isEditing: boolean = false;
    editingIndex: number;

    activeForms = ['wifi', 'ethTI', 'ethAuto'];
    connectedForms = [];

    @ViewChildren('led') led: QueryList<ElementRef>;
    

    getFormGroupValues(formGroup: FormGroup): any {
        const formValues = {};

        Object.keys(formGroup.controls).forEach(controlName => {
            formValues[controlName] = formGroup.get(controlName).value;
        });

        return formValues;
    }

    submit() {
        let wifi = this.getFormGroupValues(this.wifi);
        let ethTI = this.getFormGroupValues(this.ethTI);
        let ethAuto = this.getFormGroupValues(this.ethAuto);

        const form = {
            ...wifi,
            ...ethTI,
            ...ethAuto,
        };

        console.log(form);
    }

    disableForm(formName) {
        if (this.activeForms.includes(formName)) {//Se o form estiver atualmente ativo
            this.activeForms.splice(this.activeForms.indexOf(formName), 1);//Remove do array de ativos
            Object.keys(this[formName].controls).forEach(key => {//passa por todos os controls do grupo e desativa eles
                this[formName].controls[key].disable();
            })
        }
        else {//se o form não estiver ativo
            this.activeForms.push(formName);//Adicionar do array de ativos
            Object.keys(this[formName].controls).forEach(key => {
                this[formName].controls[key].enable();//passa por todos os controls do grupo e ativa eles
            })
        }
    }

    testConnection(formIndex: number) {
        this.led.toArray()[formIndex].nativeElement.classList.replace('not-connected','connected');
        this.connectedForms.push(formIndex);
    }
}
