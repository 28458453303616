import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ipAddress'
})
export class IpAddressPipe implements PipeTransform {

  transform(address): string {
    address = address.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    address = (address.substring(0, 3) + '.' + address.substring(3, 6) + '.' + address.substring(6, 7) + '.' + address.substring(7));
    return address;
  }

}
