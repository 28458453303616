import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';

@Injectable({ providedIn: 'root' })
export class AuthService {

    public jwt = new JwtHelperService();

    private currentUserSub: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSub = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSub.asObservable();
    }

    public get currentUserValue(): User { return this.currentUserSub.value; }

    public get isLoggedIn(): boolean { return (this.currentUserValue) ? true : false }

    public get isAccessTokenExpiredValue(): boolean {
        if (this.currentUserValue != null) {
            // console.log(this.jwt.getTokenExpirationDate(this.currentUserSub.value.accessToken));
            // console.log("Access Token está expirado?", this.jwt.isTokenExpired(this.currentUserSub.value.accessToken));
            return this.jwt.isTokenExpired(this.currentUserSub.value.accessToken);
        }
        else
            return true;

    }

    public get isRefreshTokenExpiredValue(): boolean {        
        if (this.currentUserValue == null)
            return true;

        const refreshTokenExpirationDate = this.currentUserSub.value.exp;
        const currentDate = Date.now();

        // console.log(refreshTokenExpirationDate);        
        // console.log("Refresh Token está expirado?", currentDate >= refreshTokenExpirationDate ? true : false);
    
        return currentDate >= refreshTokenExpirationDate ? true : false;
    }


    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrlUbv}/getRefreshToken`, { email, password })
            .pipe(map(user => {
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSub.next(user);

                return user;
            }));
    }

    refreshAccessToken(refreshToken: string) {
        return this.http.post<string>(`${environment.apiUrlUbv}/getToken`, { refreshToken })
            .pipe(map((newToken: any) => {
                let updatedUser: User = this.currentUserSub.value;
                updatedUser.accessToken = newToken.accessToken;                

                localStorage.setItem('currentUser', JSON.stringify(updatedUser));
                this.currentUserSub.next(updatedUser)

                return newToken;
            }))
    }

    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSub.next(null)
        this.router.navigate(['/login']);
    }
}
