import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt'
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

const jwt = new JwtHelperService();

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    returnUrl: string;
    error: string;

    loginForm = new FormGroup({
        email: new FormControl(''),
        password: new FormControl('')
    })

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) { 
        //Redirects to home url if user is already logged in
        if(this.authService.currentUserValue)
            this.router.navigate(['/'])
    }

    ngOnInit(): void {
        // redireciona o usuário para a última url utilizada, ow vai para Home
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    onSubmit(): void {
        // this.authService.login(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value)
        //     .pipe(first())
        //     .subscribe(
        //         data => {
                    this.router.navigate(['/home'])
            //     },
            //     error => {
            //         // this.error = error;
            //         console.log(error);
                    
            //     }
            // )
    }


}
