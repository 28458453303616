import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss', '../../../styles/defaultStyles.scss']
})
export class ProductComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private route: Router
  ) { }

  @ViewChild('userForm') userForm;

  myForm = new FormGroup({})

  editForm = new FormGroup({
  })

  products = [
    {
      id: 0, name: 'Primeiro Produto', values: [
        { prod: 70, units: 5, stopTime: 0, setupTime: 0, machineId: 0 },
        { prod: 50, units: 2, stopTime: 0, setupTime: 0, machineId: 2 }
      ]
    },
    {
      id: 1, name: 'Segundo Produto', values: [
        { prod: 80, units: 5, stopTime: 0, setupTime: 0, machineId: 0 },
        { prod: 60, units: 5, stopTime: 0, setupTime: 0, machineId: 1 },
        { prod: 80, units: 2, stopTime: 0, setupTime: 0, machineId: 2 }
      ]
    }
  ]

  machines = [
    { id: 0, name: "Primeira máquina" },
    { id: 1, name: "Segunda máquina" },
    { id: 2, name: "Terceira máquina" },
  ];


  dataSource = new MatTableDataSource([]);
  tableHeaderNames: Array<string> = ['Produto'];
  displayedColumns: string[] = ['name'];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  machinesNames = [];

  ngOnInit(): void {
    this.setMachinesNames();
    this.tableHeaderNames = [...this.tableHeaderNames, ...this.machinesNames];
    this.displayedColumns = [...this.displayedColumns, ...this.machinesNames];
    this.setMachinesProdValues();
  }

  setMachinesNames() {//Formata os nome de máquinas para apresentar na tabela
    this.machinesNames = [];
    this.machines.forEach((machine) => {
      this.machinesNames.push(machine.name);
    })
  }

  setMachinesProdValues() {//Formata dados para a tabela e os formulários
    let auxProducts = [];//Array auxiliar com todos os produtos
    let auxp = {};//Var auxiliar que contém apenas um produto e seus valores 
    let form = {};//Var auxiliar para substituir o form com os inputs apropriados

    form['name'] = new FormControl('', Validators.required);

    this.products.forEach(p => {//Percorre todos os produtos
      auxp = [];
      auxp['name'] = p.name;//salvo o nome em um auxiliar
      auxp['id'] = p.id;

      this.machines.forEach(m => {//percorre todas as máquinas

        p.values.forEach(v => {//percorre todos os valores atrelados ao produto (do loop de todos os produtos)
          if (v.machineId == m.id)//Se tiver um valor atrelado a máquina (a máquina atual do loop de todas as máquinas)
          {
            auxp[m.name] = v.prod;//Atribui uma Key com o nome da máquina selecionada, com o valor do prod do produto selecionado
            form["prod" + m.id] = new FormControl('', Validators.min(0));
            form["units" + m.id] = new FormControl('', Validators.min(0));
            form["stopTime" + m.id] = new FormControl('');
            form["setupTime" + m.id] = new FormControl('');
            form["mid" + m.id] = new FormControl(m.id);//Setta valor do id da maq em um input, para referenciar no envio do form
          }
        })
      }
      )

      auxProducts.push(auxp);//Adiciona o produto a Lista de produtos
    })

    this.dataSource = new MatTableDataSource(auxProducts);//Atualiza tabela com os novos dados
    this.myForm = new FormGroup(form);//Atribui o novo form com todos os campos necessarios
  }

  editCurrentObject(editingObject: any): void {//Recebe item para editar
    this.isEditing = true;
    let form = {};

    let productSelected = this.products.find((item) => { return item.id == editingObject.id }); //Retorna o objeto com os valores do produto selecionada

    //carrega os valores do produto selecionado nos devidos innputs para edição
    form['name'] = new FormControl(productSelected.name, Validators.required);

    this.machines.forEach(m => {//percorre todas as máquinas

      productSelected.values.forEach(v => {//percorre todos os valores atrelados ao produto (do loop de todos os produtos)
        if (v.machineId == m.id)//Se tiver um valor atrelado a máquina (a máquina atual do loop de todas as máquinas)
        {
          form["prod" + m.id] = new FormControl(v.prod, Validators.min(0));
          form["units" + m.id] = new FormControl(v.units, Validators.min(0));
          form["stopTime" + m.id] = new FormControl(v.stopTime);
          form["setupTime" + m.id] = new FormControl(v.setupTime);
          form["mid" + m.id] = new FormControl(m.id);//Setta valor do input do form, para referenciar a maq no envio do form
        }
      })
    })

    this.editForm = new FormGroup(form);
  }

  deleteCurrentObject(deletingObject: any): void {
    Swal.fire({
      title: 'Você tem certeza de que deseja deletar este produto?',
      text: "Essa ação é irreversível!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Deletar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Produto deletado com sucesso!',
          icon: 'success',
        })
      }
    })
  }

  onSubmitProduct(formValues): void {//Adiciona produto
    //ID ESTATICO - MUDAR FUTURAMENTE /////////////////////
    let idProduct = this.products[this.products.length - 1].id + 1;

    let auxValuesArray = [];

    for (let i = 1; i < Object.keys(formValues).length; i = i + 5) {//Percorre todas as keys do form
      auxValuesArray.push({//Adiciona um objeto por máquina, com 5 valores passados por input
        prod: formValues[Object.keys(formValues)[i]],
        units: formValues[Object.keys(formValues)[i + 1]],
        stopTime: formValues[Object.keys(formValues)[i + 2]],
        setupTime: formValues[Object.keys(formValues)[i + 3]],
        machineId: formValues[Object.keys(formValues)[i + 4]]
      });
    }

    this.products.push({//Adiciona o objeto, contendo toda a relação de máquinas feita anteriormente
      id: idProduct,
      name: formValues.name,
      values: auxValuesArray
    });

    this.dataSource = new MatTableDataSource(this.products);//atualiza tabela
    this.userForm.resetForm();

    this.setMachinesProdValues();//Atualiza o modelo de diposição da tabela
  }

  submitEdit(value: any): void {
    //Atualizar turno
    this.isEditing = false;
  }

  onSubmit() {
    //Seguir a página adiante 
    this.route.navigate(['/stop-class']);
  }

  logout() {
    this.authService.logout();
  }

}
