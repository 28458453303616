import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Gateway } from 'src/app/model/gateway';
import { AuthService } from 'src/app/services/auth.service';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { HandleHttpService } from 'src/app/services/handle-http.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-gateway',
    templateUrl: './gateway.component.html',
    styleUrls: ['./gateway.component.scss', '../../../styles/defaultStyles.scss']
})
export class GatewayComponent implements OnInit {

    constructor(
        public dataService: DataserviceService,
        public sharedService: SharedService,
        private httpRequests: HandleHttpService,
        private route: Router,
        private authService: AuthService
    ) { }

    dataSource = new MatTableDataSource([]);
    tableHeaderNames: Array<string> = ['#', 'Nome', 'Mac Address', 'Criado em', 'Atualizado em'];
    displayedColumns: string[] = ['id', 'name', 'macAddress', 'createdAt', 'updatedAt'];

    breadCrumbs: string;
    idPreview: any = {};
    isEditing: boolean = false;
    editingIndex: number;

    myForm = new FormGroup({
        name: new FormControl(''),
        macAddress: new FormControl('')
    })

    editForm = new FormGroup({
        name: new FormControl(''),
        macAddress: new FormControl('')
    })

    editCurrentObject(editingObject: any): void {
        this.isEditing = true;
        this.editingIndex = editingObject.id;

        this.httpRequests.getRequest(`v1/gateways/${editingObject.id}`).subscribe((data: Gateway) => {
            this.editForm.setValue({ name: data.name, macAddress: data.macAddress })
        });
    }

    submitEdit(value: any): void {
        let editedObject: Gateway = { name: value.name, macAddress: value.macAddress }

        if (editedObject.name != undefined || editedObject.name != null) {
            this.httpRequests.updateRequest(`v1/gateways/${this.editingIndex}`, editedObject).subscribe(() => {
                this.refresh();
                this.isEditing = false;
            })
        }
    }

    deleteCurrentObject(deletingObject: any): void {
        Swal.fire({
            title: 'Você tem certeza de que deseja deletar este Gateway?',
            text: "Essa ação é irreversível!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Deletar'
        }).then((result) => {
            if (result.isConfirmed) {
                this.httpRequests.deleteRequest(`v1/gateways/${deletingObject.id}`).subscribe(() => {
                    Swal.fire({
                        title: 'Gateway deletado com sucesso!',
                        icon: 'success',
                    })
                    this.refresh();
                }, (error) => {
                    Swal.fire({
                        title: 'Erro ao deletar o Gateway',
                        text: 'Tente novamente mais tarde.',
                        icon: 'error',
                    })
                });
            }
        })
    }

    getEmmitedObject(receivedObject: any): void {
        this.dataService.gateway = receivedObject;
        this.sharedService.persistingData.setData(this.dataService)

        this.route.navigate(['/plc']);
    }

    ngOnInit(): void {
        // try {
        //     if (this.sharedService.persistingData.getData().productionLine.id == undefined)
        //         console.log("does not exist");
        //     else
        //         this.dataService = this.sharedService.persistingData.getData();
        // } catch (error) {
        //     this.sharedService.goHome()
        // }

        // this.breadCrumbs = `
        // ${this.dataService.company.name} > 
        // ${this.dataService.factory.name} > 
        // ${this.dataService.productionLine.name} >
        // ${this.dataService.productionCell.name} >
        // ${this.dataService.machine.name} >`

        // this.refresh();
    }

    refresh(): void {
        this.sharedService.getDataSource('gateways/production-lines', Gateway, this.dataSource, this.idPreview, this.dataService.productionLine.id)
    }

    //todo: quando o mac address for igual, tratar erro de duplicata e retornar para o usuário

    onSubmit(value: any): void {
        // let postObject: Gateway = { name: value.name, macAddress: value.macAddress, productionLine: { id: this.dataService.productionLine.id } };
        // this.httpRequests.postRequest("v1/gateways/", postObject).subscribe((data) => { this.refresh() })
        // this.myForm.reset();

        this.route.navigate(['/plc']);

    }

    logout() {
        this.authService.logout();
    }
}
