export class PlcConfig {
    id?: number;
    plcName: string;
    createdAt?: string;
    updatedAt?: string;

    plcDriver: string;
    
    hostIp: string;
    rack?: string;
    slot?: string;
    
    gateway?: {
        id: number;
    }
}
