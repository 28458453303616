import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { Discard } from 'src/app/model/discard';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-discard-monitoring',
  templateUrl: './discard-monitoring.component.html',
  styleUrls: [
    './discard-monitoring.component.scss',
    '../../../styles/defaultStyles.scss',
  ],
})
export class DiscardMonitoringComponent implements OnInit {
  constructor(private authService: AuthService) { }

  @ViewChild('userForm') userForm;

  machines: Array<string> = ['Máquina 1', 'Máquina 2', 'Máquina 3'];
  collect: Array<string> = ['Automático', 'Manual'];
  origins: Array<string> = ['PLC1', 'PLC2', 'PLC3'];
  logics: Array<string> = ['Pulse', 'Incremental', 'Constante'];

  myForm = new FormGroup({
    machine: new FormControl(''),
    collect: new FormControl(''),
    origin: new FormControl(''),
    sensor: new FormControl('Descarte'),
    logic: new FormControl(''),
  });
  editForm = new FormGroup({
    selectedMachine: new FormControl(''),
    selectedCollect: new FormControl(''),
    selectedOrigin: new FormControl(''),
    selectedSensor: new FormControl('Descarte'),
    selectedLogic: new FormControl(''),
  });

  dataSource = new MatTableDataSource<Discard>([
    {
      id: 1,
      machine: 'Máquina 1',
      collect: 'Automático',
      origin: 'PLC1',
      sensor: 'Descarte',
      logic: 'Pulse',
    },
    {
      id: 2,
      machine: 'Máquina 2',
      collect: 'Automático',
      origin: 'PLC2',
      sensor: 'Descarte',
      logic: 'Incremental',
    },
    {
      id: 3,
      machine: 'Máquina 3',
      collect: 'Automático',
      origin: 'PLC3',
      sensor: 'Descarte',
      logic: 'Constante',
    },
    {
      id: 4,
      machine: 'Máquina 4',
      collect: 'Manual',
      origin: 'PLC4',
      sensor: 'Descarte',
      logic: 'Manual',
    },
  ]);
  tableHeaderNames: Array<string> = [
    '#',
    'Máquina',
    'Coleta',
    'Origem',
    'Sensor',
    'Lógica',
  ];
  displayedColumns: string[] = [
    'id',
    'machine',
    'collect',
    'origin',
    'sensor',
    'logic',
  ];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  getEmmitedObject(receivedObject: any): void {
    console.log('getEmmitedObject', receivedObject);
  }

  editCurrentObject(editingObject: any): void {
    console.log('editCurrentObject', editingObject);

    this.isEditing = true;
    this.editingIndex = editingObject.id;

    let discard: Discard;
    this.dataSource.data.forEach((element) => {
      if (element.id == editingObject.id) {
        discard = element;
      }
    });

    this.editForm.patchValue({
      selectedMachine: discard.machine,
      selectedCollect: discard.collect,
      selectedOrigin: discard.origin,
      selectedLogic: discard.logic,
    });
  }

  submitEdit(value: any): void {
    console.log('submitEdit', value);
  }

  deleteCurrentObject(deletingObject: any): void {
    console.log('deleteCurrentObject', deletingObject);
  }

  ngOnInit(): void { }

  onSubmit(value: any): void {
    console.log(value);

    let id = 0;

    if (this.dataSource.data.length > 0)
      id = this.dataSource.data[this.dataSource.data.length - 1].id + 1;

    let line;
    if (value.collect != 'Manual') {
      line = { id: id, machine: value.machine, collect: value.collect, origin: value.origin, sensor: value.sensor, logic: value.logic };
    }
    else {
      line = { id: id, machine: value.machine, collect: value.collect, origin: null, sensor: null, logic: null };
    }

    this.dataSource.data.push(line);
    this.dataSource._updateChangeSubscription();
    this.userForm.resetForm();
    this.myForm.controls['sensor'].setValue('Descarte');
  }

  logout() {
    this.authService.logout();
  }
}
