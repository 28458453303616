<div id="main">
    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Turno</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" mat-mini-fab
                aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'3'"></app-nav>
        </nav>

        <div class="flex-display">

            <app-dynamic-table class="table-component" [isStart]="false" [dataSource]="dataSource"
                [displayedColumns]="displayedColumns" [tableHeaderNames]="tableHeaderNames"
                (currentEditingObject)="editCurrentObject($event)"
                (currentDeletingObject)="deleteCurrentObject($event)"></app-dynamic-table>

            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmitShift(myForm.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar Turno
                        </div>
                        <div class="form-id">
                            ID <span># <strong>{{ idPreview.id || '?' }}</strong></span>
                        </div>
                    </header>

                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do Turno</mat-label>
                            <input matInput placeholder="Nome da Turno" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Horário de Início</mat-label>
                            <input matInput type="time" placeholder="Horário de Início" formControlName="startTime"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Horário de Fim</mat-label>
                            <input matInput type="time" placeholder="Horário de Fim" formControlName="finishTime"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Dias</mat-label>
                            <mat-select placeholder="Dias" #productionLineId formControlName="days" autocomplete="off"
                                multiple required>
                                <mat-option *ngFor="let day of days" [value]="day">{{day}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <button class="btn-default btn-filled btn-add" type="submit"
                        [disabled]="myForm.invalid || myForm.untouched" color="primary">Adicionar
                        Turno</button>

                    <div class="btn-wrapper">
                        <button type="button" class="btn-default nextPageBtn" (click)="onSubmit()"
                            [disabled]="shifts.length <= 0">Próxima Página</button>
                    </div>

                </form>
            </div>

        </div>

    </div>

    <!-- modal for editing and others -->
    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>

                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(editForm.value)">
                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do Turno</mat-label>
                            <input matInput placeholder="Nome da Turno" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Horário de Início</mat-label>
                            <input matInput type="time" placeholder="Horário de Início" formControlName="startTime"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Horário de Fim</mat-label>
                            <input matInput type="time" placeholder="Horário de Fim" formControlName="finishTime"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Dias</mat-label>
                            <mat-select placeholder="Dias" #productionLineId formControlName="days" autocomplete="off"
                                multiple required>
                                <mat-option *ngFor="let day of days" [value]="day">{{day}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid"
                            color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false"
                            color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>

</div>