import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataserviceService {

    public company: {
        id: number,
        name: string
    };
    public factory: {
        id: number,
        name: string
    };
    public productionLine: {
        id: number,
        name: string
    };
    public productionCell: {
        id: number,
        name: string
    };
    public machine: {
        id: number,
        name: string
    };
    public gateway: {
        id: number,
        name: string
    };
    public plc: {
        id: number,
        name?: string,
        plcName: string;
    };
    public sensor: {
        id: number,
        name: string
    };
    public lobot: {
        id: number,
        name?: string,
        description: string
    }; 
}
