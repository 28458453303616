import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { Measurements } from '../../model/measurements';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: [
    './measurements.component.scss',
    '../../../styles/defaultStyles.scss',
  ],
})
export class MeasurementsComponent implements OnInit {
  constructor(private authService: AuthService) { }

  @ViewChild('userForm') userForm;

  machines: Array<string> = ['Máquina 1', 'Máquina 2', 'Máquina 3', 'Máquina 4'];
  origins: Array<string> = [
    'Processing',
    'Digital Input',
    'Analógica Input',
    'PLC1',
  ];

  originsTags: Array<string> = [
    'Processing',
    'Digital Input',
    'Analógica Input',
    'PLC1',
  ];

  myForm = new FormGroup({
    name: new FormControl(''),
    machine: new FormControl(''),
    origin: new FormControl(''),
    originTags: new FormControl(''),
  });
  editForm = new FormGroup({
    selectedName: new FormControl(''),
    selectedMachine: new FormControl(''),
    selectedOrigin: new FormControl(''),
    selectedOriginTags: new FormControl(''),
  });

  dataSource = new MatTableDataSource<Measurements>([
    {
      id: 1,
      name: 'Tempo de ciclo',
      machine: 'Máquina 1',
      origin: 'Processing',
      originTags: ['Processing'],
    },
    {
      id: 2,
      name: 'D1',
      machine: 'Máquina 2',
      origin: 'Digital Input',
      originTags: ['Digital Input'],
    },
    {
      id: 3,
      name: 'A1',
      machine: 'Máquina 3',
      origin: 'Analógica Input',
      originTags: ['Digital Input'],
    },
    {
      id: 4,
      name: 'Pressão',
      machine: 'Máquina 4',
      origin: 'PLC1',
      originTags: ['Digital Input'],
    },
  ]);
  tableHeaderNames: Array<string> = ['#', 'Nome', 'Máquina', 'Origem', 'Tag de Origem'];
  displayedColumns: string[] = ['id', 'name', 'machine', 'origin', 'originTags'];

  idPreview: any = {};
  isEditing: boolean = false;
  editingIndex: number;

  getEmmitedObject(receivedObject: any): void {
    console.log('getEmmitedObject', receivedObject);
  }

  editCurrentObject(editingObject: any): void {
    console.log('editCurrentObject', editingObject);

    this.isEditing = true;
    this.editingIndex = editingObject.id;

    let measurement: Measurements;
    this.dataSource.data.forEach((element) => {
      if (element.id == editingObject.id) {
        measurement = element;
      }
    });

    this.editForm.patchValue({
      selectedName: measurement.name,
      selectedMachine: measurement.machine,
      selectedOrigin: measurement.origin,
      selectedOriginTags: measurement.originTags,
    });
  }

  submitEdit(value: any): void {
    console.log('submitEdit', value);
  }

  deleteCurrentObject(deletingObject: any): void {
    console.log('deleteCurrentObject', deletingObject);
  }

  ngOnInit(): void { }

  onSubmit(value: any): void {
    console.log(value);

    let id = 0;

    if (this.dataSource.data.length > 0)
      id = this.dataSource.data[this.dataSource.data.length - 1].id + 1;

    const line = { id: id, name: value.name, machine: value.machine, origin: value.origin, originTags: value.originTags };

    this.dataSource.data.push(line);
    this.dataSource._updateChangeSubscription();
    this.userForm.resetForm();
  }

  logout() {
    this.authService.logout();
  }
}
