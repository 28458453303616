<div id="main">
    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Configuração de Rede </span>
            <button class="mat-elevation-z0 btn-round" matTooltip="Logout" mat-mini-fab aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'0'"></app-nav>
        </nav>

        <div class="network">
            <div class="network-wrapper">
                <form [formGroup]="wifi" class="form" [ngClass]="{'disabled-card': !activeForms.includes('wifi')}">
                    <div class="label">Wi-fi
                        <mat-checkbox class="radio-button" color="primary"
                            (change)="disableForm('wifi')">Desabilitar</mat-checkbox>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label>SSID</mat-label>
                        <input matInput formControlName="ssid" required />
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Senha</mat-label>
                        <input matInput type="password" formControlName="password" required />
                    </mat-form-field>
                    <br>
                    <button mat-button [disabled]="!activeForms.includes('wifi')" type="submit"
                        class="btn-filled">Salvar
                        Rede</button>
                    <div class="led-wrapper">
                        <button mat-button [disabled]="!activeForms.includes('wifi')" type="button" class="btn-test"
                            (click)="testConnection(0)">Testar Conexão</button>
                        <div #led class="led not-connected"
                            matTooltip="{{connectedForms.includes(0)?'Conectada':'Sem conexão'}}"></div>
                    </div>

                </form>

                <form [formGroup]="ethTI" class="form" [ngClass]="{'disabled-card': !activeForms.includes('ethTI')}">
                    <div class="label">Eth0 - TI
                        <mat-checkbox class="radio-button" color="primary"
                            (change)="disableForm('ethTI')">Desabilitar</mat-checkbox>
                    </div>
                    <mat-radio-group class="input-radio" formControlName="ti_type">
                        <mat-radio-button class="radio-button" color="primary" value="static"> Static
                        </mat-radio-button>
                        <mat-radio-button class="radio-button" color="primary" value="dhcp"> DHCP </mat-radio-button>
                    </mat-radio-group>

                    <ng-container *ngIf="ethTI.get('ti_type').value === 'static'">
                        <mat-form-field appearance="outline">
                            <mat-label>IP</mat-label>
                            <input matInput formControlName="ti_ip" mask="IP" />
                        </mat-form-field>
                    </ng-container>

                    <mat-form-field appearance="outline">
                        <mat-label>Gateway</mat-label>
                        <input matInput formControlName="ti_gateway" required />
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>DNS</mat-label>
                        <input matInput formControlName="ti_dns" required />
                    </mat-form-field>
                    <br>

                    <button mat-button [disabled]="!activeForms.includes('ethTI')" type="submit"
                        class="btn-filled">Salvar
                        Rede</button>
                    <div class="led-wrapper">
                        <button mat-button [disabled]="!activeForms.includes('ethTI')" type="button" class="btn-test"
                            (click)="testConnection(1)">Testar Conexão</button>
                        <div #led class="led not-connected" 
                            matTooltip="{{connectedForms.includes(1)?'Conectada':'Sem conexão'}}"></div>
                    </div>

                </form>

                <form [formGroup]="ethAuto" class="form" [ngClass]="{'disabled-card': !activeForms.includes('ethAuto')}">
                    <div class="label">Eth1 - Automação
                        <mat-checkbox class="radio-button" color="primary"
                            (change)="disableForm('ethAuto')">Desabilitar</mat-checkbox>
                    </div>
                    <mat-radio-group class="input-radio" formControlName="automation_type">
                        <mat-radio-button class="radio-button" color="primary" value="static"> Static
                        </mat-radio-button>
                        <mat-radio-button class="radio-button" color="primary" value="dhcp"> DHCP </mat-radio-button>
                    </mat-radio-group>
                    <ng-container *ngIf="ethAuto.get('automation_type').value === 'static'">
                        <mat-form-field appearance="outline">
                            <mat-label>IP</mat-label>
                            <input matInput formControlName="automation_ip" mask="IP" />
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="ethAuto.get('automation_type').value === 'dhcp'">
                        <mat-form-field appearance="outline">
                            <mat-label>Gateway</mat-label>
                            <input matInput formControlName="automation_gateway" required />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>DNS</mat-label>
                            <input matInput formControlName="automation_dns" required />
                        </mat-form-field>
                    </ng-container>
                    <br>
                    <button mat-button [disabled]="!activeForms.includes('ethAuto')" type="submit"
                        class="btn-filled">Salvar Rede</button>
                    <div class="led-wrapper">
                        <button mat-button [disabled]="!activeForms.includes('ethAuto')" type="button" class="btn-test"
                            (click)="testConnection(2)">Testar Conexão</button>
                        <div #led class="led not-connected"
                            matTooltip="{{connectedForms.includes(2)?'Conectada':'Sem conexão'}}"></div>
                    </div>

                </form>

            </div>
            <button class="btn-default nextPageBtn" routerLink="/production-line">Próxima Página</button>
        </div>
    </div>
</div>