<div id="content">
    <ul>
        <li 
            *ngFor="let step of steps; let i = index"
            [class.active]="i == 0"
            [routerLink]="[step.url]"
            (click)="getCurrentActiveView(step.id)"
            [ngClass]="currentActiveView >= i ? 'enabled' : 'disabled'"
            [matTooltip]="!(currentActiveView >= i) ? 'Selecione uma opção na tabela para continuar' : ''"
        >{{ step.title }}</li>
    </ul>
</div>