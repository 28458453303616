<div id="main">
    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Operadores</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" mat-mini-fab
                aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'4'"></app-nav>
        </nav>

        <div class="flex-display">

            <div class="operator-table">
                <header>
                        <button (click)="refresh()" class="mat-elevation-z0 btn-round" matTooltip="Recarregar"
                            mat-mini-fab aria-label="Recarregar Página">
                            <mat-icon>refresh</mat-icon>
                        </button>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Pesquisar...">
                </header>
                <table mat-table [dataSource]="dataSource">

                    <ng-container matColumnDef="operator">
                        <th mat-header-cell *matHeaderCellDef>Operador</th>
                        <td mat-cell *matCellDef="let element"> {{element.operator}} </td>
                    </ng-container>

                    <ng-container matColumnDef="shifts">
                        <th mat-header-cell *matHeaderCellDef>Turnos</th>
                        <td mat-cell *matCellDef="let element">

                            <table mat-table [dataSource]="element.shifts" class="shift-table">
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> Turno </th>
                                    <td mat-cell *matCellDef="let shift"> {{shift.name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="startTime">
                                    <th mat-header-cell *matHeaderCellDef> Hora início </th>
                                    <td mat-cell *matCellDef="let shift"> {{shift.startTime}} </td>
                                </ng-container>
                                <ng-container matColumnDef="finishTime">
                                    <th mat-header-cell *matHeaderCellDef> Hora fim </th>
                                    <td mat-cell *matCellDef="let shift"> {{shift.finishTime}} </td>
                                </ng-container>
                                <ng-container matColumnDef="days">
                                    <th mat-header-cell *matHeaderCellDef> Dias </th>
                                    <td mat-cell *matCellDef="let shift"> {{shift.days}} </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsShift"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsShift;"></tr>
                            </table>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="editCurrentObject(element)" aria-hidden="true"
                                class="btn-round btn-action mat-elevation-z0" mat-mini-fab matTooltip="Editar">
                                <mat-icon>mode_edit</mat-icon>
                            </button>
                            <button (click)="deleteCurrentObject(element)" aria-hidden="true"
                                class="btn-round btn-action mat-elevation-z0" mat-mini-fab matTooltip="Deletar">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="shifts"></tr>
                </table>
                <footer>

                    <span class="no-content" *ngIf="dataSource.data.length === 0">
                        Não existem registros cadastrados.
                    </span>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" pageSize="10" showFirstLastButtons></mat-paginator>
                </footer>
            </div>
            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmitOperator(myForm.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar Operador
                        </div>
                        <div class="form-id">
                            ID <span># <strong>{{ idPreview.id || '?' }}</strong></span>
                        </div>
                    </header>

                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do Operador</mat-label>
                            <input matInput placeholder="Nome da Operador" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Turnos</mat-label>
                            <mat-select placeholder="Turnos" #productionLineId formControlName="shifts"
                                autocomplete="off" multiple required>
                                <mat-option *ngFor="let shift of shifts" [value]="shift.id">{{shift.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <button class="btn-default btn-filled btn-add" type="submit"
                        [disabled]="myForm.invalid || myForm.untouched" color="primary">Adicionar
                        Operador</button>

                    <div class="btn-wrapper">
                        <button type="button" class="btn-default nextPageBtn" (click)="onSubmit()"
                            [disabled]="operators.length <= 0">Próxima Página</button>
                    </div>

                </form>
            </div>

        </div>

    </div>

    <!-- modal for editing and others -->
    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>

                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(editForm.value)">
                    <div class="input-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Nome do Operador</mat-label>
                            <input matInput placeholder="Nome da Operador" formControlName="name" autocomplete="off"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="label">Turnos</mat-label>
                            <mat-select placeholder="Turnos" #productionLineId formControlName="shifts"
                                autocomplete="off" multiple required>
                                <mat-option *ngFor="let shift of shifts" [value]="shift.id">{{shift.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid"
                            color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="isEditing = false"
                            color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>

</div>