<div id="main">

    <div class="content">
        <mat-toolbar class="header">
            <span>Console de Configuração | Sensores</span>
            <button (click)="logout()" class="mat-elevation-z0 btn-round" matTooltip="Logout" 
                    mat-mini-fab aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <nav class="items-nav">
            <app-nav [currentActiveView]="'8'"></app-nav>
        </nav>

        <span class="text-breadcrumbs">{{ breadCrumbs }}</span>

        <div class="flex-display">

            <app-dynamic-table class="table-component" 
                [dataSource]="dataSource" 
                [displayedColumns]="displayedColumns"
                [tableHeaderNames]="tableHeaderNames" 
                (onRefresh)="refresh()" 
                (emmitedObject)="getEmmitedObject($event)"
                (currentEditingObject)="editCurrentObject($event)"
                (currentDeletingObject)="deleteCurrentObject($event)"
            ></app-dynamic-table>

            <div class="form-wrapper">
                <form #userForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmit(userForm.value)">
                    <header>
                        <div class="form-title">
                            Cadastrar Sensor
                        </div>

                        <div class="pb-wrapper">
                            <div class="form-id">
                                ID <span># <strong> {{ idPreview.id || '?'}} </strong></span>
                            </div>
                        </div>
                    </header>
                    
                    
                    <div class="input-wrapper">

                        <div class="tooltip-wrapper">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">{{ fileName || 'Importar arquivo' }}</mat-label>
                                <input #fileInput matInput disabled autocomplete="off">
                            </mat-form-field>
                            <div class="tip-holder">

                                <input class="file-input" type="file" autocomplete="off"
                                    (change)="onFileSelected($event)" #fileUpload>

                                <div class="btn-wrapper">
                                    <button class=" publish-btn btn-filled btn-default" type="button"
                                        (click)="fileUpload.click()"
                                        matTooltip="Importar sensores a partir de um arquivo">
                                        <mat-icon>publish</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="separator">ou crie um novo</div>

                        <div class="overflow-wrapper">
                            <mat-form-field appearance="outline">
                                <mat-label class="label">Nome do Sensor</mat-label>
                                <input matInput placeholder="Nome do Sensor" formControlName="name" autocomplete="off"
                                    required>
                            </mat-form-field>
    
                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Monitorar Paradas?</mat-label>
                                    <mat-select [formControl]="monitoring">
                                        <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Sim - O sensor monitora as paradas, tempo não funcional, que poderão ser consultadas posteriormente
                                    Não - O sensor não monitora paradas que acontecerem"
                                    [matTooltipClass]="'my-tooltip'"
                                    >?</span>
                                </div>
                            </div>

                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Mostrar no Supervisório?</mat-label>
                                    <mat-select [formControl]="showSensorSupervisory">
                                        <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Mostra ou não o sensor no supervisório"
                                    [matTooltipClass]="'my-tooltip'"
                                    >?</span>
                                </div>
                            </div>

                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Operador IHM</mat-label>
                                    <input matInput placeholder="Operador IHM" [formControl]="ihmOperator" autocomplete="off">
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Operador Interação Humano Máquina - 
                                    Utiliza valores de operação para transformar os valores iniciais em valores que possam ser utilizados. i.e: '/100', '*100'"
                                    [matTooltipClass]="'my-tooltip'">?</span>                            
                                </div>
                            </div>

                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Endereço</mat-label>
                                    <input matInput placeholder="Endereço" [formControl]="nameAddress" autocomplete="off">
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Solicitar Explicação"
                                    [matTooltipClass]="'my-tooltip'">?</span>                            
                                </div>
                            </div>

                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Tipo dos Dados</mat-label>
                                    <mat-select [formControl]="sensorDataType">
                                        <mat-option *ngFor="let option of sensorDataTypeOptions" [value]="option.id">
                                            {{ option.databaseType }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Tipo que será utilizado dentro do banco de dados / CLP"
                                    [matTooltipClass]="'my-tooltip'">?</span>                            
                                </div>
                            </div>
    
                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Deseja salvar os dados?</mat-label>
                                    <mat-select [formControl]="dataCollectorOn">
                                        <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Sim - Os dados são coletados e salvos no banco de dados
                                    Não - Os dados são coletados porém não serão salvos no banco de dados"
                                    [matTooltipClass]="'my-tooltip'"
                                    >?</span>
                                </div>
                            </div>   
    
                            <div class="tooltip-wrapper" *ngIf="dataCollectorOn.value == true">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Tipo de Compressão de Dados</mat-label>
    
                                    <mat-select [formControl]="aggregationType">
                                        <mat-option *ngFor="let option of aggregationOptions" [value]="option.value" >
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                
                                <div class="tip-holder">
                                    <span matTooltip="Alteração - Envia o dado apenas quando muda. Recomendado quando não se sabe o comportamento do Sensor.
                                        Alteração Agregada - Realiza uma agregação em uma janela de tempo, entretanto só salvará esta janela houver alguma mudança em comparação à janela anterior.
                                        Contador - Transforma contadores em números simples, facilitando operações de agregação no banco de dados.
                                        Contador Timer - Usado sobre sensores que enviam contadores de tempo. Um contador é inicializado até ser reiniciado.
                                        Pulso - Utilizado para sensores que enviam dados binários (0 - 1).
                                        Raw - Os dados são salvos sem nenhum tipo de processamento ou agregação."
                                    [matTooltipClass]="'my-tooltip'">?</span>                            
                                </div>
                            </div>

                            <div class="tooltip-wrapper" *ngIf="aggregationType.value == 'counter'">
                                <ng-container *ngIf="aggregationType.value == 'counter'">
                                    <mat-form-field appearance="outline">
                                        <mat-label class="label">is End Updatable</mat-label>
                                        <mat-select [formControl]="isEndUpdatable">
                                            <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                                {{ option.name }}
                                            </mat-option>
                                        </mat-select>
    
                                    </mat-form-field>
                                </ng-container>
                                <div class="tip-holder">
                                    <span matTooltip="O end vai sendo atualizado a cada mensagem que chega, até mesmo quando o valor se repete.">?</span>
                                </div>
                            </div>
    
                            <ng-container *ngIf="aggregationType.value == 'aggregate_on_change'">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Tempo</mat-label>
                                    <input matInput placeholder="Tempo" autocomplete="off" formControlName="time">
                                </mat-form-field>
                            </ng-container>
    
                            <ng-container *ngIf="aggregationType.value == 'aggregate_on_change'">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Limiar</mat-label>
                                    <input matInput placeholder="threshold" autocomplete="off"
                                        formControlName="threshold">
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="aggregationType.value == 'aggregate_on_change'">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Processing Average Type</mat-label>
                                    <mat-select [formControl]="processingAvgType">
                                        <mat-option *ngFor="let option of processingAvgTypes" [value]="option.value">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>

                            <div class="tooltip-wrapper" *ngIf="aggregationType.value == 'on_change'">
                                <ng-container *ngIf="aggregationType.value == 'on_change'">
                                    <mat-form-field appearance="outline">
                                        <mat-label class="label">is End Updatable</mat-label>
                                        <mat-select [formControl]="isEndUpdatable">
                                            <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                                {{ option.name }}
                                            </mat-option>
                                        </mat-select>
    
                                    </mat-form-field>
                                </ng-container>
                                <div class="tip-holder">
                                    <span matTooltip="O end vai sendo atualizado a cada mensagem que chega, até mesmo quando o valor se repete.">?</span>
                                </div>
                            </div>
    
                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Tipo de Sensor</mat-label>
                                    <mat-select [formControl]="sensorType">
                                        <mat-option *ngFor="let option of sensorTypeOptions" [value]="option.id">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Para que final este sensor será utilizado, i. e. 
                                    Caso o sensor seja um sensor de Alarme, selecionar o tipo de Alarme que será destinado">?</span>
                                </div>
                            </div>
    
                            <div class="tooltip-wrapper">
                                <div class="slider-wrapper">
                                    <mat-slide-toggle [formControl]="isAggregateSensorEquals"></mat-slide-toggle>
                                    <mat-label class="label">Sensor Agregado é o mesmo Sensor Atual</mat-label>
                                </div>
                                <div class="tip-holder">
                                    <span matTooltip="Explicação para o input">?</span>
                                </div>
                            </div>
    
                            <div class="tooltip-wrapper" *ngIf="isAggregateSensorEquals.value == false">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Específicar ID do Sensor Agregado (?)</mat-label>
                                    <input matInput placeholder="" autocomplete="off" type="number"
                                        formControlName="aggregateSensor">
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Explicação para o input">?</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit"
                            color="primary">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="edit-modal" *ngIf="isEditing == true">
        <div class="modal-wrapper">

            <div class="content-wrapper">
                <header>
                    <div class="form-title">
                        Editar
                    </div>
                    <div class="form-id">
                        ID <span># <strong>{{ editingIndex || '?' }}</strong></span>
                    </div>
                </header>
    
                <form #userForm="ngForm" [formGroup]="editForm" (ngSubmit)="submitEdit(userForm.value)"> 
                    <div class="input-wrapper">
                        <div class="overflow-wrapper">
                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                <mat-label class="label">Nome do Sensor</mat-label>
                                    <input matInput formControlName="name" autocomplete="off" required>
                                </mat-form-field>
                            </div>
    
                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Monitorar Paradas?</mat-label>
                                    <mat-select [formControl]="monitoring">
                                        <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Sim - O sensor monitora as paradas, tempo não funcional, que poderão ser consultadas posteriormente
                                    Não - O sensor não monitora paradas que acontecerem"
                                    [matTooltipClass]="'my-tooltip'"
                                    >?</span>
                                </div>
                            </div>
    
                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Mostrar no Supervisório?</mat-label>
                                    <mat-select [formControl]="showSensorSupervisory">
                                        <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Mostra ou não o sensor no supervisório"
                                    [matTooltipClass]="'my-tooltip'"
                                    >?</span>
                                </div>
                            </div>

                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Operador IHM</mat-label>
                                    <input matInput placeholder="Operador IHM" [formControl]="ihmOperator" autocomplete="off">
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Operador Interação Humano Máquina - 
                                    Utiliza valores de operação para transformar os valores iniciais em valores que possam ser utilizados. i.e: '/100', '*100'"
                                    [matTooltipClass]="'my-tooltip'">?</span>                            
                                </div>
                            </div>

                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Endereço</mat-label>
                                    <input matInput placeholder="Endereço" [formControl]="nameAddress" autocomplete="off">
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Solicitar Explicação"
                                    [matTooltipClass]="'my-tooltip'">?</span>                            
                                </div>
                            </div>
    
                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Deseja salvar os dados?</mat-label>
                                    <mat-select [formControl]="dataCollectorOn">
                                        <mat-option *ngFor="let option of monitoringOptions" [value]="option.value">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Sim - Os dados são coletados e salvos no banco de dados
                                    Não - Os dados são coletados porém não serão salvos no banco de dados"
                                    [matTooltipClass]="'my-tooltip'"
                                    >?</span>
                                </div>
                            </div>
    
                            <div class="tooltip-wrapper" *ngIf="dataCollectorOn.value == true">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Tipo dos Dados</mat-label>
                                    <mat-select [formControl]="sensorDataType">
                                        <mat-option *ngFor="let option of sensorDataTypeOptions" [value]="option.id">
                                            {{ option.databaseType }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Tipo que será utilizado dentro do banco de dados / CLP"
                                    [matTooltipClass]="'my-tooltip'">?</span>                            
                                </div>
                            </div>
    
                            <div class="tooltip-wrapper" *ngIf="dataCollectorOn.value == true">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Tipo de Compressão de Dados</mat-label>
    
                                    <mat-select [formControl]="aggregationType">
                                        <mat-option *ngFor="let option of aggregationOptions" [value]="option.value">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                
                                <div class="tip-holder">
                                    <span matTooltip="Alteração - Envia o dado apenas quando muda. Recomendado quando não se sabe o comportamento do Sensor.
    
                                        Alteração Agregada - Realiza uma agregação em uma janela de tempo, entretanto só salvará esta janela houver alguma mudança em comparação à janela anterior.
                                        Contador - Transforma contadores em números simples, facilitando operações de agregação no banco de dados.
                                        Contador Timer - Usado sobre sensores que enviam contadores de tempo. Um contador é inicializado até ser reiniciado.
                                        Pulso - Utilizado para sensores que enviam dados binários (0 - 1).
                                        Raw - Os dados são salvos sem nenhum tipo de processamento ou agregação."
                                    [matTooltipClass]="'my-tooltip'">?</span>                            
                                </div>
                            </div>
    
                            <div class="tooltip-wrapper" [formGroup]="typeConstraints" *ngIf="dataCollectorOn.value == true">
                                <ng-container *ngIf="aggregationType.value == 'on_change'">
                                    <mat-form-field appearance="outline">
                                        <mat-label class="label">Is End Updatable</mat-label>
                                        <input matInput placeholder="is End Updatable" autocomplete="off"
                                            formControlName="isEndUpdatable">
                                    </mat-form-field>
                                </ng-container>
    
                                <ng-container *ngIf="aggregationType.value == 'aggregate_on_change'">
                                    <mat-form-field appearance="outline">
                                        <mat-label class="label">Tempo</mat-label>
                                        <input matInput placeholder="Tempo" autocomplete="off" formControlName="time">
                                    </mat-form-field>
                                </ng-container>
    
                                <ng-container *ngIf="aggregationType.value == 'aggregate_on_change'">
                                    <mat-form-field appearance="outline">
                                        <mat-label class="label">Limiar</mat-label>
                                        <input matInput placeholder="Limiar" autocomplete="off"
                                            formControlName="threshold">
                                    </mat-form-field>
                                </ng-container>
                            </div>
    
                            <div class="tooltip-wrapper">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label">Tipo de Sensor</mat-label>
                                    <mat-select [formControl]="sensorType">
                                        <mat-option *ngFor="let option of sensorTypeOptions" [value]="option.id">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="tip-holder">
                                    <span matTooltip="Para que final este sensor será utilizado, i. e. 
                                    Caso o sensor seja um sensor de Alarme, selecionar o tipo de Alarme que será destinado">?</span>
                                </div>
                            </div>
    
                            <div class="tooltip-wrapper">
                                <div class="slider-wrapper">
                                    <mat-slide-toggle [formControl]="isAggregateSensorEquals"></mat-slide-toggle>
                                    <mat-label class="label">Sensor Agregado é o mesmo Sensor Atual</mat-label>
                                </div>
                                <div class="tip-holder">
                                    <span matTooltip="Explicação para o input">?</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="btn-wrapper">
                        <button class="btn-default btn-filled" type="submit" [disabled]="editForm.invalid" color="primary">Salvar</button>
                        <button class="btn-default btn-outlined" (click)="cancelEdit($event)" color="primary">Cancelar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>

    <div class="edit-modal" *ngIf="isUploadingFile == true">
        <div class="modal-wrapper">
            <div class="table-wrapper">
                <div class="table-wrapper-overflow">
                    <table mat-table matSort [dataSource]="displayDataSource" 
                        (tableHeaderNames)="tableHeaderNames" (displayedColumns)="displayedColumns">
                        <ng-container *ngFor='let displayCol of displayedColumns; let i = index'>
                            <ng-container matColumnDef="{{ displayCol }}">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ tableHeaderNames[i] }}</th>
                                <td mat-cell *matCellDef="let element"> {{ returnVal(element, displayCol) }} </td>
                            </ng-container>
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                    </table>
                </div>
                <div class="btn-wrapper btn-wrapper-table">
                    <button class="btn-default btn-filled" (click)="submitFileToSensor()">Confirmar</button>
                    <button class="btn-default btn-outlined" (click)="cancelUpload($event)">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</div>